import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { getTheme } from '../helpers/style.helper';

export const Hr = styled.hr<SpaceProps>`
    border: none;
    border-bottom: 1px solid ${getTheme('colors.silver.50')};
    width: 100%;

    ${space}
`;
