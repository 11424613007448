import React, { useCallback, useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import { Dropdown } from '../components';
import { useOnClickOutside } from './use-on-click-outside';

const DropdownComponent = ({
    children,
    dropdownRef,
    attributes,
    styles,
    setPopperElement,
    showDropdown,
    removeDefaultPadding,
}) => {
    if (!showDropdown) {
        return null;
    }

    return (
        <div
            ref={setPopperElement}
            style={{ ...styles.popper, zIndex: 15 }}
            {...attributes.popper}
        >
            <Dropdown
                ref={dropdownRef}
                removeDefaultPadding={removeDefaultPadding}
            >
                {children}
            </Dropdown>
        </div>
    );
};

type PlacementType =
    | 'top-start'
    | 'top'
    | 'top-end'
    | 'right-start'
    | 'right'
    | 'right-end'
    | 'bottom-start'
    | 'bottom'
    | 'bottom-end'
    | 'left-start'
    | 'left'
    | 'left-end';

interface IUseDropdownProps {
    placement?: PlacementType;
    debug?: boolean;
}

export const useDropdown = ({
    placement = 'bottom-end',
    debug = false,
}: IUseDropdownProps = {}) => {
    const [showDropdown, setShowDropdown] = useState(debug);
    const dropdownRef = useRef(null);
    const [
        referenceElement,
        setReferenceElement,
    ] = useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    );
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement,
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ],
    });

    const openDropdown = useCallback(() => {
        setShowDropdown(true);
    }, [setShowDropdown]);

    const closeDropdown = useCallback(() => {
        setShowDropdown(false);
    }, [setShowDropdown]);

    const toggleDropdown = useCallback(() => {
        setShowDropdown(!showDropdown);
    }, [showDropdown, setShowDropdown]);

    useOnClickOutside(dropdownRef, () => closeDropdown());

    const dropdownProps = {
        showDropdown,
        dropdownRef,
        attributes,
        styles,
        setPopperElement,
        removeDefaultPadding: false,
    };

    return {
        positionalRef: setReferenceElement,
        Dropdown: DropdownComponent,
        dropdownProps,
        isDropdownOpen: showDropdown,
        openDropdown,
        closeDropdown,
        toggleDropdown,
    };
};
