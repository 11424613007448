import axios from 'axios';

import { API_BASE_URL, API_ROUTE_TAGS, API_ROUTE_ALL } from '../constants/api';

export const getAllTags = (): Promise<ITag[]> => {
    return axios
        .get(`${API_BASE_URL}/public/${API_ROUTE_TAGS}/${API_ROUTE_ALL}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};
