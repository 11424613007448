import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { LinkWrapper } from './styled-link.styles';

interface LinkProps<S> {
    underline?: boolean;
    children: React.ReactNode;
    href: string;
    state?: S;
}

export function StyledLink<S>({
    children,
    href,
    state,
    underline,
}: PropsWithChildren<LinkProps<S>>) {
    return (
        <Link to={{ pathname: href, state: { ...state } }}>
            <LinkWrapper underline={underline}>{children}</LinkWrapper>
        </Link>
    );
}

interface FakeLinkProps {
    underline?: boolean;
    onClick: () => void;
}

export const FakeLink: React.FC<FakeLinkProps> = ({
    children,
    onClick,
    underline,
}) => {
    return (
        <LinkWrapper underline={underline} onClick={onClick}>
            {children}
        </LinkWrapper>
    );
};
