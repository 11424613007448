const colors = {
    gold: {
        '100': '#FCF1CE',
        '200': '#FBEAB6',
        '300': '#FAE39E',
        '400': '#F7D56E',
        '500': '#F5C73D',
        '600': '#F2B90D',
        '700': '#CA9907',
        '800': '#785B03',
        '900': '#302503',
    },
    red: {
        '100': '#F5D9D6',
        '200': '#EBB4AD',
        '300': '#E08E84',
        '400': '#D6685C',
        '500': '#CC4733',
        '600': '#A33529',
        '700': '#7A281F',
        '800': '#521B14',
        '900': '#290D0A',
    },
    pink: {
        '100': '#FCE9F0',
        '200': '#F8D3E0',
        '300': '#F5BDD1',
        '400': '#F1A7C1',
        '500': '#EA7AA3',
        '600': '#E44E84',
        '700': '#C71F5B',
        '800': '#9B1847',
        '900': '#580E29',
    },
    blue: {
        '50': ' #00B0DB',
        '100': '#D9DDF2',
        '200': '#ABB4E3',
        '300': '#6677CC',
        '400': '#4055BF',
        '500': '#2D3B86',
        '600': '#263372',
        '700': '#202B5F',
        '800': '#1A224C',
        '900': '#0D1126',
    },
    green: {
        '100': '#DEEDE8',
        '200': '#BDDBD1',
        '300': '#9CC9BA',
        '400': '#7AB8A3',
        '500': '#59A68C',
        '600': '#478470',
        '700': '#366354',
        '800': '#244238',
        '900': '#12211C',
    },
    silver: {
        '25': '#F8F8FA',
        '50': '#F2F2F2',
        '100': '#E5E5E5',
        '200': '#CCCCCC',
        '300': '#B2B2B2',
        '400': '#999999',
        '500': '#808080',
        '600': '#666666',
        '700': '#4C4C4C',
        '800': '#333333',
        '900': '#191919',
    },
    white: {
        '40': '#FFFFFF66',
        '100': '#FFFFFF',
    },
    black: {
        '10': '#00000017',
        '20': '#00000033',
        '25': '#00000040',
    },
};

const space = [
    '0px', //    0
    '1px', //    1
    '2px', //    2
    '4px', //    3
    '8px', //    4
    '12px', //   5
    '16px', //   6
    '24px', //   7
    '32px', //   8
    '48px', //   9
    '64px', //  10
    '96px', //  11
    '128px', // 12
    '256px', // 13
    '512px', // 14
];

const fonts = {
    heading: 'GTFlexa, sans-serif',
    primary: 'Inter, sans-serif',
};

const fontWeights = [400, 500, 700];

const fontSizes = [
    '10px', //  0
    '11px', //  1
    '12px', //  2
    '14px', //  3
    '16px', //  4
    '18px', //  5
    '21px', //  6
    '24px', //  7
    '30px', //  8
    '32px', //  9
    '36px', // 10
    '40px', // 11
    '48px', // 12
    '60px', // 13
    '72px', // 14
];

const lineHeights = ['135%', '150%', '170%', '175%'];

const radii = [0, '2px', '4px', '8px', '16px', '999px'];

const shadows = {
    buttonHover: `0 2px 3px -1px ${colors.black['10']}`,
    highHover: `0 10px 20px -10px ${colors.black['25']}`,
    floatDropdown: `0 48px 96px -24px ${colors.black['20']}`,
    playActive: `0 16px 12px -11px ${colors.black['10']}`,
};

const borders = ['none', '1px solid'];

const breakpoints = [
    '480px', //   0 -> xs
    '576px', //   1 -> sm
    '768px', //   2 -> md
    '1024px', //  3 -> lg
    '1440px', //  4 -> xl
];

export default {
    colors,
    space,
    fonts,
    fontWeights,
    fontSizes,
    lineHeights,
    radii,
    shadows,
    borders,
    breakpoints,
};
