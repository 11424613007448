const baseLink = `https://creativecommons.org/licenses/`;

export type Licencing =
    | 'CC BY 4.0'
    | 'CC BY-SA 4.0'
    | 'CC BY-NC 4.0'
    | 'CC BY-NC-SA 4.0'
    | 'Creative Commons copyright waiver'
    | 'Public domain'
    | 'some-rights'
    | 'no-rights';

interface IExplanation {
    iconName: string;
    text: string;
}

type ChildLicense = Omit<License, 'childLicenses'>;

export interface License {
    text: string;
    value: Licencing;
    name: string;
    ccLink?: string;
    explanation: IExplanation[];
    childLicenses: ChildLicense[];
}

const explanations = [
    // 0
    {
        iconName: 'ccBy',
        text:
            'Others are free to distribute, remix and build upon your work, even commercially, as long as they credit you.',
    },

    // 1
    {
        iconName: 'ccSa',
        text:
            'Other must distribute derivatives of your work under the same license.',
    },
    // 2
    {
        iconName: 'ccNc',
        text: 'Others may not use your work for commercial purposes.',
    },
    // 3
    {
        iconName: 'ccWaiver',
        text:
            'You waive all your copyright and related rights in this work, worldwide.',
    },
    // 4
    {
        iconName: 'ccPublic',
        text:
            'This work is already in the public domain and free of copyright restrictions.',
    },
    // 5
    {
        iconName: 'creativeCommons',
        text:
            'By selecting a some rights reserved license you explicitly ask to be attributed for the work that you share. On top of that you can also decide whether your work can be used in a commercial context and whether you expect people to do play your work forward under the same legal conditions.',
    },
    // 6
    {
        iconName: 'creativeCommons',
        text:
            'By selecting no rights reserved you waive all your rights related to the content that you are about to publish. You do not have to be credited for your work by others and it can be used in whatever context they seem fit. Make sure that if you have any embedded content from someone else, that their work is also free of any copyright.',
    },
];

export const licenses: License[] = [
    {
        text: 'Some rights reserved',
        value: 'some-rights',
        name: 'Some rights',
        explanation: [explanations[5]],

        childLicenses: [
            {
                text: 'Attribution',
                value: 'CC BY 4.0',
                ccLink: `${baseLink}/by/4.0`,
                name: 'Attribution',
                explanation: [explanations[0]],
            },

            {
                text: 'Attribution, share alike',
                value: 'CC BY-SA 4.0',
                ccLink: `${baseLink}/by-sa/4.0`,
                name: 'Attribution, share alike',
                explanation: [explanations[0], explanations[1]],
            },

            {
                text: 'Attribution, non commercial',
                value: 'CC BY-NC 4.0',
                ccLink: `${baseLink}/by-nc/4.0`,
                name: 'Attribution, non commercial',
                explanation: [explanations[0], explanations[2]],
            },
            {
                text: 'Attribution, non commercial, share alike',
                value: 'CC BY-NC-SA 4.0',
                ccLink: `${baseLink}/by-nc-sa/4.0`,
                name: 'Attribution, non commercial, share alike',
                explanation: [
                    explanations[0],
                    explanations[2],
                    explanations[1],
                ],
            },
        ],
    },

    {
        text: 'No rights reserved',
        value: 'no-rights',
        name: 'no-rights',
        explanation: [explanations[6]],
        childLicenses: [
            {
                text: 'Creative Commons copyright waiver',
                value: 'Creative Commons copyright waiver',
                name: 'Creative Commons copyright waiver',
                explanation: [explanations[3]],
            },
            {
                text: 'Public domain',
                value: 'Public domain',
                name: 'Public domain',
                explanation: [explanations[4]],
            },
        ],
    },
];

export const getParent = (
    license: Licencing | undefined
): License | undefined => {
    if (!license) return;
    return licenses.find(({ childLicenses }) =>
        childLicenses.find(({ value }) => value === license)
    );
};

export const mapLicense = (
    license: Licencing | undefined
): License | ChildLicense | undefined => {
    if (!license) return;
    const parent = licenses.find(({ childLicenses }) =>
        childLicenses.find(({ value }) => value === license)
    );

    if (parent) {
        return parent?.childLicenses.find(({ value }) => value === license);
    }

    return licenses.find(({ value }) => value === license);
};
