import { themeGet } from '@styled-system/theme-get';
import { css } from 'styled-components';
import * as styledSystem from 'styled-system';
import theme from '@founders-playbook/ui-themes';

const { get: getKey } = styledSystem;

const kebabize = str => {
    return str
        .split('')
        .map((letter, idx) => {
            return letter.toUpperCase() === letter
                ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
                : letter;
        })
        .join('');
};

export const getTheme = (key: string): any => themeGet(key, getKey(theme, key));

const improvedGetTheme = (props, themeKey, cssKey): any => {
    const cssValue = themeGet(themeKey, getKey(theme, themeKey))(props);

    // If the value from the theme is an array, the styles are responsive
    if (Array.isArray(cssValue)) {
        // get the breakpoints from the theme
        const breakpoints = themeGet(
            'breakpoints',
            getKey(theme, 'breakpoints')
        )(props);

        // map the breakpoints to create css breakpoints
        const cssBreakpoints = breakpoints.map(
            bp => `@media screen and (min-width: ${bp})`
        );

        // add the styles to the css breakpoints
        const responsiveStyles = [
            // default value before responsive styles
            `${kebabize(cssKey)}: ${cssValue[0]};`,

            // TODO: See if it makes more sense to map over styles instead of breakpoints

            // map over breakpoints to add styles to them
            // if there are more breakpoints than styles, repeat the last style
            // until all breakpoints are filled
            ...cssBreakpoints.map(
                (bp, i) =>
                    `${bp} {${kebabize(cssKey)}: ${cssValue[i + 1] ||
                        cssValue[cssValue.length - 1]};}`
            ),
        ];

        return css`
            ${responsiveStyles.join(' ')}
        `;
    }

    return `${kebabize(cssKey)}: ${cssValue}`;
};

export const getStyle = (themeKey: string): any => {
    const cssKey = themeKey.split('.')[themeKey.split('.').length - 1];
    return props => `${improvedGetTheme(props, themeKey, cssKey)}`;
};

export interface ChildSpaceProps {
    childSpaceX?: number;
    childSpaceY?: number;
}

export const childSpace = css<ChildSpaceProps>`
    & > * + * {
        margin-left: ${({ childSpaceX }) => getTheme(`space.${childSpaceX}`)};
        margin-top: ${({ childSpaceY }) => getTheme(`space.${childSpaceY}`)};
    }
`;

export const contentWidth = [
    '100%',
    '432px',
    '480px',
    '656px',
    '656px',
    '1040px',
];
