const colors = {
  gray: ['#F9FAFB', '#F3F4F6', '#E5E7EB', '#D1D5DB', '#9CA3AF', '#6B7280', '#4B5563', '#374151', '#1F2937', '#111827'],
  red: ['#FEF2F2', '#FEE2E2', '#FECACA', '#FCA5A5', '#F87171', '#EF4444', '#DC2626', '#B91C1C', '#991B1B', '#7F1D1D'],
  yellow: [
    '#FFFBEB',
    '#FEF3C7',
    '#FDE68A',
    '#FCD34D',
    '#FBBF24',
    '#F59E0B',
    '#D97706',
    '#B45309',
    '#92400E',
    '#78350F',
  ],
  green: ['#ECFDF5', '#D1FAE5', '#A7F3D0', '#6EE7B7', '#34D399', '#10B981', '#059669', '#047857', '#065F46', '#064E3B'],
  blue: ['#EFF6FF', '#DBEAFE', '#BFDBFE', '#93C5FD', '#60A5FA', '#3B82F6', '#2563EB', '#1D4ED8', '#1E40AF', '#1E3A8A'],
  indigo: [
    '#EEF2FF',
    '#E0E7FF',
    '#C7D2FE',
    '#A5B4FC',
    '#818CF8',
    '#6366F1',
    '#4F46E5',
    '#4338CA',
    '#3730A3',
    '#312E81',
  ],
  purple: [
    '#F5F3FF',
    '#EDE9FE',
    '#DDD6FE',
    '#C4B5FD',
    '#A78BFA',
    '#8B5CF6',
    '#7C3AED',
    '#6D28D9',
    '#5B21B6',
    '#4C1D95',
  ],
  pink: ['#FDF2F8', '#FCE7F3', '#FBCFE8', '#F9A8D4', '#F472B6', '#EC4899', '#DB2777', '#BE185D', '#9D174D', '#831843'],
};

export { colors };
