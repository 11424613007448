import primitives from './primitives';

// TODO: See if we can use breakpoint alias with objects instead of arrays
type ScreenType = [string, string];
type BreakpointsType = [string, string, string, string, string, string];
const mapToBreakpoints = (arr: ScreenType): BreakpointsType => [
    arr[0],
    arr[0],
    arr[0],
    arr[1],
    arr[1],
    arr[1],
];

const {
    colors,
    space,
    fonts,
    fontWeights,
    fontSizes,
    lineHeights,
    radii,
    shadows,
} = primitives;

const text = {
    h2xl: {
        fontFamily: fonts.heading,
        fontSize: mapToBreakpoints([fontSizes[9], fontSizes[11]]),
        fontWeight: fontWeights[2],
        lineHeight: lineHeights[1],
        color: colors.silver[900],
    },
    hxl: {
        fontFamily: fonts.heading,
        fontSize: mapToBreakpoints([fontSizes[7], fontSizes[9]]),
        fontWeight: fontWeights[2],
        lineHeight: lineHeights[1],
        color: colors.silver[900],
    },
    hlg: {
        fontFamily: fonts.primary,
        fontSize: mapToBreakpoints([fontSizes[6], fontSizes[7]]),
        fontWeight: fontWeights[1],
        lineHeight: lineHeights[1],
        color: colors.silver[900],
    },
    hmd: {
        fontFamily: fonts.primary,
        fontSize: mapToBreakpoints([fontSizes[5], fontSizes[6]]),
        fontWeight: fontWeights[1],
        lineHeight: lineHeights[1],
        color: colors.silver[900],
    },
    hsm: {
        fontFamily: fonts.primary,
        fontSize: mapToBreakpoints([fontSizes[4], fontSizes[5]]),
        fontWeight: fontWeights[1],
        lineHeight: lineHeights[1],
        color: colors.silver[900],
    },
    hxs: {
        fontFamily: fonts.primary,
        fontSize: mapToBreakpoints([fontSizes[3], fontSizes[4]]),
        fontWeight: fontWeights[1],
        lineHeight: lineHeights[1],
        color: colors.silver[900],
    },
    h2xs: {
        fontFamily: fonts.primary,
        fontSize: mapToBreakpoints([fontSizes[2], fontSizes[3]]),
        fontWeight: fontWeights[2],
        lineHeight: lineHeights[1],
        color: colors.silver[900],
    },
    xxl: {
        fontSize: mapToBreakpoints([fontSizes[4], fontSizes[5]]),
        fontWeight: fontWeights[0],
        lineHeight: lineHeights[3],
        color: colors.silver[900],
    },
    xl: {
        fontSize: mapToBreakpoints([fontSizes[3], fontSizes[4]]),
        fontWeight: fontWeights[0],
        lineHeight: lineHeights[3],
        color: colors.silver[900],
    },
    lg: {
        fontSize: mapToBreakpoints([fontSizes[2], fontSizes[3]]),
        fontWeight: fontWeights[0],
        lineHeight: lineHeights[3],
        color: colors.silver[900],
    },
    md: {
        fontSize: mapToBreakpoints([fontSizes[0], fontSizes[2]]),
        fontWeight: fontWeights[0],
        lineHeight: lineHeights[1],
        color: colors.silver[900],
    },
    sm: {
        fontSize: mapToBreakpoints([fontSizes[3], fontSizes[5]]),
        fontWeight: fontWeights[1],
        lineHeight: lineHeights[0],
        color: colors.silver[900],
    },
    xs: {
        fontSize: mapToBreakpoints([fontSizes[2], fontSizes[3]]),
        fontWeight: fontWeights[1],
        lineHeight: lineHeights[2],
        color: colors.silver[900],
    },
    xxs: {
        fontSize: mapToBreakpoints([fontSizes[0], fontSizes[2]]),
        fontWeight: fontWeights[1],
        lineHeight: lineHeights[2],
        color: colors.silver[900],
    },
};

const btn = {
    base: {
        color: colors.silver['900'],
        fontFamily: fonts.primary,
        fontWeight: text.sm.fontWeight,
        fontSize: text.sm.fontSize,
        lineHeight: text.sm.lineHeight,
        borderRadius: radii[3],
        padding: [
            `${space[4]} ${space[7]}`,
            `${space[4]} ${space[7]}`,
            `${space[4]} ${space[7]}`,
            `${space[6]} ${space[7]}`,
        ],
        outline: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    primary: {
        default: {
            bg: colors.gold['600'],
            border: 'none',
        },
        hover: {
            bg: colors.gold['500'],
            boxShadow: shadows.buttonHover,
        },
        focus: {
            border: 'none',
            boxShadow: `0 0 0 2px ${colors.gold['300']} inset`,
        },
        active: {
            bg: colors.gold['600'],
        },
        disabled: {
            bg: colors.gold['200'],
            color: colors.silver['500'],
        },
    },
    secondary: {
        default: {
            bg: colors.white['100'],
            border: `1px solid ${colors.silver['100']}`,
        },
        hover: {
            boxShadow: shadows.buttonHover,
        },
        focus: {
            boxShadow: `0 0 0 2px ${colors.silver['200']} inset`,
        },
        active: {
            bg: colors.silver['100'],
            boxShadow: `0 0 0 2px ${colors.silver['100']} inset`,
        },
        disabled: {
            color: colors.silver['300'],
        },
    },
    tertiary: {
        default: {
            bg: colors.silver['900'],
            color: colors.white['100'],
        },
        hover: {
            bg: colors.silver['700'],
            boxShadow: shadows.buttonHover,
        },
        focus: {
            boxShadow: `0 0 0 2px ${colors.silver['300']} inset`,
        },
        active: {
            bg: colors.silver['800'],
        },
        disabled: {
            // bg: colors.silver['300'],
            // TODO: Update this in all buttons
            opacity: 0.5,
        },
    },
    icon: {
        default: {
            bg: 'none',
            color: colors.white['100'],
            width: '40px',
            height: '40px',
            borderRadius: '100%',
            padding: [space[4], space[4]],
        },
        hover: {
            bg: colors.black['10'],
        },
        focus: {
            boxShadow: `0 0 0 2px ${colors.silver['100']} inset`,
        },
        active: {
            bg: colors.black['20'],
        },
        disabled: {
            opacity: 0.3,
        },
    },
    dropdown: {
        default: {
            bg: colors.white['100'],
            border: `none`,
            justifyContent: 'flex-start',
            padding: [`${space[4]} ${space[6]}`, `${space[4]} ${space[6]}`],
            activeBg: colors.silver['25'],
            fontWeight: text.lg.fontWeight,
            fontSize: text.lg.fontSize,
            lineHeight: text.lg.lineHeight,
        },
        hover: {
            bg: colors.silver['25'],
        },
        focus: {
            boxShadow: `0 0 0 2px ${colors.silver['200']} inset`,
        },
        active: {
            bg: colors.silver['100'],
            boxShadow: 'none',
        },
        disabled: {
            color: colors.silver['300'],
        },
    },
    link: {
        default: {
            bg: 'transparent',
            color: colors.silver['600'],
            textDecoration: 'underline',
            padding: [0, 0],
            fontSize: text.xl.fontSize,
            fontWeight: text.xl.fontWeight,
        },
    },
    dot: {
        default: {
            bg: colors.gold['500'],
            color: colors.silver['900'],
        },
        hover: {
            bg: colors.gold['600'],
        },
        focus: {
            boxShadow: `inset 0 0 0 2px ${colors.gold['300']}`,
        },
        active: {
            bg: colors.gold['600'],
        },
        disabled: {
            opacity: 0.4,
        },
    },
};

const input = {
    default: {
        bg: colors.white['100'],
        border: `1px solid ${colors.silver['100']}`,
        borderRadius: radii[3],
        padding: [
            `${space[4]} ${space[5]}`,
            `${space[4]} ${space[5]}`,
            `${space[4]} ${space[5]}`,
            space[6],
        ],
    },
    placeholder: {
        color: colors.silver['300'],
    },
    hover: {
        cursor: 'text',
    },
    focus: {
        borderColor: colors.gold['600'],
        boxShadow: `0 0 0 1px ${colors.gold['600']} inset`,
    },
    active: {
        borderColor: colors.gold['600'],
        boxShadow: `0 0 0 1px ${colors.gold['600']} inset`,
    },
    disabled: {
        color: colors.silver['500'],
    },
};

const checkbox = {
    default: {
        bg: colors.white['100'],
        border: `1px solid ${colors.silver['100']}`,
        borderRadius: radii[3],
        color: `${colors.silver['900']}`,
        width: '16px',
        height: '16px',
    },
};

const tag = {
    base: {},
    primary: {
        default: {
            bg: colors.white['100'],
            border: `1px solid ${colors.silver['50']}`,
            color: text.xs.color,
        },
        hover: {
            boxShadow: shadows.buttonHover,
        },
        focus: {
            border: `1px solid ${colors.silver['100']}`,
            boxShadow: `inset 0 0 0 1px ${colors.silver['100']}`,
        },
        active: {
            bg: colors.silver['50'],
            border: `1px solid ${colors.silver['100']}`,
            boxShadow: `inset 0 0 0 1px ${colors.silver['100']}`,
        },
        disabled: {
            opacity: 0.4,
        },
    },
    secondary: {
        default: {
            bg: colors.silver['900'],
            border: `1px solid ${colors.silver['900']}`,
            color: colors.white['100'],
        },
        hover: {
            bg: colors.silver['700'],
            border: `1px solid ${colors.silver['700']}`,
        },
        focus: {
            border: `1px solid ${colors.silver['300']}`,
            boxShadow: `inset 0 0 0 1px ${colors.silver['300']}`,
        },
        active: {
            bg: colors.silver['800'],
            border: colors.silver['800'],
            boxShadow: `inset 0 0 0 1px ${colors.silver['800']}`,
        },
        disabled: {
            opacity: 0.4,
        },
    },
    tertiary: {
        default: {
            bg: colors.red['100'],
            border: 'none',
            borderRadius: radii[2],
            color: colors.silver['900'],
        },
    },
};

export default {
    ...primitives,
    btn,
    text,
    input,
    tag,
    checkbox,
};
