import React from 'react';
import theme from '@founders-playbook/ui-themes';
import { Icon } from '@founders-playbook/component-library';
import { useHistory } from 'react-router-dom';
import { DASHBOARD_ROUTE } from '../constants';
import { Box, Text } from '.';
import { useDropdown } from '../hooks';
import styled from 'styled-components';
import { getTheme } from '../helpers/style.helper';
import { Button } from './button';
import { Hr } from './hr';
import { Heading } from './heading';
import { timeDiffCalc } from '../helpers';
import { History } from 'history';
import { useTranslation } from 'react-i18next';

interface Props {
    title: string;
    backNav?: string;
    navigationButton?: React.ReactElement;
    headerActions?: IHeaderAction[];
    latestDraft?: string;
}

export const EditorHeader: React.FC<Props> = ({
    title,
    latestDraft,
    navigationButton,
    headerActions,
    backNav,
}) => {
    const history: History<{ toDashboard?: boolean }> = useHistory();

    const {
        Dropdown,
        dropdownProps,
        positionalRef,
        toggleDropdown,
    } = useDropdown({ placement: 'bottom-start' });
    const actionsAvailable = headerActions && headerActions.length > 0;

    const handleClick = () => {
        if (backNav) {
            history.push(backNav, history.location.state);
            return;
        }

        if (history?.location?.state?.toDashboard) {
            history.push(`/${DASHBOARD_ROUTE}`);
            return;
        } else
            history.length === 0
                ? history.push(`/${DASHBOARD_ROUTE}`)
                : history.goBack();
        return;
    };

    const generateLastUpdated = () =>
        latestDraft ? timeDiffCalc(new Date(latestDraft), new Date()) : '';

    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width={1}
            borderBottom="1px solid"
            borderColor="silver.50"
            bg="white.100"
            pr={[7, 7, 7, 7, 6]}
            flexShrink={0}
        >
            <Box display="flex" alignItems="center">
                <BackButton onClick={handleClick}>
                    <Icon
                        iconName="arrowLeft"
                        color={theme.colors.silver['600']}
                    />
                </BackButton>

                <Box flex={1}>
                    <Text variant="sm" ml={[0, 0, 0, 8]}>
                        {title}
                    </Text>
                </Box>

                {actionsAvailable && (
                    <Button
                        ml={3}
                        variant="icon"
                        ref={positionalRef}
                        onClick={toggleDropdown}
                    >
                        <Icon iconName="chevronDown" />
                    </Button>
                )}
            </Box>

            {actionsAvailable && (
                <Dropdown {...dropdownProps} removeDefaultPadding>
                    <ActionDropdownContent
                        lastUpdated={generateLastUpdated}
                        actions={headerActions}
                    />
                </Dropdown>
            )}

            {navigationButton}
        </Box>
    );
};

const ActionDropdownContent = ({ lastUpdated, actions }) => {
    const { t } = useTranslation();

    return (
        <>
            <Box p={8}>
                <Heading variant="md">
                    {t('editor.actions.dropdown.title')}
                </Heading>
                {lastUpdated && (
                    <Text>
                        {t('dashboard.tiles.lastUpdated', {
                            time: lastUpdated(),
                        })}
                    </Text>
                )}
            </Box>
            <Hr />
            <Box
                px={6}
                py={7}
                display="flex"
                flexDirection="column"
                alignItems="stretch"
            >
                {actions &&
                    actions?.map(
                        ({
                            icon,
                            onClick,
                            name,
                            show = true,
                            disabled = false,
                        }) => (
                            <React.Fragment key={name}>
                                {show && (
                                    <Button
                                        variant="dropdown"
                                        onClick={onClick}
                                        disabled={disabled}
                                    >
                                        <>
                                            {icon && (
                                                <Icon
                                                    iconName={icon}
                                                    color={
                                                        theme.colors.silver[
                                                            disabled
                                                                ? '300'
                                                                : '900'
                                                        ]
                                                    }
                                                />
                                            )}
                                            <Text
                                                ml={5}
                                                color={
                                                    disabled
                                                        ? 'silver.300'
                                                        : 'silver.900'
                                                }
                                            >
                                                {name}
                                            </Text>
                                        </>
                                    </Button>
                                )}
                            </React.Fragment>
                        )
                    )}
            </Box>
        </>
    );
};

const BackButton = styled.button`
    padding: 28px;
    border: none;
    background: transparent;

    @media (min-width: ${theme.breakpoints[2]}) {
        padding: 32px;
        border-right: 1px solid ${getTheme('colors.silver.50')};
    }
`;
