export const API_BASE_URL = process.env.REACT_APP_API_HOST_API_URL;

// main routes
export const API_ROUTE_ORGANISATIONS = 'organisations';
export const API_ROUTE_PLAYS = 'plays';
export const API_ROUTE_PLAYBOOKS = 'playbooks';
export const API_ROUTE_USERS = 'users';
export const API_ROUTE_TAGS = 'tags';
export const API_ROUTE_MEDIA = 'media';

// sub routes
export const API_ROUTE_CREATE = 'create';
export const API_ROUTE_MEMBERS = 'members';
export const API_ROUTE_USER = 'user';
export const API_ROUTE_ME = 'me';
export const API_ROUTE_SLUG = 'slug';
export const API_ROUTE_ADD = 'add';
export const API_ROUTE_PUBLIC = 'public';
export const API_ROUTE_UPDATE = 'update';
export const API_ROUTE_ORGANISATION = 'organisation';
export const API_ROUTE_CONTENT = 'content';
export const API_ROUTE_RECENT = 'recent';
export const API_ROUTE_UPLOAD = 'upload';
export const API_ROUTE_ROOT = 'root';
export const API_ROUTE_PAYMENT = 'payment';
export const API_ROUTE_CANCEL = 'cancel';

export const API_ROUTE_INVITE = 'invite';
export const API_ROUTE_ACCEPT = 'accept';

export const API_ROUTE_SUBSCRIPTION = 'subscription';
export const API_ROUTE_ONBOARDING = 'onboarding';

export const API_ROUTE_RESTORE = 'restore';
export const API_ROUTE_ROLE = 'role';

export const API_ROUTE_PUBLISH = 'publish';
export const API_ROUTE_DRAFT = 'draft';
export const API_ROUTE_UNPUBLISH = 'unpublish';

export const API_ROUTE_ALL = 'all';

export const API_BUCKET_DEFAULT = 'bucket/default';
