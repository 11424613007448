import { createSlice } from '@reduxjs/toolkit';

const initialState = {} as IOrganisation;

const activeOrgSlice = createSlice({
    name: 'active org',
    initialState,
    reducers: {
        setActiveOrg: (state, { payload: { organisation } }) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            return organisation;
        },
    },
});

export const activeOrgReducer = activeOrgSlice.reducer;
export const { setActiveOrg } = activeOrgSlice.actions;

export const selectActiveOrg = state => state.activeOrg;

export type IInitialActiveOrgState = {
    activeOrg: Partial<IOrganisation>;
};
