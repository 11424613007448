import styled, { css } from 'styled-components';
import {
    compose,
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    space,
    SpaceProps,
    variant,
} from 'styled-system';

import theme from '@founders-playbook/ui-themes';
import { getTheme } from '../../helpers/style.helper';
import React from 'react';
import { Text } from '../text';

const BaseTagStyles = css`
    border-radius: ${getTheme('radii.5')};
    padding: ${getTheme('space.3')} ${getTheme('space.6')};
    /* font-family: ${theme.fonts.primary}; */
    /* font-size: ${theme.text.xs.fontSize}; */
    /* font-weight: ${theme.text.xs.fontWeight}; */
    /* line-height: ${theme.text.xs.lineHeight}; */
    outline: none;
    box-shadow: none;
    cursor: pointer;
`;

const baseVariants = variant({
    variants: {
        primary: {
            backgroundColor: theme.tag.primary.default.bg,
            border: theme.tag.primary.default.border,
            // color: theme.tag.primary.default.color,
        },
        secondary: {
            backgroundColor: theme.tag.secondary.default.bg,
            border: theme.tag.secondary.default.border,
            // color: theme.tag.secondary.default.color,
        },
        tertiary: {
            backgroundColor: theme.tag.tertiary.default.bg,
            border: theme.tag.tertiary.default.border,
            borderRadius: theme.tag.tertiary.default.borderRadius,
            // color: theme.tag.tertiary.default.color,
        },
    },
});

const interactiveVariants = variant({
    variants: {
        primary: {
            '&:hover': {
                boxShadow: theme.tag.primary.hover.boxShadow,
            },
            '&:focus': {
                border: theme.tag.primary.focus.border,
                boxShadow: theme.tag.primary.focus.boxShadow,
            },
            '&:active': {
                background: theme.tag.primary.active.bg,
                border: theme.tag.primary.active.border,
                boxShadow: theme.tag.primary.active.boxShadow,
            },
            '&:disabled': {
                opacity: theme.tag.primary.disabled.opacity,
            },
        },
        secondary: {
            '&:hover': {
                background: theme.tag.secondary.hover.bg,
                border: theme.tag.secondary.hover.border,
            },
            '&:focus': {
                border: theme.tag.secondary.focus.border,
                boxShadow: theme.tag.secondary.focus.boxShadow,
            },
            '&:active': {
                background: theme.tag.secondary.active.bg,
                border: theme.tag.secondary.active.border,
                boxShadow: theme.tag.secondary.active.boxShadow,
            },
            '&:disabled': {
                opacity: theme.tag.secondary.disabled.opacity,
            },
        },
        tertiary: {},
    },
});

interface StyledTagProps extends LayoutProps, FlexboxProps, SpaceProps {
    variant?: 'primary' | 'secondary' | 'tertiary';
    fixed?: boolean;
    ref?: any;
}

interface TagProps extends StyledTagProps {
    children: string;
    onClick?: () => void;
    ref?: any;
}

const StyledTag = styled.button<StyledTagProps>`
    ${BaseTagStyles};
    ${baseVariants};
    ${({ fixed = false }) => !fixed && interactiveVariants};

    ${({ fixed = false }) => fixed && `cursor: auto`};

    ${compose(layout, flexbox, space)};
`;

export const Tag = ({ children, variant, ref, ...rest }: TagProps) => (
    <StyledTag
        {...rest}
        // ref={ref}
        variant={variant}
        as={rest.fixed ? 'div' : 'button'}
        tabIndex={rest.fixed ? -1 : 0}
    >
        <Text
            color={
                variant === 'secondary'
                    ? theme.colors.white['100']
                    : theme.colors.silver['900']
            }
        >
            {children}
        </Text>
    </StyledTag>
);

Tag.defaultProps = {
    variant: 'primary',
};
