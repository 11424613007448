import { createSlice } from '@reduxjs/toolkit';

const initialState = {} as IOrganisation[];

const organisationSlice = createSlice({
    name: 'organisations ',
    initialState,
    reducers: {
        setAllOrganisations: (state, { payload: { organisations } }) => {
            return organisations;
        },
        addOrganisation: (state, { payload: { organisation } }) => {
            return state.concat(organisation);
        },
    },
});

export const organisationReducer = organisationSlice.reducer;
export const {
    setAllOrganisations,
    addOrganisation,
} = organisationSlice.actions;

// @TODO rename the key to the name of the slice 'organisations'
export const selectOrganisations = state => state.organisationState;
