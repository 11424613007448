import React, { useEffect } from 'react';
import { hydrate, render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import TagManager from 'react-gtm-module';

import reportWebVitals from './reportWebVitals';

import {
    AuthProvider,
    QueryProvider,
    ModalProvider,
    ToastProvider,
} from './providers';
import { AppRouter } from './routes';
import { ReduxProvider } from './redux';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { normalize } from 'styled-normalize';
import theme from '@founders-playbook/ui-themes';
import './config/i18n';

import 'draft-js/dist/Draft.css';
import '@founders-playbook/ui-themes/src/fonts/Inter/Inter.css';
import '@founders-playbook/ui-themes/src/fonts/GTFlexa/GTFlexa.css';
import { getTheme } from './helpers/style.helper';
import { Toast } from './components/toast/toast';
import { toolbarStyles } from './components/draft-editor/toolbar';
import { PageProvider } from './providers/page';
import { CookieConsent } from './components/cookie-consent';

const GlobalStyle = createGlobalStyle`
  ${normalize}
  ${toolbarStyles}

  a {
      color: inherit;
      text-decoration: none;
  }

  html, body {
    height: 100%;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
        Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif;
    -webkit-font-smoothing: antialiased;
  }
  
  html {
    box-sizing: border-box;
    overflow-wrap: break-word; 
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
  
  #root {
    white-space: pre-line;

    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100vh;

    @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
    }
  }

  .DraftEditor-root {
      font-size: 16px;
      line-height: 150%;

      .public-DraftEditorPlaceholder-root {
        color: ${getTheme('colors.silver.300')};
      }

      @media (min-width: ${theme.breakpoints[2]}) {
        font-size: 18px;
      } 
  }


  .draftPlayEditor .DraftEditor-root {
     line-height: 175%;
     color: ${getTheme('colors.silver.700')};
     min-height: inherit;
    }

    .DraftEditor-editorContainer, .public-DraftEditor-content {
        min-height: inherit;
    }


  #settings, #modal {
      z-index: 50;
      position: relative;
  }

  #confirmation-modal {
      z-index: 60;
      position: relative;
  }
`;

export const AppWrapper = () => {
    useEffect(() => {
        if (process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID) {
            const args: any = {
                gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
            };
            if (process.env.REACT_APP_GOOGLE_TAG_AUTH) {
                args.auth = process.env.REACT_APP_GOOGLE_TAG_AUTH;
            }
            TagManager.initialize(args);
        }
    }, []);

    return (
        <React.StrictMode>
            <Helmet
                defaultTitle="Founders Playbook"
                titleTemplate="%s - Founders Playbook"
            >
                <meta charSet="utf-8" />
                <meta property="og:url" content={window.location.href} />
                <meta name="author" content="Founders Playbook" />
            </Helmet>
            <ReduxProvider>
                <Router>
                    <AuthProvider>
                        <QueryProvider>
                            <ToastProvider>
                                <ModalProvider>
                                    <PageProvider>
                                        <ThemeProvider theme={theme}>
                                            <GlobalStyle />
                                            <AppRouter />
                                            <Toast />
                                            <CookieConsent />
                                        </ThemeProvider>
                                    </PageProvider>
                                </ModalProvider>
                            </ToastProvider>
                        </QueryProvider>
                    </AuthProvider>
                </Router>
            </ReduxProvider>
        </React.StrictMode>
    );
};

const rootElement = document.getElementById('root');
if (rootElement?.hasChildNodes()) {
    hydrate(<AppWrapper />, rootElement);
} else {
    render(<AppWrapper />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
