import styled, { css } from 'styled-components';
import {
    color,
    ColorProps,
    compose,
    layout,
    LayoutProps,
    shadow,
    ShadowProps,
    space,
    SpaceProps,
    variant,
} from 'styled-system';

import theme from '@founders-playbook/ui-themes';
import { getTheme, getStyle } from '../../helpers/style.helper';

// TODO: need to enable using button as link

const BaseButtonStyles = css`
    color: ${getTheme('btn.base.color')};
    font-family: ${getTheme('btn.base.fontFamily')};
    font-weight: ${getTheme('btn.base.fontWeight')};
    ${getStyle('btn.base.fontSize')};
    line-height: ${getTheme('btn.base.lineHeight')};
    border-radius: ${getTheme('btn.base.borderRadius')};
    padding: ${getTheme('btn.base.padding')};
    ${getStyle('btn.base.padding')};
    outline: ${getTheme('btn.base.outline')};
    border: ${getTheme('btn.base.border')};
    cursor: ${getTheme('btn.base.cursor')};

    display: flex;
    align-items: center;
    justify-content: center;
`;

const activeVariants = variant({
    variants: {
        primary: {
            backgroundColor: theme.btn.primary.active.bg,
        },
        secondary: {
            boxShadow: theme.btn.secondary.active.boxShadow,
            backgroundColor: theme.btn.secondary.active.bg,
        },
        tertiary: {
            background: theme.btn.tertiary.active.bg,
        },
        icon: {
            background: theme.btn.icon.active.bg,
        },
        dropdown: {
            backgroundColor: theme.btn.dropdown.default.activeBg,
            boxShadow: theme.btn.dropdown.active.boxShadow,
        },
        link: {},
    },
});

const variants = variant({
    variants: {
        primary: {
            background: theme.btn.primary.default.bg,
            border: theme.btn.primary.default.border,

            '&:hover:enabled': {
                backgroundColor: theme.btn.primary.hover.bg,
                boxShadow: theme.btn.primary.hover.boxShadow,
            },
            '&:focus': {
                boxShadow: theme.btn.primary.focus.boxShadow,
                border: theme.btn.primary.focus.border,
            },
            '&:active:enabled': {
                backgroundColor: theme.btn.primary.active.bg,
            },
            '&:disabled': {
                color: theme.btn.primary.disabled.color,
                backgroundColor: theme.btn.primary.disabled.bg,
            },
        },
        secondary: {
            background: theme.btn.secondary.default.bg,
            border: theme.btn.secondary.default.border,

            '&:hover:enabled': {
                boxShadow: theme.btn.secondary.hover.boxShadow,
            },
            '&:focus': {
                boxShadow: theme.btn.secondary.focus.boxShadow,
            },
            '&:active:enabled': {
                boxShadow: theme.btn.secondary.active.boxShadow,
                backgroundColor: theme.btn.secondary.active.bg,
            },
            '&:disabled': {
                color: theme.btn.secondary.disabled.color,
            },
        },
        tertiary: {
            background: theme.btn.tertiary.default.bg,
            color: theme.btn.tertiary.default.color,

            '&:hover:enabled': {
                background: theme.btn.tertiary.hover.bg,
                boxShadow: theme.btn.tertiary.hover.boxShadow,
            },
            '&:focus': {
                boxShadow: theme.btn.tertiary.focus.boxShadow,
            },
            '&:active:enabled': {
                background: theme.btn.tertiary.active.bg,
            },
            '&:disabled': {
                // background: theme.btn.tertiary.disabled.bg,
                // TODO: Update this for all buttons
                opacity: theme.btn.tertiary.disabled.opacity,
                cursor: 'default',
            },
        },
        icon: {
            background: theme.btn.icon.default.bg,
            color: theme.btn.icon.default.color,
            width: theme.btn.icon.default.width,
            height: theme.btn.icon.default.height,
            borderRadius: theme.btn.icon.default.borderRadius,
            padding: theme.btn.icon.default.padding,

            '&:hover:enabled': {
                background: theme.btn.icon.hover.bg,
            },
            '&:focus': {
                boxShadow: theme.btn.icon.focus.boxShadow,
            },
            '&:active:enabled': {
                background: theme.btn.icon.active.bg,
            },
            '&:disabled': {
                opacity: theme.btn.icon.disabled.opacity,
            },
        },
        dropdown: {
            background: theme.btn.dropdown.default.bg,
            border: theme.btn.dropdown.default.border,
            justifyContent: theme.btn.dropdown.default.justifyContent,
            padding: theme.btn.dropdown.default.padding,
            fontWeight: theme.btn.dropdown.default.fontWeight,
            fontSize: theme.btn.dropdown.default.fontSize,
            lineHeight: theme.btn.dropdown.default.lineHeight,

            '&:hover:enabled': {
                background: theme.btn.dropdown.hover.bg,
            },
            '&:focus': {
                boxShadow: theme.btn.dropdown.focus.boxShadow,
            },
            '&:active:enabled': {
                backgroundColor: theme.btn.dropdown.active.bg,
                boxShadow: theme.btn.dropdown.active.boxShadow,
            },
            '&:disabled': {
                color: theme.btn.dropdown.disabled.color,
            },
        },
        link: {
            background: theme.btn.link.default.bg,
            color: theme.btn.link.default.color,
            textDecoration: theme.btn.link.default.textDecoration,
            padding: theme.btn.link.default.padding,
            fontSize: theme.btn.link.default.fontSize,
            fontWeight: theme.btn.link.default.fontWeight,

            '&:hover:disabled': {
                cursor: 'auto',
            },

            '&:disabled': {
                color: theme.btn.dropdown.disabled.color,
            },
        },
    },
});

interface ButtonProps extends SpaceProps, ColorProps, LayoutProps, ShadowProps {
    ref?: any;
    variant?:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'dropdown'
        | 'icon'
        | 'link';
    children: React.ReactElement | React.ReactElement[] | string;
    showAsActive?: boolean;
    iconShape?: 'round' | 'square';
}

const Button = styled.button<ButtonProps>`
    ${BaseButtonStyles}

    ${variants}
    ${({ showAsActive }) => showAsActive && activeVariants}

    ${compose(space, color, layout, shadow)}
    
    ${({ variant, iconShape, theme }) => {
        if (variant !== 'icon') return;
        if (iconShape === 'round') {
            return `border-radius: ${theme.radii[5]}`;
        }

        return `border-radius: ${theme.radii[2]}`;
    }}
`;

Button.defaultProps = {
    variant: 'primary',
    showAsActive: false,
    iconShape: 'round',
};

export default Button;
