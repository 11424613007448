import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import theme from '@founders-playbook/ui-themes';
import { Box, Text } from '.';
import { getStyle } from '../helpers';

interface TextAreaProps extends SpaceProps {
    variant?:
        | 'hxl'
        | 'hlg'
        | 'hmd'
        | 'hsm'
        | 'hxs'
        | 'xl'
        | 'lg'
        | 'md'
        | 'sm'
        | 'xs';
    maxLength?: number;
    value?: string;
    onChange?: (e: React.FormEvent<HTMLTextAreaElement>) => void;
    rows?: number;
    label?: string;
    name: string;
    placeholder?: string;
}

const StyledTextArea = styled.textarea<TextAreaProps>`
    position: relative;
    width: 100%;
    border: ${theme.input.default.border};
    border-radius: ${theme.input.default.borderRadius};
    ${getStyle('input.default.padding')};
    ${({ variant }) => getStyle(`text.${variant}.fontSize`)};
    ${({ variant }) => getStyle(`text.${variant}.fontWeight`)};
    ${({ variant }) => getStyle(`text.${variant}.lineHeight`)};
    ${({ variant }) => getStyle(`text.${variant}.color`)};
    resize: none;

    &:hover:enabled {
        cursor: ${theme.input.hover.cursor};
    }

    &:focus:enabled {
        outline: none;
        border-color: ${theme.input.focus.borderColor};
        box-shadow: ${theme.input.focus.boxShadow};
    }

    &:active:enabled {
        outline: none;
        border-color: ${theme.input.active.borderColor};
        box-shadow: ${theme.input.active.boxShadow};
    }

    &:disabled {
        color: ${theme.input.disabled.color};
    }

    &::placeholder {
        color: ${theme.input.placeholder.color};
    }

    ${space};
`;

export const TextArea = ({
    variant = 'lg',
    maxLength,
    value,
    onChange,
    rows = 3,
    label,
    name,
    placeholder = '',
}: TextAreaProps) => (
    <Box position="relative" mt={[6, 7]}>
        <>
            {label && (
                <Text as="label" htmlFor={name} fontWeight={500}>
                    {label}
                </Text>
            )}
            <StyledTextArea
                mt={5}
                variant={variant}
                value={value}
                onChange={onChange}
                maxLength={maxLength}
                placeholder={placeholder}
                rows={rows}
                name={name}
            />
            {!!maxLength && (
                <Box position="absolute" right={4} bottom={4}>
                    <Text
                        variant="xxs"
                        color={
                            value?.length === maxLength
                                ? 'red.300'
                                : 'silver.200'
                        }
                    >
                        {value?.length || 0} / {maxLength}
                    </Text>
                </Box>
            )}
        </>
    </Box>
);
