import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Text, Chip } from '../';
import { ConditionalLinkWrapper } from '../conditional-link-wrapper';

const MAX_INNER_TILES = 4;

export interface TileWithInnerTilesProps {
    secondary?: string;
    title?: string;
    innerTiles: { color: string }[];
    link?: string;
    color?: string;
    dateString: string;
    chips: { text: string; color: string }[];
    divider?: number;
}

const baseSqOuter = [88, 88, 128, 128];
const baseSqInner = [38, 38, 52, 52];

export const TileWithInnerTiles = ({
    secondary,
    color,
    title,
    innerTiles,
    link,
    dateString,
    chips = [],
    divider = 1,
}: TileWithInnerTilesProps) => {
    const { t } = useTranslation();

    const outerSq = baseSqOuter.map(s => `${s / divider}px`);

    const innerSq = baseSqInner.map(s => `${s / divider}px`);
    const divide = (elem: number) => Math.ceil(elem / divider);

    return (
        <ConditionalLinkWrapper link={link}>
            <Box display="flex" alignItems="center" width={1}>
                <Box
                    p={[2, 2, 3, 3].map(divide)}
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    flexWrap="wrap"
                    height={outerSq}
                    width={outerSq}
                    bg={innerTiles.length ? 'silver.50' : color}
                    flexShrink={0}
                    borderRadius={[3, 3, 4, 4].map(divide)}
                >
                    {innerTiles
                        .slice(
                            0,
                            innerTiles.length > MAX_INNER_TILES
                                ? MAX_INNER_TILES - 1
                                : MAX_INNER_TILES
                        )
                        .map(({ color }, i) => {
                            return (
                                <Box
                                    key={i}
                                    m={[2, 2, 3, 3].map(divide)}
                                    height={innerSq}
                                    width={innerSq}
                                    bg={color}
                                    flexShrink={0}
                                    borderRadius={[2, 2, 4, 4].map(divide)}
                                />
                            );
                        })}
                    {innerTiles.length > MAX_INNER_TILES && (
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            m={[2, 2, 3, 3].map(divide)}
                            height={innerSq}
                            width={innerSq}
                            bg={'pink.200'}
                            flexShrink={0}
                            borderRadius={[2, 2, 4, 4].map(divide)}
                        >
                            <Text fontWeight={500} color={'pink.600'}>
                                +{innerTiles.length - MAX_INNER_TILES + 1}
                            </Text>
                        </Box>
                    )}
                </Box>
                <Box
                    display="flex"
                    flexDirection="column"
                    ml={[4, 6]}
                    alignItems="flex-start"
                >
                    {chips.map(({ text, color }, index) => (
                        <Chip key={index} text={text} color={color} />
                    ))}

                    {title ? (
                        <Heading variant="sm">{title}</Heading>
                    ) : (
                        <Heading variant="sm" color="silver.300">
                            {t('dashboard.tiles.titlePlaceholder', {
                                contentType: 'playbook',
                            })}
                        </Heading>
                    )}

                    {secondary && (
                        <Text variant="md" fontWeight={1}>
                            {secondary}
                        </Text>
                    )}
                    <Text variant="lg" mt={4} color="silver.500">
                        {t('dashboard.tiles.lastUpdated', { time: dateString })}
                    </Text>
                </Box>
            </Box>
        </ConditionalLinkWrapper>
    );
};
