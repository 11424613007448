import styled, { css } from 'styled-components';
import { applyDefaultTheme } from '../../helpers';

export type SizeType = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';

interface StyledHeadingProps {
  size: SizeType;
}

const HeadingCSS = css<StyledHeadingProps>`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: ${({ theme, size }) =>
    (size === 'xs' && theme.fontSizes[2]) ||
    (size === 'sm' && theme.fontSizes[4]) ||
    (size === 'md' && theme.fontSizes[5]) ||
    (size === 'lg' && theme.fontSizes[6]) ||
    (size === 'xl' && theme.fontSizes[7]) ||
    (size === '2xl' && theme.fontSizes[8])};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ theme }) => theme.colors.text.default};
`;

export const H1 = styled.h1.attrs(applyDefaultTheme)<StyledHeadingProps>`
  ${HeadingCSS};
`;
export const H2 = styled.h2.attrs(applyDefaultTheme)<StyledHeadingProps>`
  ${HeadingCSS};
`;
export const H3 = styled.h3.attrs(applyDefaultTheme)<StyledHeadingProps>`
  ${HeadingCSS};
`;
export const H4 = styled.h4.attrs(applyDefaultTheme)<StyledHeadingProps>`
  ${HeadingCSS};
`;
export const H5 = styled.h5.attrs(applyDefaultTheme)<StyledHeadingProps>`
  ${HeadingCSS};
`;
