import React, { useContext, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ToastContext } from '../../providers';
import { addElementToDom } from '../../helpers';
import { ToastDisplay } from './toast-display';

export const Toast = () => {
    const [toast, setToast] = useContext(ToastContext);
    const node = document.getElementById('toast') ?? addElementToDom('toast');

    useEffect(() => {
        const timeout = setTimeout(() => {
            setToast(null);
        }, 2000);

        return () => {
            clearTimeout(timeout);
        };
    }, [toast, setToast]);

    if (!toast) {
        return null;
    }

    return createPortal(
        <ToastDisplay
            handleClick={() => setToast(null)}
            type={toast.type}
            message={toast.message}
        />,
        node
    );
};
