// @ts-nocheck

import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTable, usePagination } from 'react-table';
import { getTheme } from '../../helpers';
import { Icon } from '@founders-playbook/component-library';
import { Flexbox } from '../box';
import { Button } from '../button';
import { Span } from '../span';

const Styles = styled.div`
    table {
        width: 100%;
        overflow-x: scroll;
        border-spacing: 0;

        th,
        td {
            padding: 24px 0px;
            text-align: left;
            line-height: 175%;
            margin: 0;

            font-size: 16px;
            border-bottom: 1px solid ${getTheme('colors.silver.300')};
        }
        th {
            line-height: 180%;
            font-weight: 500;
            color: ${getTheme('colors.silver.500')};
        }
    }
`;

interface Props {
    columns: any;
    data: any;
}

export const Table: React.FC<Props> = ({ columns, data }) => {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        setHiddenColumns,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0, pageSize: 10 },
        },
        usePagination
    );
    useEffect(() => {
        const toShowCols = columns
            .filter(column => !column.show)
            .map(column => column.id);

        setHiddenColumns(toShowCols);
    }, [columns, setHiddenColumns]);

    const paginationProps = {
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        pageIndex,
        pageSize,
    };

    // Render the UI for your table
    return (
        <Styles>
            <table {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return (
                                        <td {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <Pagination {...paginationProps} />
        </Styles>
    );
};

const Pagination: ReactFC = ({
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage,
    pageIndex,
    pageOptions,
    pageCount,
    gotoPage,
}) => {
    return (
        <Flexbox justifyContent="center" alignItems="center" mt={6}>
            <Button
                variant="icon"
                disabled={!canPreviousPage}
                onClick={() => previousPage()}
            >
                <Icon iconName="chevronLeft" />
            </Button>
            {pageIndex !== 0 && <Span>{pageIndex + 1}</Span>}
            <Span>{pageIndex + 1}</Span>
            <Span mx={3}>of</Span>
            <Span onClick={() => gotoPage(pageCount - 1)}>
                {pageOptions.length}
            </Span>
            <Button
                variant="icon"
                disabled={!canNextPage}
                onClick={() => nextPage()}
            >
                <Icon iconName="chevronRight" />
            </Button>
        </Flexbox>
    );
};
