import axios from 'axios';

import {
    API_BASE_URL,
    API_ROUTE_INVITE,
    API_ROUTE_ACCEPT,
} from '../constants/api';

export const inviteMember = (input: {
    emails: string[];
    organisationId: string;
}): Promise<any> => {
    return axios
        .post(`${API_BASE_URL}/${API_ROUTE_INVITE}/`, input)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const acceptInvite = (inviteId: string): Promise<IOrganisation> => {
    return axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_INVITE}/${API_ROUTE_ACCEPT}/${inviteId}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};
