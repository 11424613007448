import { ValidationError } from 'yup';
import { Text } from '../text';

interface Props {
    errors?: Array<ValidationError>;
    name: string;
}

export const ErrorMessage: React.FC<Props> = ({ errors, name }) => {
    const err = errors && errors?.find(({ path }) => path === name);

    if (!err) return null;
    return (
        <Text variant="lg" color="red.500">
            {err.message}
        </Text>
    );
};
