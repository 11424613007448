export const ERROR_ROUTE = 'error';
export const ORGANISATION_ROUTE = 'organisation';
export const DASHBOARD_ROUTE = 'dashboard';
export const PROFILE_ROUTE = 'profile';
export const PLAYBOOK_ROUTE = 'playbook';
export const PLAY_ROUTE = 'play';

export const PUBLISHED_ROUTE = 'published';
export const DRAFT_ROUTE = 'draft';

export const NEW_ROUTE = 'new';
export const EDIT_ROUTE = 'edit';
export const PREVIEW_ROUTE = 'edit/preview';
export const LOGOUT_ROUTE = 'logout';
export const REVISION_ROUTE = 'revision';
export const ONBOARDING_ROUTE = 'onboarding';
