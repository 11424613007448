import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useWindowSize } from '../hooks';

const PageContext = React.createContext<
    [boolean, Dispatch<SetStateAction<boolean>>]
>(null!);

function PageProvider({ children }) {
    const [isUserSidebarOpen, setIsUserSidebarOpen] = useState(false);
    const [isResponsiveSidebarOpen, setIsResponsiveSidebarOpen] = useState(
        false
    );
    const { width } = useWindowSize();

    const loc = useLocation();

    const pathname = loc.pathname.replace(/\//g, '');

    useEffect(() => {
        if (isUserSidebarOpen) {
            setIsUserSidebarOpen(false);
        }
    }, [pathname]);

    useEffect(() => {
        if (width && width >= 1024) {
            setIsResponsiveSidebarOpen(true);
            return;
        }

        setIsResponsiveSidebarOpen(isUserSidebarOpen);
    }, [isUserSidebarOpen, width]);

    return (
        <PageContext.Provider
            value={[isResponsiveSidebarOpen, setIsUserSidebarOpen]}
        >
            {children}
        </PageContext.Provider>
    );
}
export { PageProvider, PageContext };
