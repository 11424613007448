export enum StatusType {
    DRAFT = 'draft',
    PUBLISHED = 'published',
}

export enum ORGANISATION_TYPE {
    PERSONAL = 'personal',
    ORGANISATION = 'organisation',
}

export enum RoleType {
    ADMIN = 'Admin',
    MEMBER = 'Member',
}

export enum ContentType {
    PLAYBOOK = 'playbook',
    PLAY = 'play',
}
