import axios from 'axios';
import security from '../helpers/security';

axios.interceptors.request.use(async config => {
    try {
        const token = await security.getAccessTokenSilently()();
        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
            };
        }
    } catch (err) {
        if (err.message === 'Login required') {
            console.warn(err);
        } else throw err;
    }

    return config;
});

export * from './user';
export * from './play';
export * from './playbook';
export * from './organisation';
export * from './tag';
export * from './media';
export * from './invite';
