import { getUserColor } from '../../helpers';
import { Box } from '../box';
import { Heading } from '../heading';
import { Text } from '../text';

interface Props {
    author: IOrgUser;
    showName?: boolean;
    size?: string | string[];
}

export const Avatar: React.FC<Props> = ({
    author,
    size = '48px',
    showName = false,
}) => {
    let content;

    if (author?.profilePicture) {
        content = (
            <img
                style={{ width: '100%' }}
                src={author?.profilePicture}
                alt={author?.fullName ?? 'Profile photo'}
            />
        );
    } else if (author?.fullName) {
        content = (
            <Heading variant="md" color="white.100">
                {author?.fullName.substring(0, 2).toUpperCase()}
            </Heading>
        );
    } else if (author?.email) {
        content = (
            <Heading variant="md" color="white.100">
                {author.email.substring(0, 2).toUpperCase()}
            </Heading>
        );
    }

    return (
        <Box display="flex" alignItems="center">
            <Box
                flexShrink={0}
                height={size}
                width={size}
                borderRadius={5}
                bg={getUserColor(author?.email ?? 'standard')}
                display="flex"
                justifyContent="center"
                alignItems="center"
                overflow="hidden"
            >
                {content}
            </Box>
            {showName && author?.fullName && (
                <Box ml={6} width="100%" minWidth={0}>
                    <Box display="flex" width="100%">
                        <Text color="silver.800" singleLineTruncate>
                            {author?.fullName}{' '}
                        </Text>
                        {author?.orgName && (
                            <Text ml={3} color="silver.500">
                                for
                            </Text>
                        )}
                    </Box>
                    <Text color="silver.800" singleLineTruncate width="100%">
                        {author?.orgName}
                    </Text>
                </Box>
            )}
        </Box>
    );
};
