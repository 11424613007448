import styled from 'styled-components';
import { border, BorderProps, compose, space, SpaceProps } from 'styled-system';
import { getTheme } from '../../helpers';

interface Props extends SpaceProps, BorderProps {
    errors?: Array<any>;
    name: string;
    value: string;
    checked: boolean;
    ref?: any;
    theme?: any;
    disabled?: boolean;
}
export const RadioButton: React.FC<Props &
    React.InputHTMLAttributes<HTMLInputElement>> = ({
    errors,
    name,
    value,
    onChange,
    onBlur,
    checked,
    disabled,
    children,
}) => {
    return (
        <StyledLabel checked={checked} disabled={disabled}>
            <StyledInput
                disabled={disabled}
                name={name}
                type="checkbox"
                checked={checked}
                value={value}
                onChange={onChange}
            />
            <CheckMark checked={checked} />
            {children}
        </StyledLabel>
    );
};

const StyledLabel = styled.label<{ checked: boolean; disabled?: boolean }>`
    color: ${({ disabled }) =>
        disabled
            ? getTheme('colors.silver.600')
            : getTheme('checkbox.default.color')};

    position: relative;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const CheckMark = styled.span<{ checked: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;

    position: absolute;
    top: 0;
    left: 0;
    height: ${getTheme('checkbox.default.width')};
    width: ${getTheme('checkbox.default.height')};
    background-color: ${({ checked }) =>
        checked ? getTheme('colors.gold.600') : getTheme('colors.white.100')};

    &:after {
        content: '';
        position: absolute;
        /* display: ${({ checked }) => (checked ? 'block' : 'none')}; */
        left:50%;
        top:50%;
        transform:translate(-50%,-50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: ${getTheme('colors.white.100')};
    }
`;

const StyledInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    margin-right: 16px;

    ${compose(space, border)};
`;
