import React from 'react';
import styled from 'styled-components';
import { Icon, IconName } from '@founders-playbook/component-library';
import { getTheme } from '../../helpers/style.helper';
import { Box } from '../box';
import { Heading } from '../heading';

interface DotButtonProps {
    iconName: IconName;
    label: string;
    onClick: () => void;
}

const UnstyledButton = styled.button`
    background: none;
    border: none;
    outline: none;

    &:enabled {
        cursor: pointer;
    }
`;

const IconWrapper = styled.div`
    background-color: ${getTheme('btn.dot.default.bg')};
    padding: ${getTheme('space.6')};
    border-radius: ${getTheme('radii.5')};
`;

const DotButtonWrapper = styled(UnstyledButton)`
    &:hover:enabled ${IconWrapper} {
        background-color: ${getTheme('btn.dot.hover.bg')};
        box-shadow: 0 0 0 2px ${getTheme('btn.dot.hover.bg')},
            ${getTheme('shadows.buttonHover')};
    }

    &:focus ${IconWrapper} {
        box-shadow: ${getTheme('btn.dot.focus.boxShadow')};
    }

    &:active:enabled ${IconWrapper} {
        background-color: ${getTheme('btn.dot.active.bg')};
        box-shadow: none;
    }

    &:disabled {
        opacity: ${getTheme('btn.dot.disabled.opacity')};
    }
`;

const DotButton = ({
    iconName,
    label,
    onClick,
}: DotButtonProps): React.ReactElement => (
    <DotButtonWrapper onClick={onClick}>
        <Box alignItems="center" display="flex">
            <Box bg="gold.500" p={[4, 6]} borderRadius={5}>
                <Icon iconName={iconName} size={32} />
            </Box>
            <Heading variant="sm" pl={6}>
                {label}
            </Heading>
        </Box>
    </DotButtonWrapper>
);

export default DotButton;
