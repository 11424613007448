import React from 'react';
import { IndividialIconProps } from '../Icon';

export const CCPublic = ({ fillStyle }: IndividialIconProps) => {
    const Stroke = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="0 0 384 384.017"
            enable-background="new 0 0 384 384.017"
        >
            <path d="M192,0.017c-106.036,0-192,85.964-192,192c0,106.024,85.964,192,192,192c106.036,0,192-85.976,192-192  C384,85.98,298.036,0.017,192,0.017z M192,348.015c-86.023,0-156.004-69.987-156.004-155.998c0-18.007,3.103-35.299,8.746-51.394  l61.013,27.169c-1.643,8.165-2.534,16.682-2.534,25.479c0,76.65,57.212,98.247,93.208,98.247c21.227,0,39.598-6.852,53.979-16.904  c2.733-1.959,5.209-3.93,7.484-5.936l-27.99-34.009c-0.821,0.938-1.678,1.83-2.557,2.628  c-10.887,10.323-22.853,10.323-25.527,10.323c-29.286,0-41.545-30.899-41.709-55.651l168.157,74.867  c0.199,0.035,0.363,0.035,0.527,0.07C302.259,315.215,250.89,348.015,192,348.015z M342.332,231.878l-173.436-77.412  c6.258-11.332,16.218-19.849,31.122-19.849c8.64,0,15.491,2.862,20.887,6.476c2.24,1.619,4.188,3.237,5.83,4.868l31.263-32.178  c-20.998-18.054-44.871-21.28-60.484-21.28c-36.688,0-62.649,15.86-77.952,39.944L61.564,106.58  C89.478,64.114,137.51,36.007,192,36.007c86.022,0,156.01,69.976,156.01,156.01c0,13.925-1.865,27.403-5.303,40.261  C342.566,232.137,342.473,232.019,342.332,231.878z" />
        </svg>
    );
    const Fill = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="0 0 384 384.017"
            enable-background="new 0 0 384 384.017"
        >
            <path d="M192,0.017c-106.036,0-192,85.964-192,192c0,106.024,85.964,192,192,192c106.036,0,192-85.976,192-192  C384,85.98,298.036,0.017,192,0.017z M192,348.015c-86.023,0-156.004-69.987-156.004-155.998c0-18.007,3.103-35.299,8.746-51.394  l61.013,27.169c-1.643,8.165-2.534,16.682-2.534,25.479c0,76.65,57.212,98.247,93.208,98.247c21.227,0,39.598-6.852,53.979-16.904  c2.733-1.959,5.209-3.93,7.484-5.936l-27.99-34.009c-0.821,0.938-1.678,1.83-2.557,2.628  c-10.887,10.323-22.853,10.323-25.527,10.323c-29.286,0-41.545-30.899-41.709-55.651l168.157,74.867  c0.199,0.035,0.363,0.035,0.527,0.07C302.259,315.215,250.89,348.015,192,348.015z M342.332,231.878l-173.436-77.412  c6.258-11.332,16.218-19.849,31.122-19.849c8.64,0,15.491,2.862,20.887,6.476c2.24,1.619,4.188,3.237,5.83,4.868l31.263-32.178  c-20.998-18.054-44.871-21.28-60.484-21.28c-36.688,0-62.649,15.86-77.952,39.944L61.564,106.58  C89.478,64.114,137.51,36.007,192,36.007c86.022,0,156.01,69.976,156.01,156.01c0,13.925-1.865,27.403-5.303,40.261  C342.566,232.137,342.473,232.019,342.332,231.878z" />
        </svg>
    );

    return fillStyle === 'outline' ? Stroke : Fill;
};
