import React, { useState } from 'react';

// TODO: Find out how to type this 'any'
const ToastContext = React.createContext<any>(null);

type Toast = {
    type: 'success' | 'error';
    message: string;
};

function ToastProvider({ children }) {
    const ToastState = useState<Toast | null>();

    return (
        <ToastContext.Provider value={ToastState}>
            {children}
        </ToastContext.Provider>
    );
}
export { ToastProvider, ToastContext };
