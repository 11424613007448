import ReactSelect from 'react-select';
import theme from '@founders-playbook/ui-themes';
import { Box, Text } from './';

interface Option {
    value: string;
    label: string;
}

interface SelectProps {
    options: Option[];
    value?: Option | undefined;
    onChange?: (e: { label: string; value: string }) => void;
    label?: string;
    name: string;
    placeholder?: string;
}

const selectTheme = originalTheme => ({
    ...originalTheme,
    colors: {
        ...originalTheme.colors,
        primary25: theme.colors.gold['100'],
        primary50: theme.colors.gold['200'],
        primary75: theme.colors.gold['400'],
        primary: theme.colors.gold['600'],
    },
});

const fontStyles = {
    fontSize: theme.fontSizes[2],
    fontWeight: theme.fontWeights[0],
    lineHeight: theme.lineHeights[3],
    color: theme.colors.silver[900],

    '@media (min-width: 768px)': {
        fontSize: theme.fontSizes[3],
    },
};

const customStyles = {
    control: provided => ({
        ...provided,
        border: theme.input.default.border,
        borderRadius: theme.input.default.borderRadius,

        '&:active': {
            borderColor: theme.input.active.borderColor,
            boxShadow: theme.input.active.boxShadow,
        },
    }),
    menuList: provided => {
        return {
            ...provided,
            padding: '8px',
        };
    },
    option: provided => {
        return { ...provided, ...fontStyles, borderRadius: theme.radii[2] };
    },
    valueContainer: provided => ({
        ...provided,
        padding: '8px 8px 8px 12px',

        '@media (min-width: 768px)': {
            padding: '12px 8px 12px 16px',
        },
    }),
    menu: provided => ({
        ...provided,
        border: theme.input.default.border,
        boxShadow: '0 4px 11px hsl(0deg 0% 0% / 10%)',
    }),
    placeholder: provided => ({
        ...provided,
        ...fontStyles,
        color: theme.colors.silver['300'],
    }),
    input: provided => ({
        ...provided,
        ...fontStyles,
        lineHeight: 0,
    }),
    singleValue: provided => ({
        ...provided,
        ...fontStyles,
    }),
};

export const Select = ({
    options,
    onChange,
    value,
    label,
    name,
    placeholder = 'Select...',
}: SelectProps) => {
    return (
        <Box mt={[6, 7]}>
            {label && (
                <Text as="label" htmlFor={name} fontWeight={500}>
                    {label}
                </Text>
            )}
            <Box mt={5}>
                <ReactSelect
                    options={options}
                    onChange={onChange}
                    value={value}
                    styles={customStyles}
                    theme={selectTheme}
                    placeholder={placeholder}
                />
            </Box>
        </Box>
    );
};

export default Select;
