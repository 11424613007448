import { Box, Text } from '..';
import { ConditionalLinkWrapper } from '../conditional-link-wrapper';

type Props = {
    title: string;
    secondary?: string;
    color: string;
    link?: string;
};

export const MicroTile = ({ color, title, link, secondary }: Props) => {
    return (
        <ConditionalLinkWrapper link={link}>
            <Box display="flex" alignItems="center">
                <Box
                    height="85px"
                    width="70px"
                    px={6}
                    py={7}
                    bg={color}
                    boxShadow="highHover"
                    borderRadius={1}
                >
                    <Box height="100%" width="100%" bg={color} />
                </Box>
                <Box>
                    <Text variant="md" ml={6} fontWeight={1}>
                        {title}
                    </Text>

                    {secondary && (
                        <Text variant="md" ml={6} fontWeight={1}>
                            {secondary}
                        </Text>
                    )}
                </Box>
            </Box>
        </ConditionalLinkWrapper>
    );
};
