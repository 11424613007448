import React, { useRef, useState, Dispatch, SetStateAction } from 'react';
import _ from 'lodash';
import { Box, Button, EmailList, Text } from '.';
import { Input } from './input';
import { useTranslation } from 'react-i18next';

interface InviteMembersProps {
    list: string[];
    setList: Dispatch<SetStateAction<string[]>>;
}

export const InviteMembers = ({ list, setList }: InviteMembersProps) => {
    const { t } = useTranslation();
    const [emailInput, setEmailInput] = useState('');
    const [error, setError] = useState('');
    const inputRef = useRef<HTMLInputElement>(null);

    const isEmail = email => /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
    const isInList = email => list.includes(email);

    const addToList = () => {
        // Reset errors
        setError('');

        // Get array of all emails
        // Clean whitespace of input
        const emails = emailInput.split(',').map(email => email.trim());

        // If only 1 email is added, then set error messages
        if (emails.length === 1) {
            if (!isEmail(emails[0])) {
                return setError(
                    t('general.inviteMembers.inputs.email.errors.invalid')
                );
            }

            if (isInList(emails[0])) {
                return setError(
                    t('general.inviteMembers.inputs.email.errors.duplicate')
                );
            }
        }

        // 1. Make sure emails are unique
        // 2. Filter out all non valid emails
        // 3. Filter out duplicate emails from already existing emails in list
        const toAdd = _.uniq(emails)
            .filter(email => isEmail(email))
            .filter(email => !isInList(email));

        // Add emails to list
        setList([...list, ...toAdd]);
        // Reset input
        setEmailInput('');
        // Refocus on input
        inputRef.current?.focus();
    };

    const removeFromList = React.useCallback(
        email => {
            setList(list.filter(item => item !== email));
        },
        [list, setList]
    );

    const handleOnChange = e => {
        if (e.target.value) {
            setError('');
        }

        return setEmailInput(e.target.value);
    };

    const handleKeyDown = e => {
        if (e.key === 'Enter') {
            addToList();
        }
    };

    return (
        <>
            <Box
                display="flex"
                flexDirection={['column', 'row']}
                alignItems={['stretch', 'flex-end']}
            >
                <Box flex={1} position="relative">
                    <Input
                        label={t('general.inviteMembers.inputs.email.label')}
                        placeholder={t(
                            'general.inviteMembers.inputs.email.placeholder'
                        )}
                        name="emailInput"
                        ref={inputRef}
                        mt={5}
                        id="emailInput"
                        value={emailInput}
                        onChange={handleOnChange}
                        onKeyDown={handleKeyDown}
                    />
                    {error && (
                        <Box position="absolute" right={0} bottom="-32px">
                            <Text variant="lg" color="red.500">
                                {error}
                            </Text>
                        </Box>
                    )}
                </Box>
                <Box
                    height={['39px', '39px', '39px', '59px']}
                    display="flex"
                    alignItems="stretch"
                    flexDirection={['column', 'row']}
                >
                    <Button ml={[0, 6]} mt={[6, 0]} onClick={addToList}>
                        {t('general.inviteMembers.inputs.addAction')}
                    </Button>
                </Box>
            </Box>

            <Box mt={[7, 7, 7, 10]}>
                <EmailList emails={list} handleRemoveClick={removeFromList} />
            </Box>
        </>
    );
};
