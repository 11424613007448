import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import {
    PLAYBOOK_ROUTE,
    PLAY_ROUTE,
    LOGOUT_ROUTE,
    ONBOARDING_ROUTE,
} from '../constants';
import { Loader } from '../components/loader';

import ProtectedRoute from './components/protected-route';
import security from '../helpers/security';
import { Logout } from './auth';
import { useHandleRoot } from '../hooks';

export const Onboarding = lazy(() => import('../modules/onboarding'));
export const Invite = lazy(() => import('../modules/invite'));
export const Organisation = lazy(() => import('../modules/organisation'));
export const Playbook = lazy(() => import('../modules/playbook'));
export const Play = lazy(() => import('../modules/play'));
export const Error = lazy(() => import('../modules/error'));
export const Public = lazy(() => import('../modules/public'));

const AuthenticationWrapper = ({ children }) => {
    const { isLoading, getAccessTokenSilently } = useAuth0();
    // hack to expose the function outside the provider/context tree
    security.setAccessTokenSilently(getAccessTokenSilently);
    const loading = useHandleRoot();
    if (isLoading || loading) return <Loader />;

    return children;
};

export const AppRouter: React.FC = () => {
    const { isAuthenticated } = useAuth0();

    let redirectDefault = isAuthenticated ? '/dashboard' : '/public';
    redirectDefault += window.location.search;

    return (
        <AuthenticationWrapper>
            <Suspense fallback={<Loader />}>
                <Switch>
                    <Route
                        path={`/${LOGOUT_ROUTE}`}
                        render={() => <Logout />}
                    />
                    <Route path={`/public`} render={() => <Public />} />
                    <ProtectedRoute
                        path={`/${ONBOARDING_ROUTE}`}
                        component={() => <Onboarding />}
                    />

                    <Route path={`/${PLAYBOOK_ROUTE}`} component={Playbook} />
                    <Route path={`/${PLAY_ROUTE}`} component={Play} />

                    <ProtectedRoute path="/invite" component={Invite} />

                    <ProtectedRoute
                        path="/dashboard"
                        component={() => <Organisation />}
                    />

                    <Route
                        path="/404"
                        render={() => (
                            <Error
                                error={{
                                    status: 404,
                                }}
                            />
                        )}
                    />
                    <Redirect to={redirectDefault} />
                </Switch>
            </Suspense>
        </AuthenticationWrapper>
    );
};
