import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { applyDefaultTheme } from '../../helpers';
import { Adjustments } from './Icons/Adjustments';
import { ArrowDown } from './Icons/ArrowDown';
import { ArrowLeft } from './Icons/ArrowLeft';
import { ArrowRight } from './Icons/ArrowRight';
import { ArrowsExpand } from './Icons/ArrowsExpand';
import { ArrowUp } from './Icons/ArrowUp';
import { CheckCircle } from './Icons/CheckCircle';
import { ChevronDown } from './Icons/ChevronDown';
import { ChevronLeft } from './Icons/ChevronLeft';
import { ChevronRight } from './Icons/ChevronRight';
import { ChevronUp } from './Icons/ChevronUp';
import { Clock } from './Icons/Clock';
import { Cog } from './Icons/Cog';
import { CreativeCommons } from './Icons/CreativeCommons';
import { Document } from './Icons/Document';
import { DotsHorizontal } from './Icons/DotsHorizontal';
import { DotsVertical } from './Icons/DotsVertical';
import { Eye } from './Icons/Eye';
import { Exclamation } from './Icons/Exclamation';
import { EyeOff } from './Icons/EyeOff';
import { FolderOpen } from './Icons/FolderOpen';
import { Globe } from './Icons/Globe';
import { Hamburger } from './Icons/Hamburger';
import { Home } from './Icons/Home';
import { Link } from './Icons/Link';
import { LockClosed } from './Icons/LockClosed';
import { Login } from './Icons/Login';
import { Logout } from './Icons/Logout';
import { Menu } from './Icons/Menu';
import { Minus } from './Icons/Minus';
import { MinusCircle } from './Icons/MinusCircle';
import { PencilAlt } from './Icons/PencilAlt';
import { Photograph } from './Icons/Photograph';
import { Plus } from './Icons/Plus';
import { PlusCircle } from './Icons/PlusCircle';
import { Refresh } from './Icons/Refresh';
import { Reply } from './Icons/Reply';
import { Rewind } from './Icons/Rewind';
import { Search } from './Icons/Search';
import { Star } from './Icons/Star';
import { Support } from './Icons/Support';
import { ThumbUp } from './Icons/ThumbUp';
import { Trash } from './Icons/Trash';
import { ViewGridAdd } from './Icons/ViewGridAdd';
import { X } from './Icons/X';
import { XCircle } from './Icons/XCircle';
import { CCBy } from './Icons/CCBy';
import { CCnc } from './Icons/CCNc';
import { CCSa } from './Icons/CCSa';
import { CCWaiver } from './Icons/CCWaiver';
import { CCPublic } from './Icons/CCPublic';

export type IconName =
    | 'adjustments'
    | 'arrowDown'
    | 'arrowLeft'
    | 'arrowRight'
    | 'arrowsExpand'
    | 'arrowUp'
    | 'checkCircle'
    | 'chevronDown'
    | 'chevronLeft'
    | 'chevronRight'
    | 'chevronUp'
    | 'clock'
    | 'cog'
    | 'creativeCommons'
    | 'ccBy'
    | 'ccSa'
    | 'ccNc'
    | 'ccPublic'
    | 'ccWaiver'
    | 'document'
    | 'dotsHorizontal'
    | 'dotsVertical'
    | 'eye'
    | 'exclamation'
    | 'eyeOff'
    | 'folderOpen'
    | 'globe'
    | 'hamburger'
    | 'home'
    | 'link'
    | 'lockClosed'
    | 'login'
    | 'logout'
    | 'menu'
    | 'minus'
    | 'minusCircle'
    | 'pencilAlt'
    | 'photograph'
    | 'plus'
    | 'plusCircle'
    | 'refresh'
    | 'reply'
    | 'rewind'
    | 'search'
    | 'star'
    | 'support'
    | 'thumbUp'
    | 'trash'
    | 'viewGridAdd'
    | 'x'
    | 'xCircle';
type FillType = 'outline' | 'solid';

export interface IconProps {
    iconName: IconName;
    fillStyle?: FillType;
    color?: string;
    size?: number;
    spin?: boolean;
}

export interface IndividialIconProps {
    fillStyle: FillType;
}

interface IconWrapperProps {
    color: string;
    size: number;
    spin: boolean;
    fillStyle: FillType;
}

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const IconWrapper = styled.div.attrs(applyDefaultTheme)<IconWrapperProps>`
    height: ${({ size }) => `${size}px`};
    width: ${({ size }) => `${size}px`};
    ${({ spin }) =>
        spin &&
        css`
            animation: 2s ${rotate} linear infinite;
        `};

    svg {
        ${({ fillStyle, color }) => fillStyle === 'solid' && `fill: ${color};`}
        ${({ fillStyle, color }) =>
            fillStyle === 'outline' && `stroke: ${color};`}
    }
`;

export const Icon: React.FC<IconProps> = ({
    iconName,
    fillStyle = 'outline',
    color = 'black',
    size = 24,
    spin = false,
}) => {
    const icons = {
        adjustments: <Adjustments fillStyle={fillStyle} />,
        arrowDown: <ArrowDown fillStyle={fillStyle} />,
        arrowLeft: <ArrowLeft fillStyle={fillStyle} />,
        arrowRight: <ArrowRight fillStyle={fillStyle} />,
        arrowsExpand: <ArrowsExpand fillStyle={fillStyle} />,
        arrowUp: <ArrowUp fillStyle={fillStyle} />,
        checkCircle: <CheckCircle fillStyle={fillStyle} />,
        chevronDown: <ChevronDown fillStyle={fillStyle} />,
        chevronLeft: <ChevronLeft fillStyle={fillStyle} />,
        chevronRight: <ChevronRight fillStyle={fillStyle} />,
        chevronUp: <ChevronUp fillStyle={fillStyle} />,
        clock: <Clock fillStyle={fillStyle} />,
        cog: <Cog fillStyle={fillStyle} />,
        creativeCommons: <CreativeCommons fillStyle={fillStyle} />,
        ccBy: <CCBy fillStyle={fillStyle} />,
        ccNc: <CCnc fillStyle={fillStyle} />,
        ccSa: <CCSa fillStyle={fillStyle} />,
        ccPublic: <CCPublic fillStyle={fillStyle} />,
        ccWaiver: <CCWaiver fillStyle={fillStyle} />,
        document: <Document fillStyle={fillStyle} />,
        dotsHorizontal: <DotsHorizontal fillStyle={fillStyle} />,
        dotsVertical: <DotsVertical fillStyle={fillStyle} />,
        eye: <Eye fillStyle={fillStyle} />,
        exclamation: <Exclamation fillStyle={fillStyle} />,
        eyeOff: <EyeOff fillStyle={fillStyle} />,
        folderOpen: <FolderOpen fillStyle={fillStyle} />,
        globe: <Globe fillStyle={fillStyle} />,
        hamburger: <Hamburger fillStyle={fillStyle} />,
        home: <Home fillStyle={fillStyle} />,
        link: <Link fillStyle={fillStyle} />,
        lockClosed: <LockClosed fillStyle={fillStyle} />,
        login: <Login fillStyle={fillStyle} />,
        logout: <Logout fillStyle={fillStyle} />,
        menu: <Menu fillStyle={fillStyle} />,
        minus: <Minus fillStyle={fillStyle} />,
        minusCircle: <MinusCircle fillStyle={fillStyle} />,
        pencilAlt: <PencilAlt fillStyle={fillStyle} />,
        photograph: <Photograph fillStyle={fillStyle} />,
        plus: <Plus fillStyle={fillStyle} />,
        plusCircle: <PlusCircle fillStyle={fillStyle} />,
        refresh: <Refresh fillStyle={fillStyle} />,
        reply: <Reply fillStyle={fillStyle} />,
        rewind: <Rewind fillStyle={fillStyle} />,
        search: <Search fillStyle={fillStyle} />,
        star: <Star fillStyle={fillStyle} />,
        support: <Support fillStyle={fillStyle} />,
        thumbUp: <ThumbUp fillStyle={fillStyle} />,
        trash: <Trash fillStyle={fillStyle} />,
        viewGridAdd: <ViewGridAdd fillStyle={fillStyle} />,
        x: <X fillStyle={fillStyle} />,
        xCircle: <XCircle fillStyle={fillStyle} />,
    };

    return (
        <IconWrapper
            color={color}
            size={size}
            spin={spin}
            fillStyle={fillStyle}
        >
            {icons[iconName]}
        </IconWrapper>
    );
};
