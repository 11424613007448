import React from 'react';
type Props = {
    width?: string;
    height?: string;
};
export const Loader: React.FC<Props> = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }}
        >
            <p>Loading....</p>
        </div>
    );
};
