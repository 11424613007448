import React from 'react';
import { useTheme } from 'styled-components';
import { getTheme } from '../../helpers';
import {
    useIsAllowedNew,
    useGetOrganisation,
    useAppSelector,
} from '../../hooks';
import { Box, Text } from '../';
import { DASHBOARD_ROUTE } from '../../constants';
import { Button } from '../button';
import { useHistory } from 'react-router';
import { Icon } from '@founders-playbook/component-library';
import { useTranslation } from 'react-i18next';
import { getUserForActiveOrg } from '../../redux/reducers';
import { canAdmin } from '../../helpers/can-admin';

export const ContentBanner: React.FC = () => {
    const user = useAppSelector(state => getUserForActiveOrg(state));
    const isAllowedNewContent = useIsAllowedNew();
    const history = useHistory();
    const { t } = useTranslation();

    const linkUrl = `/${DASHBOARD_ROUTE}/?modal=plans`;

    const theme = useTheme();
    if (isAllowedNewContent) return null;

    return (
        <Box
            position={['relative', 'relative', 'relative', 'absolute']}
            right="0"
            top="0"
            zIndex={9}
            left="0"
            marginLeft={['0', '0', '0', '0', '296px', '296px']}
            backgroundColor={getTheme(`colors.gold.500`)(theme)}
            display="flex"
            py={5}
            px={5}
            justifyContent="center"
        >
            <Box display="flex">
                <Icon iconName="exclamation" />
                <Text variant="xs" ml={4} mr={7}>
                    {canAdmin(user)
                        ? t('general.maxDraftsBanner.notice.admin')
                        : t('general.maxDraftsBanner.notice.nonAdmin')}
                </Text>
                <Button
                    onClick={() => history.push(linkUrl)}
                    color={getTheme(`colors.silver.900`)(theme)}
                    variant="link"
                >
                    <Text variant="xs">
                        {t('general.maxDraftsBanner.action')}
                    </Text>
                </Button>
            </Box>
        </Box>
    );
};
