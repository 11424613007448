export interface GroupedRevisions {
    groupedDate: Date;
    revisions: IPlay[] | IPlaybook[];
    editors: string;
}

export const groupByDate = (
    a: GroupedRevisions[] | [],
    b
): GroupedRevisions[] => {
    const d = new Date(b.updatedAt);
    const groupedDate = new Date(d.toDateString());

    const editor = `${b.editor?.fullName}`;

    if (!a.length) {
        return [
            {
                groupedDate,
                revisions: [b],
                editors: editor,
            },
        ];
    }

    const index = a.findIndex(el => {
        const newD = new Date(el.groupedDate);
        const newGroupedD = new Date(newD.toDateString());

        return newGroupedD.getTime() === groupedDate.getTime();
    });

    if (index >= 0) {
        a[index].revisions.push(b);
        const isAuthor = a[index].editors.indexOf(editor);
        if (isAuthor === -1)
            a[index].editors = a[index].editors.concat(', ', editor);

        return a;
    } else {
        return [
            ...a,
            {
                groupedDate,
                revisions: [b],
                editors: editor,
            },
        ];
    }
};
