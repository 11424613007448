import { useTranslation } from 'react-i18next';
import { Box, ConditionalLinkWrapper, Heading, Text, Chip } from '../';

export interface TileProps {
    title?: string;
    dateString: string;
    color?: string | undefined;
    link?: string;
    chips: { text: string; color: string }[];
}

export const Tile = ({
    title,
    color,
    dateString,
    link,
    chips = [],
}: TileProps) => {
    const { t } = useTranslation();

    return (
        <ConditionalLinkWrapper link={link}>
            <Box display="flex" alignItems="center" width={1} noSelect>
                <Box
                    height={['88px', '88px', '128px', '128px']}
                    width={['88px', '88px', '128px', '128px']}
                    bg={color}
                    flexShrink={0}
                    borderRadius={[3, 3, 4, 4]}
                />
                <Box
                    display="flex"
                    flexDirection="column"
                    ml={[4, 6]}
                    alignItems="flex-start"
                >
                    {chips.map(({ text, color }, index) => (
                        <Chip key={index} text={text} color={color} />
                    ))}

                    {title ? (
                        <Heading variant="sm">{title}</Heading>
                    ) : (
                        <Heading variant="sm" color="silver.300">
                            {t('dashboard.tiles.titlePlaceholder', {
                                contentType: 'play',
                            })}
                        </Heading>
                    )}

                    <Text variant="lg" mt={4} color="silver.500">
                        {t('dashboard.tiles.lastUpdated', { time: dateString })}
                    </Text>
                </Box>
            </Box>
        </ConditionalLinkWrapper>
    );
};
