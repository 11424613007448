import React, { useState } from 'react';
import { Box } from './box';
import { Heading } from './heading';
import {
    DndContext,
    closestCenter,
    DragOverlay,
    MouseSensor,
    useSensor,
    TouchSensor,
    useSensors,
} from '@dnd-kit/core';
import {
    useSortable,
    SortableContext,
    arrayMove,
    defaultAnimateLayoutChanges,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import styled from 'styled-components';

interface DragNDropProps {
    items: any[];
    onDragEnd: (newList: any[]) => void;
}

export const DragNDrop = ({ items, onDragEnd }: DragNDropProps) => {
    const [activeId, setActiveId] = useState<string | null>(null);
    const mouseSensor = useSensor(MouseSensor);
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            delay: 250,
            tolerance: 5,
        },
    });

    const sensors = useSensors(mouseSensor, touchSensor);

    const handleDragStart = event => {
        const { active } = event;
        setActiveId(active.id);
    };

    const handleDragEnd = event => {
        const { active, over } = event;

        if (active.id !== over.id) {
            const oldIndex = items.findIndex(item => item.id === active.id);
            const newIndex = items.findIndex(item => item.id === over.id);

            const nextState = arrayMove(items, oldIndex, newIndex);

            onDragEnd(nextState);
        }

        setActiveId(null);
    };

    return (
        <DndContext
            collisionDetection={closestCenter}
            sensors={sensors}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onDragCancel={() => setActiveId(null)}
        >
            <SortableContext items={items.map(item => item.id)}>
                <GridContainer>
                    {items.map(item => {
                        return (
                            <SortableItem
                                id={item.id}
                                key={item.id}
                                animateLayoutChanges={
                                    defaultAnimateLayoutChanges
                                }
                            >
                                <div
                                    style={{
                                        userSelect: 'none',
                                        opacity:
                                            item.id === activeId
                                                ? 0.4
                                                : undefined,
                                    }}
                                >
                                    <Box
                                        borderRadius={3}
                                        mb={7}
                                        py={6}
                                        px={8}
                                        bg="white.100"
                                    >
                                        <Heading>{item.title}</Heading>
                                    </Box>
                                </div>
                            </SortableItem>
                        );
                    })}
                </GridContainer>
            </SortableContext>

            <DragOverlay
                dropAnimation={{
                    duration: 250,
                    easing: 'ease',
                    dragSourceOpacity: 0.4,
                }}
            >
                {activeId ? (
                    <Item id={activeId}>
                        <Box borderRadius={3} py={6} px={8}>
                            <Heading>
                                {items.find(item => item.id === activeId).title}
                            </Heading>
                        </Box>
                    </Item>
                ) : null}
            </DragOverlay>
        </DndContext>
    );
};

const SortableItem = props => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    } as React.CSSProperties;

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {props.children}
        </div>
    );
};

const Item = React.forwardRef<HTMLDivElement, any>(
    ({ children, ...props }, ref) => {
        return (
            <div
                {...props}
                ref={ref}
                style={{
                    background: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 0 0 2px #F5C73D',
                }}
            >
                {children}
            </div>
        );
    }
);

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
`;
