import { AxiosResponse } from 'axios';

export const normalisePlay = (
    doc: IPlay
): Omit<
    IPlay,
    '_key' | '_id' | '_rev' | 'errors' | 'createdAt' | 'tags' | 'slug'
> => {
    const { _key, _id, _rev, createdAt, slug, tags, errors, ...rest } = doc;

    return {
        ...rest,
    };
};

export const normalisePlaybook = (doc: Partial<IPlaybook>) => {
    const {
        _key,
        _id,
        _rev,
        slug,
        errors,
        createdAt,
        tags,
        sections,
        ...rest
    } = doc;

    const newSections =
        sections?.map(({ plays, ...sectionRest }) => {
            return {
                ...sectionRest,
                plays:
                    plays?.map(({ play, description }) => {
                        return { playId: play._id, description };
                    }) ?? [],
            };
        }) ?? [];

    return { sections: newSections, ...rest };
};

export const toData = (res: AxiosResponse) => res.data;
