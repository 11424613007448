import axios from 'axios';

import {
    API_BASE_URL,
    API_ROUTE_USERS,
    API_ROUTE_ME,
    API_ROUTE_ROLE,
    API_ROUTE_ROOT,
    API_ROUTE_ONBOARDING,
} from '../constants/api';
import { RoleType } from '../interfaces/enums';

export const getMe = (): Promise<{
    user: IUser;
    organisations: IOrganisation[];
}> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ME}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const updateUser = (
    user: Partial<IUser & { organisationId: string }>
): Promise<IUser> => {
    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ME}`, user)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const updateUserRoot = (user: {
    fullName: string;
    occupation?: string;
    profilePicture?: string;
    organisationId?: string;
}): Promise<IUser> => {
    return axios
        .put(
            `${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ME}/${API_ROUTE_ROOT}`,
            user
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const setOnboarding = (): Promise<IUser> => {
    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ONBOARDING}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const changeRole = ({
    userId,
    ...rest
}: {
    userId: string;
    organisationId: string;
    roleName: RoleType;
}): Promise<IUser> => {
    return axios
        .put(
            `${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ROLE}/${userId}`,
            rest
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getRoles = (): Promise<IRole[]> => {
    return axios
        .get(`${API_BASE_URL}/${API_ROUTE_USERS}/${API_ROUTE_ROLE}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};
