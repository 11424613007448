import { Icon } from '@founders-playbook/component-library';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Button, Box } from '../';
import { Text } from '../text';

interface Props {
    uploadFile: (file: File) => void;
    text?: string;
}

export const Dropzone: React.FC<Props> = ({
    uploadFile,
    text = 'Add media',
}) => {
    const { t } = useTranslation();
    const [files, setFiles] = useState<any>([]);
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        maxFiles: 1,
        onDrop: acceptedFiles => {
            setFiles(
                acceptedFiles.map(file =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                )
            );
            const file = acceptedFiles[0];

            uploadFile(file);
        },
    });

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            files.forEach(file => URL.revokeObjectURL(file.preview));
        },
        [files]
    );

    return (
        <Box
            {...getRootProps({ className: 'dropzone' })}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
        >
            <input {...getInputProps()} />
            <Button variant="secondary">
                <Icon iconName="photograph" />
                <Text variant="sm" ml={4}>
                    {t('editor.main.play.step.media.actionLabel')}
                </Text>
            </Button>
        </Box>
    );
};
