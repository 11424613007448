import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import {
    space,
    SpaceProps,
    color,
    ColorProps,
    typography,
    TypographyProps,
    layout,
    LayoutProps,
    variant,
    compose,
} from 'styled-system';
import theme from '@founders-playbook/ui-themes';

type HeadingSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const variants = variant({
    variants: {
        xxl: {
            fontFamily: theme.text.h2xl.fontFamily,
            fontSize: theme.text.h2xl.fontSize,
            fontWeight: theme.text.h2xl.fontWeight,
            lineHeight: theme.text.h2xl.lineHeight,
            color: theme.text.h2xl.color,
        },
        xl: {
            fontFamily: theme.text.hxl.fontFamily,
            fontSize: theme.text.hxl.fontSize,
            fontWeight: theme.text.hxl.fontWeight,
            lineHeight: theme.text.hxl.lineHeight,
            color: theme.text.hxl.color,
        },
        lg: {
            fontFamily: theme.text.hlg.fontFamily,
            fontSize: theme.text.hlg.fontSize,
            fontWeight: theme.text.hlg.fontWeight,
            lineHeight: theme.text.hlg.lineHeight,
            color: theme.text.hlg.color,
        },
        md: {
            fontFamily: theme.text.hmd.fontFamily,
            fontSize: theme.text.hmd.fontSize,
            fontWeight: theme.text.hmd.fontWeight,
            lineHeight: theme.text.hmd.lineHeight,
            color: theme.text.hmd.color,
        },
        sm: {
            fontFamily: theme.text.hsm.fontFamily,
            fontSize: theme.text.hsm.fontSize,
            fontWeight: theme.text.hsm.fontWeight,
            lineHeight: theme.text.hsm.lineHeight,
            color: theme.text.hsm.color,
        },
        xs: {
            fontFamily: theme.text.hxs.fontFamily,
            fontSize: theme.text.hxs.fontSize,
            fontWeight: theme.text.hxs.fontWeight,
            lineHeight: theme.text.hxs.lineHeight,
            color: theme.text.hxs.color,
        },
        xxs: {
            fontFamily: theme.text.h2xs.fontFamily,
            fontSize: theme.text.h2xs.fontSize,
            fontWeight: theme.text.h2xs.fontWeight,
            lineHeight: theme.text.h2xs.lineHeight,
            color: theme.text.h2xs.color,
        },
    },
});

export interface HeadingProps
    extends SpaceProps,
        ColorProps,
        TypographyProps,
        LayoutProps {
    variant?: HeadingSizes;
}

export const Heading = styled.h2<HeadingProps>`
    text-align: left;
    ${variants};
    ${compose(space, color, typography, layout)}
`;

Heading.defaultProps = {
    variant: 'lg',
};
