import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Heading, Text } from '.';
import { useLocalStorage } from '../hooks';

export const CookieConsent = () => {
    const { t } = useTranslation();

    const [hasSeenStatement, setHasSeenStatement] = useLocalStorage(
        'has-seen-cookie-statement',
        false
    );

    if (hasSeenStatement) {
        return null;
    }

    return (
        <Box
            zIndex={1001}
            position="fixed"
            bottom={0}
            left={0}
            right={0}
            bg="silver.900"
            overflow="hidden"
        >
            <Box
                mx="auto"
                width={['100%', '432px', '480px', '656px']}
                p={6}
                py={8}
                display="flex"
                flexDirection="column"
            >
                <Heading color="white.100">
                    {t('general.cookieConsent.title')}
                </Heading>
                <Text mt={6} color="white.100">
                    {t('general.cookieConsent.body')}
                </Text>
                <Text mt={6} pb={2} color="white.100" textAlign="center">
                    <a
                        href="cookie_statement_0921.pdf"
                        target="_blank"
                        style={{ textDecoration: 'underline' }}
                    >
                        {t('general.cookieConsent.actions.viewStatement')}
                    </a>
                </Text>
                <Button
                    mt={6}
                    variant="secondary"
                    onClick={() => {
                        setHasSeenStatement(true);
                    }}
                >
                    {t('general.cookieConsent.actions.confirm')}
                </Button>
            </Box>
        </Box>
    );
};
