import React from 'react';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';
import { getTheme } from '../../helpers/style.helper';
import { Box } from '../box';
import { Text } from '../text';

interface DropdownProps {
    children: React.ReactElement | React.ReactElement[];
    removeDefaultPadding?: boolean;
}

interface DropdownButtonProps {
    onClick?: () => void;
    children: React.ReactElement | React.ReactElement[] | string | null;
}

export const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>(
    ({ children, removeDefaultPadding = false }, ref) => (
        <Box
            ref={ref}
            p={removeDefaultPadding ? null : 4}
            borderRadius={3}
            backgroundColor="white.100"
            border="1px solid"
            borderColor="silver.100"
            boxShadow="highHover"
            display="flex"
            flexDirection="column"
        >
            <Box mt={-4} display="flex" flexDirection="column">
                {children}
            </Box>
        </Box>
    )
);

const StyledDropdownButton = styled.button<SpaceProps>`
    padding: ${getTheme('space.4')} ${getTheme('space.6')};
    border-radius: ${getTheme('radii.2')};
    border: none;
    display: block;
    background-color: ${getTheme('colors.white.100')};
    text-align: left;

    ${space}

    &:hover:enabled {
        background-color: ${getTheme('colors.silver.25')};
        cursor: pointer;
    }
`;

export const DropdownButton = ({ onClick, children }: DropdownButtonProps) => (
    <StyledDropdownButton mt={4} onClick={onClick}>
        <Text variant="sm">{children}</Text>
    </StyledDropdownButton>
);
