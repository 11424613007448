import Color from 'color';
import { getTheme } from './style.helper';
import { timeDiffCalc } from './calculate-time-difference';
import { DRAFT_ROUTE, EDIT_ROUTE, PUBLISHED_ROUTE } from '../constants';
import { getContentType } from './get-content-type';
import i18n from '../config/i18n';

type CommonProps = {
    color?: string | undefined;
    updatedAt: string;
    title: string;
    latestDraft?: string;
    publishedRevision?: string | null;
    revisionMeta?: any;
    hasDraftedChanges?: boolean;
    publishedScope?: string[];
};

const ROUTE_MAP = {
    published: PUBLISHED_ROUTE,
    draft: DRAFT_ROUTE,
    edit: EDIT_ROUTE,
};

const getTextColor = (
    theme,
    color,
    option1 = 'white.100',
    option2 = 'silver.900'
) => {
    return Color(getTheme(`colors.${color}`)(theme)).isDark()
        ? option1
        : option2;
};

const genChipLabels = ({
    hasDraftedChanges,
    publishedRevision,
    revisionMeta,
    publishedScope,
}) => {
    const chips: { text: string; color: string }[] = [];
    if (hasDraftedChanges) {
        chips.push({
            text: i18n.t('dashboard.tiles.chips.unpublishedChanges'),
            color: 'red',
        });
    }
    if (!publishedRevision && !revisionMeta?.parent) {
        chips.push({
            text: i18n.t('dashboard.tiles.chips.draft'),
            color: 'red',
        });
    }
    if (publishedScope && publishedScope.length) {
        chips.push({
            text: i18n.t('dashboard.tiles.chips.private'),
            color: 'silver',
        });
    }
    return chips;
};

export const toCommonProps = (
    {
        color,
        updatedAt,
        title,
        publishedRevision,
        revisionMeta,
        hasDraftedChanges,
        publishedScope,
    }: CommonProps,
    { theme }
) => {
    const textColor = getTextColor(theme, color);
    const dateString = timeDiffCalc(new Date(updatedAt), new Date());
    const chips = genChipLabels({
        hasDraftedChanges,
        publishedRevision,
        revisionMeta,
        publishedScope,
    });
    return { title, color, dateString, textColor, chips };
};

export const getLink = ({ _id, _key, slug }, linkType: string) => {
    const contentType = getContentType(_id);
    const routeType = ROUTE_MAP[linkType];
    return `/${contentType}/${routeType}/${_key}/${slug}`;
};

export const playbookToTileProps = playbook => {
    const commonProps = toCommonProps(playbook, { theme: {} });
    const innerTiles = playbook.playColors;
    return { ...commonProps, innerTiles };
};

export const playToTileProps = play => {
    return toCommonProps(play, { theme: {} });
};

export const getKey = (id: string | undefined) => (id ? id.split('/')[1] : '');
