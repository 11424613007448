import styled from 'styled-components';
import { compose, space, SpaceProps } from 'styled-system';
import { getTheme } from '../helpers';

export const Card = styled.div<SpaceProps>`
    border-radius: 30px;
    background-color: ${getTheme('colors.white.100')};
    padding: 24px;

    ${compose(space)}
`;
