import styled from 'styled-components';
import {
    compose,
    flexbox,
    FlexboxProps,
    layout,
    LayoutProps,
    space,
    SpaceProps,
} from 'styled-system';
import { childSpace, ChildSpaceProps } from '../../helpers/style.helper';

interface FlexboxElementProps
    extends FlexboxProps,
        LayoutProps,
        SpaceProps,
        ChildSpaceProps {}

export const Flexbox = styled.div<FlexboxElementProps>`
    display: flex;

    ${childSpace}

    ${compose(flexbox, layout, space)}
`;

export default Flexbox;
