import { css } from 'styled-components';

export const linkConfig = {
    theme: {
        input: 'input',
        inputInvalid: 'inputInvalid',
        link: 'link',
    },
    placeholder: 'http://...',
    linkTarget: '_blank',
};

export const toolbarConfig = {
    theme: {
        toolbarStyles: { toolbar: 'toolbar' },
        buttonStyles: {
            button: 'button',
            buttonWrapper: 'buttonWrapper',
            active: 'active',
        },
    },
};

export const toolbarStyles = css`
    .toolbar {
        margin-top: 40px;
        border: 1px solid #111;
        background: #333;
        border-radius: 4px;
        box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
        z-index: 2;
        box-sizing: border-box;
        padding: 4px;
    }

    .buttonWrapper {
        display: inline-block;
    }

    .button {
        background: #333;
        color: #ddd;
        font-size: 18px;
        border: 0;
        padding-top: 5px;
        vertical-align: bottom;
        height: 34px;
        width: 36px;
        border-radius: 4px;
    }

    .button svg {
        fill: #ddd;
    }

    .button:hover,
    .button:focus {
        background: #444;
        outline: 0; /* reset for :focus */
    }

    .active {
        color: #6a9cc9;
    }

    .active svg {
        fill: #6a9cc9;
    }

    .input {
        padding: 4px;
        background: transparent;
        border: none;
        outline: none;
        color: white;
        &::placeholder {
            color: #aaa;
        }
    }
    .inputInvalid {
    }

    .link {
        text-decoration: underline;
        color: #4055bf;
    }
`;
