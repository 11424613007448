import DiffMatchPatch from 'diff-match-patch';
import {
    getPlainTextFromRaw,
    isRawDraftContentState,
} from './draftjs-content-helpers';

export const createDiffedHtml = (a, b) => {
    let valueCurrent = a ?? '';
    let valueRevision = b ?? '';

    if (isRawDraftContentState(valueCurrent)) {
        valueCurrent = getPlainTextFromRaw(valueCurrent);
    }
    if (isRawDraftContentState(valueRevision)) {
        valueRevision = getPlainTextFromRaw(valueRevision);
    }

    const dmp = new DiffMatchPatch();

    const diff = dmp.diff_main(valueCurrent, valueRevision);
    dmp.diff_cleanupSemantic(diff);
    const html = dmp.diff_prettyHtml(diff);

    return { __html: html };
};
