import { convertFromRaw, EditorState, RawDraftContentState } from 'draft-js';

export const createContentState = (
    value: RawDraftContentState
): EditorState => {
    if (!value) return EditorState.createEmpty();

    const parsedState = convertFromRaw(value);
    const content = EditorState.createWithContent(parsedState);

    return content;
};

export const getPlainTextFromRaw = (value: RawDraftContentState): string => {
    const content = createContentState(value);

    if (!value || !content) return '';

    return content.getCurrentContent().getPlainText('\u0001');
};

export const isRawDraftContentState = (
    toCheck: any
): toCheck is RawDraftContentState => {
    if ((toCheck as RawDraftContentState).blocks) {
        return true;
    }
    return false;
};
