import { themeGet } from '@styled-system/theme-get';
import styled, { css } from 'styled-components';
import {
    space,
    SpaceProps,
    color,
    ColorProps,
    typography,
    TypographyProps,
    layout,
    LayoutProps,
    variant,
    compose,
} from 'styled-system';
import theme from '@founders-playbook/ui-themes';

export type TextSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

const variants = variant({
    variants: {
        xxl: {
            fontSize: theme.text.xxl.fontSize,
            fontWeight: theme.text.xxl.fontWeight,
            lineHeight: theme.text.xxl.lineHeight,
            color: theme.text.xxl.color,
        },
        xl: {
            fontSize: theme.text.xl.fontSize,
            fontWeight: theme.text.xl.fontWeight,
            lineHeight: theme.text.xl.lineHeight,
            color: theme.text.xl.color,
        },
        lg: {
            fontSize: theme.text.lg.fontSize,
            fontWeight: theme.text.lg.fontWeight,
            lineHeight: theme.text.lg.lineHeight,
            color: theme.text.lg.color,
        },
        md: {
            fontSize: theme.text.md.fontSize,
            fontWeight: theme.text.md.fontWeight,
            lineHeight: theme.text.md.lineHeight,
            color: theme.text.md.color,
        },
        sm: {
            fontSize: theme.text.sm.fontSize,
            fontWeight: theme.text.sm.fontWeight,
            lineHeight: theme.text.sm.lineHeight,
            color: theme.text.sm.color,
        },
        xs: {
            fontSize: theme.text.xs.fontSize,
            fontWeight: theme.text.xs.fontWeight,
            lineHeight: theme.text.xs.lineHeight,
            color: theme.text.xs.color,
        },
        xxs: {
            fontSize: theme.text.xxs.fontSize,
            fontWeight: theme.text.xxs.fontWeight,
            lineHeight: theme.text.xxs.lineHeight,
            color: theme.text.xxs.color,
        },
    },
});

export interface TextProps
    extends SpaceProps,
        ColorProps,
        TypographyProps,
        LayoutProps {
    variant?: TextSizes;
    singleLineTruncate?: boolean;
}

const truncateStyles = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const Text = styled.p<TextProps>`
    font-family: ${themeGet('fonts.primary')};
    text-align: left;
    ${variants};

    ${({ singleLineTruncate }) => singleLineTruncate && truncateStyles}

    ${compose(space, color, typography, layout)}
`;

Text.defaultProps = {
    variant: 'xl',
};
