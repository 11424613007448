import React from 'react';
import { Box } from './box';
import { Heading } from './heading';

interface IndexDotProps {
    index: number;
}

export const IndexDot = ({ index }: IndexDotProps) => {
    return (
        <Box
            height="56px"
            width="56px"
            borderRadius={5}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bg="gold.500"
        >
            <Heading variant="sm">{index}</Heading>
        </Box>
    );
};
