import { Icon } from '@founders-playbook/component-library';
import React from 'react';
import { useTheme } from 'styled-components';
import { getTheme } from '../../helpers';
import { Box } from '../box';
import { Button } from '../button';
import { Heading } from '../heading';
import { ModalWrapper } from './modal-wrapper';

export interface ModalFooterProps {
    onCancel?: () => void;
    cancelText?: string;
    onConfirm?: () => void;
    confirmText?: string;
    cancelDisabled?: boolean;
    confirmDisabled?: boolean;
}
interface Props {
    noBodyPadding?: boolean;
    onClose?: () => void;
    children?: React.ReactElement;
    hideCloseButton?: boolean;
    modalBackground?: string;
    modalContainerKey?: string;
    header?: string;
    size?: 'small' | 'large';
}

export const Modal = ({
    size = 'large',
    onClose,
    children,
    hideCloseButton = true,
    modalBackground = 'silver.25',
    modalContainerKey,
    header,
    noBodyPadding,
    modalFooterProps,
}: Props & { modalFooterProps?: ModalFooterProps }) => {
    const padding = !noBodyPadding && {
        px: [6, 8, 8, 9],
        py: [6, 6, 6, 7],
    };
    const theme = useTheme();

    const widthLarge = ['100vw', '480px', '512px', '720px', '944px', '1057px'];
    const widthSmall = ['100vw', '100vw', '480px'];
    return (
        <ModalWrapper
            onClose={onClose}
            modalContainerKey={modalContainerKey}
            hideCloseButton={hideCloseButton}
        >
            <Box
                bg={modalBackground}
                width={size === 'large' ? widthLarge : widthSmall}
                position="relative"
                flexDirection="column"
                display="flex"
                responsiveFullHeight
            >
                {header && (
                    <Box
                        p={[6, 6, 6, 7]}
                        bg="white.100"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box flex="1">
                            <Heading textAlign="center" variant="md">
                                {header}
                            </Heading>
                        </Box>
                        <Box alignSelf="flex-end">
                            <Button variant="icon" onClick={onClose}>
                                <Icon
                                    color={getTheme('colors.silver.500')(theme)}
                                    iconName="x"
                                ></Icon>
                            </Button>
                        </Box>
                    </Box>
                )}
                <Box overflow="auto" flex={1} {...padding}>
                    {children}
                </Box>
                <ModalFooter {...modalFooterProps} />
            </Box>
        </ModalWrapper>
    );
};

export const ModalFooter = ({
    onCancel,
    onConfirm,
    cancelText,
    confirmText,
    confirmDisabled,
    cancelDisabled,
}: ModalFooterProps) => {
    if (!cancelText && !confirmText) {
        return null;
    }

    const btnWidth = ['100%', '100%', '100%', 'auto'];
    return (
        <Box
            justifySelf="flex-end"
            p={[6, 6, 6, 7]}
            display="flex"
            justifyContent={['center', 'center', 'center', 'flex-end']}
            alignItems="center"
            borderTop="1px solid"
            borderColor="silver.50"
            bg="white.100"
        >
            {cancelText && (
                <Button
                    width={btnWidth}
                    disabled={cancelDisabled}
                    variant="secondary"
                    mr={6}
                    onClick={onCancel}
                >
                    {cancelText}
                </Button>
            )}
            {confirmText && (
                <Button
                    width={btnWidth}
                    variant="primary"
                    disabled={confirmDisabled}
                    onClick={onConfirm}
                >
                    {confirmText}
                </Button>
            )}
        </Box>
    );
};
