import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Modal, Select, Text } from '.';
import { useAppSelector } from '../hooks';
import { selectOrganisations, selectActiveOrg } from '../redux/reducers';

type Option = {
    value: string;
    label: string;
};

type Props = {
    onConfirmClick?: () => void;
    onClose: () => void;
    content: IPlay | IPlaybook;
    handleSubmit: (content: IPlay | IPlaybook, option: Option) => void;
    header: string;
};

const toOption = (content: IOrganisation | IPlaybook) => ({
    value: content._id,
    label: 'name' in content ? content.name : content.title,
});

export const CloneModal = ({
    onClose,
    content,
    handleSubmit,
    header,
}: Props) => {
    const { t } = useTranslation();
    const organisations = useAppSelector(selectOrganisations);
    const activeOrg = useAppSelector(selectActiveOrg);

    const [selectedOrg, setSelectedOrg] = useState(toOption(activeOrg));

    return (
        <Modal
            header={header}
            onClose={onClose}
            modalBackground="silver.25"
            modalFooterProps={{
                onConfirm: () => handleSubmit(content, selectedOrg),
                confirmDisabled: !selectedOrg,
                confirmText: t('view.modal.copyToDashboard.actions.confirm'),
            }}
        >
            <Box maxWidth={'672px'} mx="auto" paddingTop={[6, 8]}>
                <Text>{t('view.modal.copyToDashboard.body')}</Text>

                <Box mt={[8, 8, 8, 10]}>
                    <Text as="label" variant="sm" htmlFor="select-org">
                        {t('view.modal.copyToDashboard.label')}
                    </Text>
                    <Select
                        name="select-org"
                        value={selectedOrg}
                        options={organisations.map(toOption)}
                        onChange={option => setSelectedOrg(option)}
                    />
                </Box>
            </Box>
        </Modal>
    );
};
