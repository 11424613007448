import { uniqueId } from 'lodash';
import { useState } from 'react';
import { Box } from '../box';
import { DragNDrop } from '../drag-n-drop';
import { Modal } from './modal';

export const DnDModal: React.FC<{
    list: any;
    onClose: (val: boolean) => void;
    dispatch: any;
}> = ({ list, onClose, dispatch }) => {
    const [newList, setNewList] = useState(
        list.map(l => ({ ...l, id: uniqueId() }))
    );

    return (
        <Modal
            modalBackground="silver.50"
            header={'Drag and drop to rearrange'}
            onClose={() => onClose(false)}
            modalFooterProps={{
                cancelText: 'Cancel',
                onCancel: () => onClose(false),
                confirmText: 'Change order',
                onConfirm: () => {
                    dispatch({
                        type: 'REORDER_LIST',
                        payload: {
                            list: newList,
                        },
                    });
                    onClose(false);
                },
            }}
        >
            <Box my={7}>
                <DragNDrop items={newList} onDragEnd={setNewList} />
            </Box>
        </Modal>
    );
};
