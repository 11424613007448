import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePopper } from 'react-popper';
import { Tag, Text, Heading } from '..';
import { useOnClickOutside } from '../../hooks';
import { IAction } from '../../redux/types';
import { Box } from '../box';
import { ErrorMessage } from '../error-message';

export interface TagData {
    name: string;
    options: string[];
}

interface ContextTagsProps {
    validateSingleField: (name: string, value, status: string) => void;
    errors?: Array<any>;
    data: ITag[];
    tagIds: string[];
    dispatch: (action: IAction) => void;
}

export const ContextTags = ({
    errors,
    data,
    tagIds,
    dispatch,
    validateSingleField,
}: ContextTagsProps): React.ReactElement => {
    const { t } = useTranslation();

    const onTagModalClose = () => {
        validateSingleField('tagIds', tagIds, 'publish');
    };

    return (
        <div style={{ position: 'relative' }}>
            <Heading as="h5" variant="xs" mt={9} mb={7}>
                {t('editor.tags.title')}
            </Heading>

            {data.map(tag => (
                <SingleTag
                    key={tag.name}
                    tag={tag}
                    tagIds={tagIds}
                    dispatch={dispatch}
                    onTagModalClose={onTagModalClose}
                />
            ))}

            <Box mt={6}>
                <ErrorMessage errors={errors} name="tagIds" />
            </Box>
        </div>
    );
};

const SingleTag = ({ tag, tagIds, dispatch, onTagModalClose }) => {
    const [showModal, setShowModal] = useState(false);
    const tagModalRef = useRef(null);
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
        null
    );
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'top',
        modifiers: [
            {
                name: 'offset',
                options: {
                    offset: [0, 8],
                },
            },
        ],
    });

    const handleOpen = useCallback(() => setShowModal(true), []);
    const handleClose = useCallback(() => setShowModal(false), []);

    useOnClickOutside(tagModalRef, () => {
        onTagModalClose();
        handleClose();
    });

    const isActive =
        tagIds.includes(tag._key) ||
        !!tag?.tags?.find(({ _key }) => tagIds.includes(_key));

    return (
        <>
            <Box display="inline-block" ref={setReferenceElement as any}>
                <Tag
                    m={2}
                    key={tag.name}
                    onClick={handleOpen}
                    variant={isActive ? 'secondary' : 'primary'}
                    display="inline-flex"
                    alignItems="center"
                >
                    {tag.name}
                </Tag>
            </Box>

            {showModal && (
                <div
                    ref={setPopperElement}
                    style={{ ...styles.popper, zIndex: 15 }}
                    {...attributes.popper}
                >
                    <Box
                        bg="white.100"
                        boxShadow="highHover"
                        padding={8}
                        width="300px"
                        borderRadius={3}
                        border="1px solid"
                        borderColor="silver.50"
                        ref={tagModalRef}
                    >
                        <Text fontWeight="bold">{tag.name}</Text>
                        <Box display="flex" flexWrap="wrap" mx={-2} mt={6}>
                            {tag?.tags &&
                                tag.tags.map(tag => (
                                    <Tag
                                        m={2}
                                        key={tag._key}
                                        variant={
                                            tagIds.includes(tag._key)
                                                ? 'secondary'
                                                : 'primary'
                                        }
                                        onClick={() => {
                                            dispatch({
                                                type: 'HANDLE_TAG_ID',
                                                payload: {
                                                    id: tag._key,
                                                },
                                            });
                                        }}
                                    >
                                        {tag.name}
                                    </Tag>
                                ))}
                        </Box>
                    </Box>
                </div>
            )}
        </>
    );
};
