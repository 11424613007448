import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState = { user: {} } as { user: IUser };

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, { payload: { user } }) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            state.user = user;
        },
    },
});

export const selectActiveRole = (state: RootState) => {
    const {
        activeOrg,
        userState: { user },
    } = state;
    return user.roles.find(role => role.organisationId === activeOrg._id);
};

export const selectRoleForContent = (state: RootState, orgId) => {
    const {
        userState: { user },
    } = state;
    return user?.roles?.find(role => role?.organisationId === orgId);
};

export const getUserForActiveOrg = (state: RootState): IOrgUser => {
    const { _id: orgId } = state.activeOrg;
    const { user } = state.userState;
    let settings: UserSettings | undefined = user?.settings?.find(
        ({ organisationId }) => orgId === organisationId
    );

    if (!settings) {
        settings = {
            organisationId: '',
            fullName: user.fullName ?? '',
            occupation: user.occupation,
            profilePicture: user.profilePicture,
        };
    }

    const role = user.roles.find(
        ({ organisationId }) => orgId === organisationId
    );

    return {
        ...settings,
        role,
        email: user.email,
        _id: user._id,
        _key: user._key,
    };
};

export const userReducer = userSlice.reducer;
export const { setUser } = userSlice.actions;
