import React, { Dispatch, SetStateAction, useState } from 'react';

const ModalContext = React.createContext<{
    settingsModal: [boolean, Dispatch<SetStateAction<boolean>>];
    organisationModal: [boolean, Dispatch<SetStateAction<boolean>>];
}>(null!);

function ModalProvider({ children }) {
    const settingsModal = useState(false);
    const organisationModal = useState(false);

    return (
        <ModalContext.Provider value={{ settingsModal, organisationModal }}>
            {children}
        </ModalContext.Provider>
    );
}
export { ModalProvider, ModalContext };
