import { Link } from 'react-router-dom';
import { ConditionalWrapper } from './conditional-wrapper';

export const ConditionalLinkWrapper = ({ link, children }) => {
    return (
        <ConditionalWrapper
            condition={link}
            wrapper={children => <Link to={link}>{children}</Link>}
        >
            {children}
        </ConditionalWrapper>
    );
};
