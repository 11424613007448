import styled, { useTheme } from 'styled-components';
import { Box } from '../box';
import { Text } from '../text';
import { REVISION_ROUTE } from '../../constants';
import { StyledLink } from '../link';
import React from 'react';
import { Tag } from '../tag';
import { useDropdown } from '../../hooks';
import { Button } from '../button';
import { Icon } from '@founders-playbook/component-library';
import { getTheme } from '../../helpers';
import { useTranslation } from 'react-i18next';

interface Props {
    publishedVersion: string | undefined | null;
    rev: IPlayRevision | IPlaybookRevision;
    isCurrentVersion: boolean;
    basePath: string;
    organisationKey: string;
    organisationSlug: string;
    revisionKey: string;
    baseId: string;
    slug: string;
    handleRestore: (key: string) => void;
}

export const RevisionLink: React.FC<Props> = ({
    isCurrentVersion,
    rev,
    basePath,
    organisationKey,
    organisationSlug,
    revisionKey,
    slug,
    baseId,
    publishedVersion,
    handleRestore,
}) => {
    const { t } = useTranslation();
    const date = new Date(rev.updatedAt);
    const revisionRoute = `/${basePath}/${REVISION_ROUTE}/${baseId}/${slug}/${rev._key}`;
    const {
        Dropdown,
        dropdownProps,
        positionalRef,
        toggleDropdown,
    } = useDropdown();
    const theme = useTheme();

    return (
        <Box
            key={rev._key}
            onClick={e => e.stopPropagation()}
            paddingLeft={2}
            position="relative"
            width={'280px'}
        >
            {!isCurrentVersion && (
                <>
                    <Box
                        position="absolute"
                        right={-30}
                        top={'20px'}
                        zIndex={8}
                    >
                        <Button
                            ref={positionalRef}
                            variant="icon"
                            onClick={e => {
                                e.preventDefault();
                                toggleDropdown();
                            }}
                        >
                            <Icon
                                iconName="dotsHorizontal"
                                color={getTheme(`colors.silver.500`)(theme)}
                            />
                        </Button>
                    </Box>

                    <Dropdown {...dropdownProps}>
                        <Button
                            variant="dropdown"
                            mt={4}
                            onClick={e => {
                                e.preventDefault();
                                handleRestore(rev._key);
                            }}
                        >
                            {t('revisionSidebar.restoreAction')}
                        </Button>
                    </Dropdown>
                </>
            )}
            <Hr />

            <StyledLink href={revisionRoute} state={{ toDashboard: true }}>
                <Box position="relative">
                    <Text variant="xs">{date.toLocaleString()}</Text>
                    {isCurrentVersion && (
                        <Text variant="xs" fontStyle={'italic'}>
                            {t('revisionSidebar.mostRecent')}
                        </Text>
                    )}

                    {rev._id === publishedVersion && (
                        <Box position="absolute" right="-110px" top="-4px">
                            <Tag variant="tertiary" fixed>
                                {t('revisionSidebar.publishedTag')}
                            </Tag>
                        </Box>
                    )}

                    {rev?.copy?.updatedAt && (
                        <Text variant="xs" fontStyle={'italic'}>
                            {t('revisionSidebar.copiedFrom', {
                                source: new Date(
                                    rev.copy.updatedAt
                                ).toLocaleString(),
                            })}
                        </Text>
                    )}

                    {rev._key === revisionKey && (
                        <Text variant="xs" fontStyle={'italic'}>
                            {t('revisionSidebar.selected')}
                        </Text>
                    )}
                    <Text variant="xs">{rev?.editor?.fullName}</Text>
                </Box>
            </StyledLink>
        </Box>
    );
};

export const Hr = styled.hr`
    color: #000000;
    opacity: 0.1;
    margin: 16px -100px;
`;
