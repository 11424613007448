import axios from 'axios';

import {
    API_BASE_URL,
    API_ROUTE_PLAYS,
    API_ROUTE_PUBLISH,
    API_ROUTE_UNPUBLISH,
    API_ROUTE_RESTORE,
} from '../constants/api';
import { StatusType } from '../interfaces/enums';

import { normalisePlay, toData } from './helpers';

export const getPlay = (
    playId: string,
    status: StatusType = StatusType.DRAFT
): Promise<IPlay> => {
    const route = status === StatusType.DRAFT ? '/' : '/public/';

    return axios
        .get(
            `${API_BASE_URL}${route}${API_ROUTE_PLAYS}/${playId}/?status=${status}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getPublicPlays = (): Promise<IPlay[]> => {
    return axios
        .get(`${API_BASE_URL}/public/${API_ROUTE_PLAYS}/`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getPlayRevisions = (playKey: string): Promise<PlayRevisions> =>
    axios
        .get(`${API_BASE_URL}/${API_ROUTE_PLAYS}/${playKey}/revisions`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });

export const createPlay = (play: IPlay): Promise<IPlay> => {
    const toCreate = normalisePlay(play);
    return axios
        .post(`${API_BASE_URL}/${API_ROUTE_PLAYS}`, toCreate)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const clonePlay = ({
    key,
    organisationId,
}: {
    key: string;
    organisationId: string;
}): Promise<IPlay> => {
    return axios
        .post(`${API_BASE_URL}/${API_ROUTE_PLAYS}/${key}/clone`, {
            organisationId,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const updatePlay = (play: IPlay): Promise<IPlay> => {
    const { _key } = play;
    const updateObj = normalisePlay(play);

    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_PLAYS}/${_key}`, updateObj)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const restoreRevision = ({
    playKey,
    revisionKey,
    organisationId,
}: {
    playKey: string;
    revisionKey: string;
    organisationId: string;
}): Promise<IPlay> => {
    return axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_PLAYS}/${playKey}/${API_ROUTE_RESTORE}/${revisionKey}`,
            {
                organisationId,
            }
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const publishPlay = ({
    play,
    scope = [],
}: {
    play: IPlay;
    scope: string[];
}): Promise<IPlay> => {
    const { _key } = play;
    const updateObj = normalisePlay(play);

    return axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_PLAYS}/${_key}/${API_ROUTE_PUBLISH}`,
            { ...updateObj, publishedScope: scope }
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const unpublishPlay = ({
    playKey,
    organisationId,
}: {
    playKey: string;
    organisationId: string;
}): Promise<IPlay> => {
    return axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_PLAYS}/${playKey}/${API_ROUTE_UNPUBLISH}`,
            { organisationId }
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const deletePlay = ({
    playKey,
    organisationId,
}: {
    playKey: string;
    organisationId: string;
}): Promise<IPlay> => {
    return axios
        .delete(`${API_BASE_URL}/${API_ROUTE_PLAYS}/${playKey}`, {
            data: { organisationId },
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const publishPlayToDefaults = async ({
    key,
    organisationId,
}: {
    key: string;
    organisationId: string;
}): Promise<IPlay> => {
    const resp = await axios.post(
        `/${API_ROUTE_PLAYS}/${key}/${API_ROUTE_PUBLISH}/defaults`,
        { organisationId }
    );
    return toData(resp);
};

export const unpublishPlayFromDefaults = async ({
    key,
    organisationId,
}: {
    key: string;
    organisationId: string;
}): Promise<IPlay> => {
    const resp = await axios.post(
        `/${API_ROUTE_PLAYS}/${key}/${API_ROUTE_UNPUBLISH}/defaults`,
        { organisationId }
    );
    return toData(resp);
};
