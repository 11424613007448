import React from 'react';
import styled from 'styled-components';
import { Loader } from './loader';

interface LoaderSwitchProps {
    isLoading: boolean;
    placeholderHeight?: string;
    loaderHeight?: string;
    loaderWidth?: string;
    block?: boolean;
}

const LoaderSwitchWrapper = styled(({ height, block, ...rest }) => (
    <span {...rest} />
))`
    height: ${({ height }) => height || 'auto'};
    display: ${({ block }) => (block ? 'block' : 'inline-block')};
`;

const LoaderSwitch: React.FC<LoaderSwitchProps> = ({
    isLoading,
    placeholderHeight,
    loaderHeight,
    loaderWidth,
    children,
    block = false,
}) => {
    return (
        <LoaderSwitchWrapper height={placeholderHeight} block={block}>
            {isLoading ? (
                <Loader height={loaderHeight} width={loaderWidth} />
            ) : (
                children
            )}
        </LoaderSwitchWrapper>
    );
};

export default LoaderSwitch;
