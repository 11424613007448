import React from 'react';
import styled from 'styled-components';
import { StyledLabel as label } from './Input.styles';

export interface CheckboxProps {
  id: string;
  children: React.ReactNode;
}

const StyledLabel = styled(label)`
  input {
    margin-right: 8px;
  }
`;

export const Checkbox = ({ children, id }: CheckboxProps) => (
  <StyledLabel htmlFor={id}>
    <input id={id} type="checkbox" />
    {children}
  </StyledLabel>
);
