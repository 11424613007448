import React, { useState } from 'react';
import { Icon } from '@founders-playbook/component-library';
import { Box, Button, Text } from '.';
import theme from '@founders-playbook/ui-themes';
import { getUserColor } from '../helpers';
import Truncate from 'react-truncate';

interface EmailListProps {
    emails: string[];
    handleRemoveClick: (email: string) => void;
}

const paginate = arr => {
    // Temporarily disabled pagination
    return [arr];

    // let mutable = [...arr];
    // let updatedArr = [mutable.splice(0, 7)];
    // while (mutable.length) updatedArr.push(mutable.splice(0, 6));
    // return updatedArr;
};

const Line = ({ emailAddress, color, handleRemoveClick }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            flexBasis={['100%', '100%', '100%', '100%', '50%']}
            justifyContent={['space-between']}
            width={['100%', '100%', '100%', '100%', '424px']}
            my={4}
            px={[0, 0, 0, 0, 4]}
        >
            <Box
                bg={color.bg}
                width="40px"
                height="40px"
                borderRadius={5}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexShrink={0}
            >
                <Text variant="xl" fontWeight={1} color={color.color}>
                    {emailAddress.charAt(0).toUpperCase()}
                </Text>
            </Box>
            <Box flex={1} px={6} minWidth={0}>
                <Text color="silver.600">
                    <Truncate>{emailAddress}</Truncate>
                </Text>
            </Box>
            <Button
                variant="icon"
                onClick={() => handleRemoveClick(emailAddress)}
            >
                <Icon iconName="x" color={theme.colors.silver['600']} />
            </Button>
        </Box>
    );
};

const LineButton = ({ handleClick, iconName, amount }) => {
    return (
        <Box
            display="flex"
            alignItems="center"
            flexBasis="50%"
            py={4}
            px={6}
            onClick={handleClick}
            cursor="pointer"
        >
            <Box
                width="40px"
                height="40px"
                borderRadius={5}
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Icon iconName="plus" />
            </Box>
            <Box ml={6} flex={1}>
                <Text color="silver.600">
                    another {amount} email addresses added
                </Text>
            </Box>
            <Button variant="icon" onClick={handleClick}>
                <Icon iconName={iconName} color={theme.colors.silver['600']} />
            </Button>
        </Box>
    );
};

export const EmailList = ({ emails, handleRemoveClick }: EmailListProps) => {
    const [pageNumber, setPageNumber] = useState(0);
    const pages = paginate(emails);

    return (
        <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="row"
            width={['100%', '100%', '400px', '400px', '100%']}
            alignItems="flex-start"
            alignContent="baseline"
        >
            {pageNumber > 0 && (
                <LineButton
                    handleClick={() => setPageNumber(pageNumber - 1)}
                    iconName="chevronUp"
                    amount={pages.slice(0, pageNumber).flat().length}
                />
            )}

            {pages[pageNumber].map(emailAddress => {
                const color = getUserColor(emailAddress);

                return (
                    <Line
                        key={emailAddress}
                        emailAddress={emailAddress}
                        color={color}
                        handleRemoveClick={handleRemoveClick}
                    />
                );
            })}

            {pageNumber < pages.length - 1 && (
                <LineButton
                    handleClick={() => setPageNumber(pageNumber + 1)}
                    iconName="chevronDown"
                    amount={pages.slice(pageNumber + 1).flat().length}
                />
            )}
        </Box>
    );
};
