import { useGetOrganisation } from './use-get-organisation';

export const useIsAllowedNew = () => {
    const organisation = useGetOrganisation();

    if (organisation?.subscriptionStatus?.status === 'active') {
        return true;
        // check for undefined value, because 0 drafts is falsy.
    } else if (organisation && organisation?.numberOfDrafts !== undefined) {
        return organisation?.numberOfDrafts < 5;
    }
    return true;
};
