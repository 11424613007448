import React from 'react';
import { Icon } from '@founders-playbook/component-library/src';
import { Box, Text } from '..';

interface ToastDisplayProps {
    handleClick?: () => void;
    type?: 'success' | 'error';
    message?: string;
}

export const ToastDisplay = ({
    handleClick,
    type,
    message,
}: ToastDisplayProps) => {
    return (
        <Box
            width={['90%', '90%', '90%', '90%', '956px']}
            zIndex={1000}
            position="fixed"
            bottom={64}
            right={'50%'}
            transform="translateX(50%)"
            bg="silver.900"
            p={6}
            overflow="hidden"
            borderRadius={8}
            cursor="pointer"
            onClick={handleClick}
            display="flex"
            alignItems="center"
            justifyContent="center"
        >
            {type ? (
                <Box
                    position="absolute"
                    left={['40px', '40px', '40px', '80px']}
                >
                    <Icon
                        color="white"
                        iconName={
                            type === 'success' ? 'checkCircle' : 'exclamation'
                        }
                    />
                </Box>
            ) : null}

            <Text color="white.100" maxWidth="70%">
                {message}
            </Text>
        </Box>
    );
};
