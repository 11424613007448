import React, { useRef, useLayoutEffect } from 'react';
import { Icon } from '@founders-playbook/component-library';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import { EDIT_ROUTE, PUBLISHED_ROUTE } from '../../constants';
import { getTheme, timeDiffCalc } from '../../helpers';
import { usePageSidebar } from '../../hooks/use-page-sidebar';
import { useWindowSize } from '../../hooks/use-window-size';
import { useDropdown } from '../../hooks/use-dropdown';
import { Avatar } from '../avatar';
import { Box } from '../box';
import { Button } from '../button';
import { Tag } from '../tag';
import { Heading } from '../heading';
import { Text } from '../text';
import { MicroTile, TileWithInnerTiles } from '../tile';
import { DraftEditor } from '../draft-editor';
import { getContentType } from '../../helpers';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { ConfirmationModal } from '../modal/confirmation-modal';
import { getLink, playbookToTileProps } from '../../helpers/content';
import { useAppSelector } from '../../hooks';
import { selectRoleForContent } from '../../redux/reducers';
import { useTranslation } from 'react-i18next';
import { mapLicense } from '../../helpers/license-mapper';

interface Props {
    clone?: IPlay | IPlaybook;
    content: IPlay | IPlaybook;
}

export const Header: React.FC<Props> = ({ content, clone }) => {
    const { t } = useTranslation();
    const {
        title,
        goal,
        slug,
        _key,
        tags,
        updatedAt,
        author,
        _id,
        source,
        license,
    } = content;
    const type = getContentType(_id);
    const history = useHistory();
    const [showLoginModal, setShowLoginModal] = useState(false);
    const headerWrapperRef = useRef<HTMLDivElement>(null);
    const [headerHeight, setHeaderHeight] = useState(0);
    const privateBannerRef = useRef<HTMLDivElement>(null);
    const [privateBannerHeight, setPrivateBannerHeight] = useState(0);
    const { width } = useWindowSize();
    const {
        Dropdown,
        dropdownProps,
        positionalRef,
        toggleDropdown,
    } = useDropdown();

    const { isAuthenticated, loginWithRedirect } = useAuth0();

    const theme = useTheme();

    const lastUpdated = timeDiffCalc(new Date(updatedAt), new Date());
    const { toggleSidebar } = usePageSidebar();

    const editRoute = `/${type}/${EDIT_ROUTE}/${_key}/${slug}`;
    const addRoute = `/${type}/${PUBLISHED_ROUTE}/${_key}/${slug}#add`;
    const cloneRoute = `/${type}/${PUBLISHED_ROUTE}/${_key}/${slug}#clone`;

    //if role is undefined, user is not logged in or has no rights
    const role = useAppSelector(state =>
        selectRoleForContent(state, content?.organisationId)
    );

    useLayoutEffect(() => {
        if (headerWrapperRef && headerWrapperRef.current) {
            const { height } = headerWrapperRef.current.getBoundingClientRect();
            setHeaderHeight(height);
        }
    }, [content, width, clone]);

    useLayoutEffect(() => {
        if (privateBannerRef && privateBannerRef.current) {
            const { height } = privateBannerRef.current.getBoundingClientRect();
            setPrivateBannerHeight(height);
        }
    }, [width]);

    const navigateOrLogin = (route: string) => () => {
        if (isAuthenticated) {
            history.push(route);
        } else {
            setShowLoginModal(true);
        }
    };

    const isPrivate = !!(
        content.publishedScope && content.publishedScope.length
    );

    const getHeaderBackgroundColor = () => {
        if (type === 'playbook') {
            if (isPrivate) {
                return 'red.200';
            } else {
                return 'blue.200';
            }
        } else {
            if (isPrivate) {
                return 'green.200';
            } else {
                return 'gold.500';
            }
        }
    };

    const licenceDetails = mapLicense(license);

    const LisenceLink: React.FC = () => {
        if (licenceDetails?.ccLink) {
            return (
                <a
                    href={licenceDetails?.ccLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        textDecoration: 'underline',
                    }}
                >
                    {licenceDetails?.value}
                </a>
            );
        } else {
            return <>{licenceDetails?.value}</>;
        }
    };

    return (
        <>
            {/* Background */}
            <Box
                position="absolute"
                top={privateBannerHeight}
                height={headerHeight - privateBannerHeight}
                left={0}
                right={0}
                bg={getHeaderBackgroundColor()}
            />

            {/* PrivateBannerBackground */}
            {isPrivate && (
                <Box
                    position="absolute"
                    top={0}
                    height={privateBannerHeight}
                    left={0}
                    right={0}
                    bg="silver.900"
                />
            )}

            {width && width < 1024 && (
                <Box
                    position="absolute"
                    top={isPrivate ? `${privateBannerHeight + 16}px` : '16px'}
                    left={6}
                    zIndex={2}
                >
                    <Button variant="icon" onClick={toggleSidebar}>
                        <Icon
                            iconName="menu"
                            color={getTheme('colors.silver.600')(theme)}
                        />
                    </Button>
                </Box>
            )}

            <Box ref={headerWrapperRef} pb={[9, 9, 9, 10]} position="relative">
                {isPrivate && (
                    <Box
                        ref={privateBannerRef}
                        width="100%"
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        py={4}
                        mx="auto"
                    >
                        <Icon
                            iconName="lockClosed"
                            color={getTheme('colors.white.100')(theme)}
                        />
                        <Text color="white.100" variant="xs" ml={4}>
                            {t('view.header.hasPrivateAccessLabel', {
                                contentType: type,
                            })}
                        </Text>
                    </Box>
                )}

                <Box zIndex={1} pt={10} position="relative">
                    <Box position="absolute" top={[6, 7, 8, 9]} right={0}>
                        <Button
                            variant="icon"
                            bg="white.100"
                            height={['48px', '48px', '48px', '56px']}
                            width={['48px', '48px', '48px', '56px']}
                            boxShadow="highHover"
                            ref={positionalRef}
                            onClick={toggleDropdown}
                        >
                            <Icon iconName="dotsVertical" />
                        </Button>

                        <Dropdown {...dropdownProps} removeDefaultPadding>
                            <Box
                                mt={4}
                                py={5}
                                px={4}
                                width="256px"
                                display="flex"
                                flexDirection="column"
                                alignItems="stretch"
                            >
                                {role && (
                                    <Button
                                        variant="dropdown"
                                        onClick={navigateOrLogin(editRoute)}
                                    >
                                        <Box flexShrink={0}>
                                            <Icon iconName="pencilAlt" />
                                        </Box>
                                        <Text ml={6} variant="sm">
                                            {t('view.header.actions.edit', {
                                                contentType: type,
                                            })}
                                        </Text>
                                    </Button>
                                )}
                                {type === 'play' && (
                                    <Button
                                        mt={4}
                                        variant="dropdown"
                                        onClick={navigateOrLogin(addRoute)}
                                    >
                                        <Box flexShrink={0}>
                                            <Icon iconName="link" />
                                        </Box>
                                        <Text ml={6} variant="sm">
                                            {t(
                                                'view.header.actions.addToPlaybook'
                                            )}
                                        </Text>
                                    </Button>
                                )}
                                <Button
                                    mt={4}
                                    variant="dropdown"
                                    onClick={navigateOrLogin(cloneRoute)}
                                >
                                    <Box flexShrink={0}>
                                        <Icon iconName="viewGridAdd" />
                                    </Box>
                                    <Text ml={6} variant="sm">
                                        {t('view.header.actions.clone')}
                                    </Text>
                                </Button>
                            </Box>
                        </Dropdown>
                    </Box>

                    <Box mt={4} display="flex" alignItems="center">
                        <Avatar
                            author={author}
                            size={['48px', '48px', '48px', '60px']}
                        />
                        <Box ml={6}>
                            <Heading variant="sm" mb={3}>
                                {t('view.header.title', {
                                    contentType: type,
                                    author: author.fullName,
                                })}
                            </Heading>
                            <Text variant="md" color="silver.700">
                                {t('view.header.subtitle.main', {
                                    lastUpdated,
                                })}{' '}
                                <LisenceLink />
                            </Text>
                        </Box>
                    </Box>

                    <Heading as="h1" variant="xxl" mt={[7, 7, 7, 9]}>
                        {title}
                    </Heading>

                    <Box mt={[6, 6, 6, 7]}>
                        <DraftEditor value={goal} readOnly />
                    </Box>

                    {tags && tags.length > 0 && (
                        <Box
                            mx={-2}
                            mt={[8, 8, 8, 9]}
                            display="flex"
                            flexWrap="wrap"
                        >
                            {tags &&
                                tags.map(({ name, _id }) => (
                                    <Tag
                                        variant="secondary"
                                        m={2}
                                        key={_id}
                                        fixed
                                    >
                                        {name}
                                    </Tag>
                                ))}
                        </Box>
                    )}

                    {clone && (
                        <Heading as="h5" variant="xs" mt={9} mb={4}>
                            {t('view.header.originalContentTitle', {
                                contentType: type,
                            })}
                        </Heading>
                    )}

                    {clone && type === 'playbook' && (
                        <TileWithInnerTiles
                            divider={2}
                            {...playbookToTileProps(clone)}
                            link={getLink(clone, 'published')}
                            dateString={''}
                            secondary={`By ${clone.author.fullName}`}
                            chips={[]}
                        />
                    )}

                    {clone && type === 'play' && (
                        <MicroTile
                            color={clone?.color ?? 'green.500'}
                            title={clone.title}
                            link={getLink(clone, 'published')}
                            secondary={`By ${clone.author.fullName}`}
                        />
                    )}

                    {source && (
                        <Text mt={10}>
                            {t('view.header.sourceLabel', {
                                contentType: type,
                            })}
                            <a
                                style={{ textDecoration: 'underline' }}
                                href={source}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {source}
                            </a>
                        </Text>
                    )}
                </Box>
            </Box>

            {showLoginModal && (
                <ConfirmationModal
                    onClose={() => setShowLoginModal(false)}
                    hideCloseButton={false}
                    heading={t('modals.confirmation.login.title')}
                    body={t('modals.confirmation.login.body')}
                >
                    <Box display="flex">
                        <Button variant="secondary" onClick={loginWithRedirect}>
                            {t('modals.confirmation.login.createAction')}
                        </Button>
                        <Button
                            ml={7}
                            variant="primary"
                            onClick={loginWithRedirect}
                        >
                            {t('modals.confirmation.login.signInAction')}
                        </Button>
                    </Box>
                </ConfirmationModal>
            )}
        </>
    );
};
