import styled, { css } from 'styled-components';

export const LinkWrapper = styled.div<{ underline?: boolean }>`
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;

    &:hover {
        background-color: #f8f8fa;
    }

    ${({ underline = false }) => {
        if (underline) {
            return css`
                padding: 0;
                text-underline-offset: 4px;
                text-decoration: underline;
                &:hover {
                    background-color: transparent;
                }
            `;
        }
    }}
`;
