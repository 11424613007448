import styled from 'styled-components';
import styledMap from 'styled-map';
import { Theme } from '../../theme/types';
import { applyDefaultTheme } from '../../helpers';
import { ButtonShapeType, ButtonTypeType, ButtonSizeType } from './Button';

const getVerticalPadding = (theme: Theme, size: ButtonSizeType) =>
  styledMap('size', {
    sm: theme.space[3],
    md: theme.space[4],
    lg: theme.space[5],
  });

const getHorizontalPadding = (theme: Theme, size: ButtonSizeType, shape: ButtonShapeType) => {
  if (shape === 'rectangle' || shape === 'round') {
    return styledMap('size', {
      sm: theme.space[6],
      md: theme.space[7],
      lg: theme.space[8],
    });
  }

  return getVerticalPadding(theme, size);
};

const getBorderRadius = (theme: Theme, shape: ButtonShapeType) => {
  if (shape === 'rectangle' || shape === 'square') {
    return theme.radii[3];
  }

  return '9999px';
};

interface StyledButtonProps {
  size: ButtonSizeType;
  buttonType: ButtonTypeType;
  shape: ButtonShapeType;
}

export const StyledButton = styled.button.attrs(applyDefaultTheme)<StyledButtonProps>`
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  border: none;

  box-shadow: ${({ theme }) =>
    styledMap('buttonType', {
      secondary: `inset 0 0 0 ${theme.borderWidths[2]} ${theme.colors.black.default}`,
      default: 'none',
    })};
  background-color: ${({ theme }) =>
    styledMap('buttonType', {
      primary: theme.colors.primary.default,
      link: 'transparent',
      subtle: 'transparent',
      default: theme.colors.white.default,
    })};
  color: ${({ theme }) =>
    styledMap('buttonType', {
      link: theme.colors.info.default,
      default: theme.colors.black.default,
    })};

  padding-top: ${({ theme, size }) => getVerticalPadding(theme, size)};
  padding-bottom: ${({ theme, size }) => getVerticalPadding(theme, size)};
  padding-left: ${({ theme, size, shape }) => getHorizontalPadding(theme, size, shape)};
  padding-right: ${({ theme, size, shape }) => getHorizontalPadding(theme, size, shape)};
  border-radius: ${({ theme, shape }) => getBorderRadius(theme, shape)};

  &:hover {
    box-shadow: ${({ theme }) =>
      styledMap('buttonType', {
        secondary: `inset 0 0 0 ${theme.borderWidths[2]} ${theme.colors.info.default}`,
        default: 'none',
      })};
    background-color: ${({ theme }) =>
      styledMap('buttonType', {
        primary: theme.colors.primary.dark,
        subtle: theme.colors.info.lightest,
        default: theme.colors.white.default,
      })};
    color: ${({ theme }) =>
      styledMap('buttonType', {
        link: theme.colors.info.dark,
        default: theme.colors.black.default,
      })};
    text-decoration: ${styledMap('buttonType', {
      link: 'underline',
      default: 'none',
    })};
  }

  &:active {
    background-color: ${({ theme }) =>
      styledMap('buttonType', {
        primary: theme.colors.primary.darkest,
        secondary: theme.colors.info.lightest,
        subtle: theme.colors.info.light,
        default: theme.colors.white.default,
      })};
    color: ${({ theme }) =>
      styledMap('buttonType', {
        link: theme.colors.info.darkest,
        default: theme.colors.black.default,
      })};
  }

  &:focus {
    outline: none;

    box-shadow: ${({ theme }) =>
      styledMap('buttonType', {
        primary: `0 0 0 ${theme.borderWidths[2]} ${theme.colors.primary.light}`,
        secondary: `inset 0 0 0 ${theme.borderWidths[2]} ${theme.colors.info.default}, 0 0 0 ${theme.borderWidths[2]} ${theme.colors.info.light}`,
        default: 'none',
      })};
    background-color: ${({ theme }) =>
      styledMap('buttonType', {
        primary: theme.colors.primary.default,
        subtle: theme.colors.info.lightest,
        default: theme.colors.white.default,
      })};
    color: ${({ theme }) =>
      styledMap('buttonType', {
        link: theme.colors.info.dark,
        default: theme.colors.black.default,
      })};
    text-decoration: ${styledMap('buttonType', {
      link: 'underline',
      default: 'none',
    })};
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.disabled.dark};

    svg {
      stroke: ${({ theme }) => theme.colors.disabled.dark};
    }

    box-shadow: ${({ theme }) =>
      styledMap('buttonType', {
        secondary: `inset 0 0 0 ${theme.borderWidths[2]} ${theme.colors.disabled.dark}`,
        default: 'none',
      })};
    background-color: ${({ theme }) =>
      styledMap('buttonType', {
        primary: theme.colors.disabled.default,
        link: 'transparent',
        subtle: 'transparent',
        default: theme.colors.white.default,
      })};
  }
`;
