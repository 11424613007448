import { useCallback, useEffect } from 'react';
import { useAppDispatch } from './redux-hooks';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { batch } from 'react-redux';
import { getMe } from '../api';

import { rootQueryKey, ONBOARDING_ROUTE } from '../constants';
import { setActiveOrg, setAllOrganisations, setUser } from '../redux/reducers';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetOrganisation } from './use-get-organisation';
export const useHandleRoot = (): boolean => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const isOnboarding = window.location.pathname.includes(ONBOARDING_ROUTE);

    const organisation = useGetOrganisation();
    const { isAuthenticated } = useAuth0();
    const { data, isLoading } = useQuery<
        { user: IUser; organisations: IOrganisation[] },
        Error
    >(rootQueryKey, getMe, { enabled: isAuthenticated });

    const goToOnboarding = useCallback(() => {
        const queryParams = window.location.search;
        if (!data?.user?.isOnboarded) {
            if (queryParams) {
                history.push(`/${ONBOARDING_ROUTE}/${queryParams}`);
            } else {
                history.push(`/${ONBOARDING_ROUTE}`);
            }
            return;
        }
        //@TODO need better fix for invite callback
        if (queryParams.includes('inviteId')) {
            history.push(`/invite/${queryParams}`);
        }
    }, [data, history, isOnboarding]);

    useEffect(() => {
        if (isLoading || !data) return;

        const updateActiveOrg = data.organisations.find(
            ({ _id }) => _id === organisation?._id
        );

        batch(() => {
            dispatch(setUser({ user: data.user }));
            dispatch(
                setAllOrganisations({ organisations: data.organisations })
            );

            dispatch(
                setActiveOrg({
                    organisation: updateActiveOrg
                        ? updateActiveOrg
                        : data.organisations[0],
                })
            );
        });

        goToOnboarding();
    }, [isLoading, data, dispatch, goToOnboarding]);

    return isLoading;
};
