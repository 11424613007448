import React from 'react';
import { Box, Heading, ModalWrapper, Text } from '..';
import { ModalFooter, ModalFooterProps } from './modal';

interface ConfirmationModalProps {
    heading: string;
    body?: string;
    onClose?: () => void;
    children?: React.ReactElement;
    hideCloseButton?: boolean;
    modalFooterProps?: ModalFooterProps;
}

export const ConfirmationModal = ({
    heading,
    body,
    onClose,
    children,
    hideCloseButton = true,
    modalFooterProps,
}: ConfirmationModalProps) => (
    <ModalWrapper
        onClose={onClose}
        modalContainerKey="confirmation-modal"
        hideCloseButton={hideCloseButton}
    >
        <Box
            bg="white.100"
            width={['100vw', '480px', '480px', '672px']}
            maxHeight="calc(100vh - 24px)"
            // responsiveFullHeight
            borderRadius={['8px 8px 0 0', '8px 8px 0 0', '8px 8px 0 0', '0']}
            overflow="hidden"
            position="relative"
            display="flex"
            flexDirection="column"
            px={[7, 7, 7, 11]}
            pt={[10]}
            pb={[7, 7, 7, 9]}
            justifyContent="center"
        >
            <Box position="relative" zIndex={2}>
                <Heading>{heading}</Heading>
            </Box>

            {!!body && <Text mt={9}>{body}</Text>}

            <Box alignSelf="center" mt={10}>
                {children}
            </Box>
            <ModalFooter {...modalFooterProps} />
        </Box>
    </ModalWrapper>
);
