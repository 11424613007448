import { Box, Text } from './';

const COLOR_MAP = {
    red: { text: 'red.500', bg: 'red.100' },
    silver: { text: 'silver.400', bg: 'silver.100' },
    blue: { text: 'white.100', bg: 'blue.50' },
};

export const Chip = ({
    text,
    color,
    mb = 4,
}: {
    text: string;
    color: string;
    mb?: number;
}) => {
    return (
        <Box bg={COLOR_MAP[color].bg} px={4} py={3} borderRadius={4} mb={mb}>
            <Text variant="xxs" color={COLOR_MAP[color].text}>
                {text}
            </Text>
        </Box>
    );
};
