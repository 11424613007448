import axios from 'axios';

import {
    API_BASE_URL,
    API_ROUTE_ORGANISATIONS,
    API_ROUTE_PLAYBOOKS,
    API_ROUTE_PUBLISH,
    API_ROUTE_UNPUBLISH,
} from '../constants/api';
import { StatusType } from '../interfaces/enums';
import { normalisePlaybook, toData } from './helpers';

axios.defaults.baseURL = API_BASE_URL;

export const getFrontPage = async () => {
    const resp = await axios.get(`/public/frontpage`);
    return toData(resp);
};

export const getUserPlaybooks = async (
    organisationKey: string
): Promise<IPlaybook[]> => {
    const resp = await axios.get(
        `/${API_ROUTE_ORGANISATIONS}/${organisationKey}/playbooks`
    );
    return toData(resp);
};

export const getPlaybook = async (
    playbookId: string,
    status: StatusType = StatusType.DRAFT
): Promise<IPlaybook> => {
    const route = status === StatusType.DRAFT ? '/' : '/public/';

    const resp = await axios.get(
        `${route}${API_ROUTE_PLAYBOOKS}/${playbookId}/?status=${status}`
    );
    return toData(resp);
};

export const createPlaybook = async (
    createPlayDTO: IPlaybook
): Promise<IPlaybook> => {
    const updateObj = normalisePlaybook(createPlayDTO);
    const resp = await axios.post(`/${API_ROUTE_PLAYBOOKS}`, updateObj);
    return toData(resp);
};

export const updatePlaybook = async (
    playbook: Partial<IPlaybook>
): Promise<IPlaybook> => {
    const { _key } = playbook;
    const updateObj = normalisePlaybook(playbook);
    const resp = await axios.put(`/${API_ROUTE_PLAYBOOKS}/${_key}`, updateObj);
    return toData(resp);
};

export const publishPlaybook = async ({
    playbook,
    scope = [],
}: {
    playbook: IPlaybook;
    scope: string[];
}): Promise<IPlaybook> => {
    const { _key } = playbook;
    const updateObj = normalisePlaybook(playbook);
    const resp = await axios.post(
        `/${API_ROUTE_PLAYBOOKS}/${_key}/${API_ROUTE_PUBLISH}`,
        { ...updateObj, publishedScope: scope }
    );
    return toData(resp);
};

export const publishPlaybookToDefaults = async ({
    key,
    organisationId,
}: {
    key: string;
    organisationId: string;
}): Promise<IPlaybook> => {
    const resp = await axios.post(
        `/${API_ROUTE_PLAYBOOKS}/${key}/${API_ROUTE_PUBLISH}/defaults`,
        { organisationId }
    );
    return toData(resp);
};

export const unpublishPlaybookFromDefaults = async ({
    key,
    organisationId,
}: {
    key: string;
    organisationId: string;
}): Promise<IPlaybook> => {
    const resp = await axios.post(
        `/${API_ROUTE_PLAYBOOKS}/${key}/${API_ROUTE_UNPUBLISH}/defaults`,
        { organisationId }
    );
    return toData(resp);
};

export const unpublishPlaybook = async ({
    key,
    organisationId,
}: {
    key: string;
    organisationId: string;
}): Promise<IPlaybook> => {
    const resp = await axios.post(
        `/${API_ROUTE_PLAYBOOKS}/${key}/${API_ROUTE_UNPUBLISH}`,
        {
            organisationId,
        }
    );
    return toData(resp);
};

export const deletePlaybook = async ({
    key,
    organisationId,
}: {
    key: string;
    organisationId: string;
}): Promise<IPlaybook> => {
    const resp = await axios.delete(`/${API_ROUTE_PLAYBOOKS}/${key}`, {
        data: { organisationId },
    });
    return toData(resp);
};

export const clonePlaybook = async ({
    key,
    organisationId,
}: {
    key: string;
    organisationId: string;
}): Promise<IPlaybook> => {
    const resp = await axios.post(
        `${API_BASE_URL}/${API_ROUTE_PLAYBOOKS}/${key}/clone`,
        {
            organisationId,
        }
    );
    return toData(resp);
};
