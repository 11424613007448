import styled from 'styled-components';
import { border, BorderProps, compose, space, SpaceProps } from 'styled-system';
import { Box, Flexbox, Text } from '..';
import { getTheme } from '../../helpers';

interface Props extends SpaceProps, BorderProps {
    errors?: Array<any>;
    name: string;
    checked: boolean;
    ref?: any;
    theme?: any;
    disabled?: boolean;
}
export const ToggleSwitch: React.FC<Props &
    React.InputHTMLAttributes<HTMLInputElement>> = ({
    errors,
    name,
    onChange,
    onBlur,
    checked,
    disabled,
    children,
}) => {
    return (
        <Flexbox alignItems="center">
            <Box>
                <StyledLabel checked={checked} disabled={disabled}>
                    <StyledInput
                        disabled={disabled}
                        name={name}
                        type="checkbox"
                        checked={checked}
                        onChange={onChange}
                    />
                    <CheckMark checked={checked} />
                </StyledLabel>
            </Box>

            {children && <Text ml={6}>{children}</Text>}
        </Flexbox>
    );
};

const StyledLabel = styled.label<{ checked: boolean; disabled?: boolean }>`
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
`;

const CheckMark = styled.span<{ checked: boolean }>`
    border-radius: 34px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: ${({ checked }) =>
        checked ? getTheme('colors.gold.400') : getTheme('colors.silver.100')};
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
        transform: ${({ checked }) => (checked ? 'translateX(26px)' : '0')};
        border-radius: 50%;
        position: absolute;
        content: '';
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: ${getTheme('colors.white.100')};
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }
`;

const StyledInput = styled.input`
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    margin-right: 16px;

    ${compose(space, border)};
`;
