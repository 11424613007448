import { useContext } from 'react';
import { ModalContext } from '../providers';

export function useModal(modalName: string) {
    const modals = useContext(ModalContext);

    const [isVisible, setIsVisible] = modals[modalName];

    const closeModal = () => {
        setIsVisible(false);
    };

    const openModal = () => {
        setIsVisible(true);
    };

    const toggleModal = () => {
        setIsVisible(!isVisible);
    };

    return {
        isVisible,
        toggleModal,
        closeModal,
        openModal,
    };
}
