import styled, { css } from 'styled-components';
import {
    border,
    BorderProps,
    compose,
    space,
    SpaceProps,
    typography,
    TypographyProps,
} from 'styled-system';
import { getStyle, getTheme } from '../../helpers';
import { Box } from '../box';
import { ErrorMessage } from '../error-message';
import { Text, TextSizes } from '../text';

interface SxWrapper extends SpaceProps, BorderProps {}

interface Props extends SpaceProps, BorderProps, TypographyProps {
    errors?: Array<any>;
    label?: string;
    name: string;
    variant?: VariantType;
    ref?: any;
    theme?: any;
    labelSettings?: {
        variant?: TextSizes;
        color?: string;
    };
    omitDefaultPadding?: boolean;
    sxWrapper?: SxWrapper;
    removeBorders?: boolean;
}
export const Input: React.FC<Props &
    React.InputHTMLAttributes<HTMLInputElement>> = ({
    errors,
    name,
    label,
    variant = 'lg',
    onChange,
    onBlur,
    ref,
    theme,
    labelSettings,
    omitDefaultPadding = false,
    sxWrapper,
    removeBorders = false,
    ...rest
}) => (
    <Box position="relative" {...sxWrapper}>
        {label && (
            <Text
                as="label"
                htmlFor={name}
                fontWeight={500}
                mb={[4, 5]}
                display="inline-block"
                {...labelSettings}
            >
                {label}
            </Text>
        )}
        <StyledInput
            {...rest}
            removeBorders={removeBorders}
            ref={ref}
            theme={theme}
            id={name}
            name={name}
            variant={variant}
            onChange={onChange}
            onBlur={onBlur}
            omitDefaultPadding={omitDefaultPadding}
        />

        <ErrorMessage errors={errors} name={name} />
    </Box>
);

type VariantType =
    | 'h2xl'
    | 'hxl'
    | 'hlg'
    | 'hmd'
    | 'hsm'
    | 'hxs'
    | 'h2xs'
    | 'xxl'
    | 'xl'
    | 'lg'
    | 'md'
    | 'sm'
    | 'xxs'
    | 'xs';

interface StyledInputProps extends SpaceProps {
    variant: VariantType;
    omitDefaultPadding: boolean;
    removeBorders: boolean;
}

const removeBordersCSS = css`
    &,
    &:focus:enabled,
    &:hover:enabled,
    &:active:enabled,
    &:disabled {
        border: none;
        border-color: transparent;
        outline: none;
        box-shadow: none;
    }
`;

const StyledInput = styled.input<StyledInputProps>`
    width: 100%;
    border: ${getTheme('input.default.border')};
    border-radius: ${getTheme('input.default.borderRadius')};
    ${({ omitDefaultPadding, removeBorders }) =>
        !omitDefaultPadding &&
        !removeBorders &&
        getStyle('input.default.padding')};
    ${({ variant }) => getStyle(`text.${variant}.fontSize`)};
    ${({ variant }) => getStyle(`text.${variant}.fontWeight`)};
    ${({ variant }) => getStyle(`text.${variant}.lineHeight`)};
    ${({ variant }) => getStyle(`text.${variant}.color`)};

    &:hover:enabled {
        cursor: ${getTheme('input.hover.cursor')};
    }

    &:focus:enabled {
        outline: none;
        border-color: ${getTheme('input.focus.borderColor')};
        box-shadow: ${getTheme('input.focus.boxShadow')};
    }

    &:active:enabled {
        outline: none;
        border-color: ${getTheme('input.active.borderColor')};
        box-shadow: ${getTheme('input.active.boxShadow')};
    }

    &:disabled {
        color: ${getTheme('input.disabled.color')};
    }

    &::placeholder {
        color: ${getTheme('input.placeholder.color')};
    }

    ${({ removeBorders }) => removeBorders && removeBordersCSS}

    ${compose(space, border, typography)};
`;
