import { useContext } from 'react';
import { PageContext } from '../providers';

export function usePageSidebar() {
    const [sidebarOpen, setSidebarOpen] = useContext(PageContext);

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const closeSidebar = () => setSidebarOpen(false);

    return { toggleSidebar, closeSidebar };
}
