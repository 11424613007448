import styled from 'styled-components';
import { position, PositionProps } from 'styled-system';
import { getTheme } from '../helpers';

export const Overlay = styled.div<PositionProps>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    background-color: ${getTheme('colors.black.25')};
    display: flex;
    align-items: center;
    justify-content: center;

    ${position}
`;
