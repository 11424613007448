import React from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';
import styled from 'styled-components';
import { Icon, IconName } from '@founders-playbook/component-library';
import { Box } from '../box';
import { Text } from '../text';
interface Props {
    hideTooltip?: boolean;
    tooltipText: string;
    iconName: IconName;
}
export const ToolTip: React.FC<Props> = ({
    hideTooltip = false,
    tooltipText,
    iconName,
    children,
}) => {
    const {
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip();

    if (hideTooltip) {
        return <>{children}</>;
    }
    const addRefToFirstChild = React.Children.map(children, (child, index) => {
        if (React.isValidElement(child)) {
            if (index === 0) {
                return React.cloneElement(child, {
                    ref: setTriggerRef,
                });
            } else {
                return React.cloneElement(child);
            }
        }
        return child;
    });

    return (
        <>
            {addRefToFirstChild}
            {visible && (
                <StyledTooltip
                    ref={setTooltipRef}
                    {...getTooltipProps({
                        className: 'tooltip-container',
                    })}
                >
                    <Box display="flex">
                        <Box alignSelf="flex-start" mr={6} mt={3}>
                            <Icon iconName={iconName} color="white" />
                        </Box>
                        <Box>
                            <Text color="white.100">{tooltipText}</Text>
                        </Box>
                    </Box>
                </StyledTooltip>
            )}
        </>
    );
};

const StyledTooltip = styled.div`
    border-radius: 2px;
    background: black;
    color: white;
    padding: 16px 20px;
`;
