import styled from 'styled-components';
import { applyDefaultTheme } from '../../helpers';

interface StyledInputProps {
  isValid: boolean;
  isError: boolean;
}

export const StyledInput = styled.input.attrs(applyDefaultTheme)<StyledInputProps>`
  min-width: 288px;
  padding: ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[6]};
  font-size: ${({ theme }) => theme.fontSizes[3]};
  border-radius: ${({ theme }) => theme.radii[4]};
  border-width: ${({ theme }) => theme.borderWidths[1]};
  border-style: solid;
  border-color: ${({ theme, isValid, isError }) => {
    if (isError) {
      return theme.colors.danger.default;
    }
    if (isValid) {
      return theme.colors.success.default;
    }
    return theme.colors.border.default;
  }};

  &:hover {
    border-color: ${({ theme, isValid, isError }) => {
      if (isError) {
        return theme.colors.danger.default;
      }
      if (isValid) {
        return theme.colors.success.default;
      }
      return theme.colors.info.default;
    }};
  }

  &:focus {
    outline: none;
    border-color: ${({ theme, isValid, isError }) => {
      if (isError) {
        return theme.colors.danger.default;
      }
      if (isValid) {
        return theme.colors.success.default;
      }
      return theme.colors.info.default;
    }};
    box-shadow: 0 0 0 3px
      ${({ theme, isValid, isError }) => {
        if (isError) {
          return theme.colors.danger.light;
        }
        if (isValid) {
          return theme.colors.success.light;
        }
        return theme.colors.info.light;
      }};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.disabled.default};
  }

  &:disabled:hover {
    border-color: ${({ theme }) => theme.colors.border.default};
  }
`;

interface StyledLabelProps {
  display?: 'inline' | 'inline-block' | 'block' | 'none';
}

export const StyledLabel = styled.label.attrs(applyDefaultTheme)<StyledLabelProps>`
  display: ${({ display }) => display || 'block'};
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  color: ${({ theme }) => theme.colors.text.secondary};
`;

export const StyledErrorWrapper = styled.div.attrs(applyDefaultTheme)`
  margin-top: ${({ theme }) => theme.space[3]};
`;

export const StyledInputWrapper = styled.div.attrs(applyDefaultTheme)`
  display: inline-block;
  margin-top: ${({ theme }) => theme.space[4]};
  position: relative;
`;

export const StyledIconWrapper = styled.div.attrs(applyDefaultTheme)`
  position: absolute;
  right: ${({ theme }) => theme.space[5]};
  top: 50%;
  transform: translateY(-50%);
`;
