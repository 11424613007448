import React, { useEffect, useRef } from 'react';
import theme from '@founders-playbook/ui-themes';
import styled from 'styled-components';
import { useOnClickOutside } from '../../hooks/use-on-click-outside';
import { createPortal } from 'react-dom';
import { Icon } from '@founders-playbook/component-library';
import noScroll from 'no-scroll';
import { addElementToDom } from '../../helpers';
import useKeyPress from '../../hooks/use-key-press';
import { Overlay } from '..';
import { Button } from '../button';

const ModalDialog = styled.div`
    border-radius: 4px;
    position: relative;
`;
const CloseModal = styled.div`
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 10;
`;

interface Props {
    onClose?: () => void;
    hideCloseButton?: boolean;
    modalContainerKey?: string;
    modalBackground?: string;
    display?: string;
}

export const ModalWrapper: React.FC<Props> = ({
    children,
    onClose = () => {},
    hideCloseButton = false,
    modalContainerKey = 'modal',
}) => {
    const node =
        document.getElementById(modalContainerKey) ??
        addElementToDom(modalContainerKey);
    const escapeKey = useKeyPress('Escape');

    const memuModalRef = useRef(null);
    useOnClickOutside(memuModalRef, () => {
        onClose();
    });

    useEffect(() => {
        if (escapeKey) {
            onClose();
        }
    }, [escapeKey, onClose]);

    useEffect(() => {
        noScroll.on();
        return () => {
            noScroll.off();
        };
    }, []);

    return createPortal(
        <Overlay>
            <ModalDialog ref={memuModalRef}>
                {!hideCloseButton && (
                    <CloseModal onClick={onClose}>
                        <Button variant="icon" onClick={onClose}>
                            <Icon iconName="x" />
                        </Button>
                    </CloseModal>
                )}

                {children}
            </ModalDialog>
        </Overlay>,
        node
    );
};
