import Truncate from 'react-truncate';
import { Box, Text, Hr, Avatar } from '../';
import { Tile, TileProps } from './tile';
import { TileWithInnerTiles, TileWithInnerTilesProps } from './tile-with-inner';

type Props = {
    tileProps: TileProps | TileWithInnerTilesProps;
    author: IOrgUser;
    secondaryText: string;
    type: 'play' | 'playbook';
};

export const TileCard = ({ tileProps, author, secondaryText, type }: Props) => (
    <Box
        px={[6, 7]}
        pt={[6, 7]}
        pb={6}
        bg="white.100"
        borderRadius={30}
        height="100%"
        display="flex"
        flexDirection="column"
    >
        {type === 'playbook' ? (
            <TileWithInnerTiles {...(tileProps as TileWithInnerTilesProps)} />
        ) : (
            <Tile {...(tileProps as TileProps)} />
        )}

        <Box flex="1">
            <Text my={[7, 7, 8]} color="silver.700">
                <Truncate lines={2}>{secondaryText}</Truncate>
            </Text>
        </Box>

        <Hr />

        <Box mt={6}>
            <Avatar author={author} showName />
        </Box>
    </Box>
);
