import styled from 'styled-components';
import { applyDefaultTheme } from '../../helpers';

interface StyledTextProps {
  size: 'lg' | 'md' | 'sm';
  color: string;
}

export const P = styled.p.attrs(applyDefaultTheme)<StyledTextProps>`
  margin: 0;
  font-family: ${({ theme }) => theme.fonts.normal};
  font-size: ${({ theme, size }) =>
    (size === 'sm' && theme.fontSizes[1]) ||
    (size === 'md' && theme.fontSizes[2]) ||
    (size === 'lg' && theme.fontSizes[3])};
  font-weight: ${({ theme }) => theme.fontWeights.normal};
  color: ${({ color }) => color};
`;
