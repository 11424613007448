import React from 'react';
import { animateScroll } from 'react-scroll';
import { Icon } from '@founders-playbook/component-library';
import { scroller } from 'react-scroll';
import { slugify } from '@founders-playbook/helpers/src';

import theme from '@founders-playbook/ui-themes';
import { Box } from '../box';
import { Button } from '../button';
import { Heading } from '../heading';
import { Text } from '../text';
import { usePageSidebar } from '../../hooks/use-page-sidebar';
import { Hr } from '../hr';
import { useTranslation } from 'react-i18next';

const getList = (content: IPlay | IPlaybook) => {
    if ('steps' in content) {
        return content.steps;
    } else if ('sections' in content) {
        return content.sections;
    }
};

interface Props<T> {
    content: T;
    handlePrevious: () => void;
}

export const ViewSidebar = <T extends IPlay | IPlaybook>({
    content,
    handlePrevious,
}: Props<T>) => {
    const { t } = useTranslation();
    const { title, _id } = content as T;

    const renderList = getList(content);

    const { toggleSidebar } = usePageSidebar();

    const onBackButtonClick = () => {
        toggleSidebar();
        handlePrevious();
    };

    return (
        <Box position="relative">
            <Box p={6}>
                <Button variant="dropdown" onClick={onBackButtonClick}>
                    <Icon iconName="home" />
                    <Text ml={6}>{t('view.sidebar.backAction')}</Text>
                </Button>
            </Box>

            <Box
                position="absolute"
                top={3}
                right={3}
                display={['block', 'block', 'block', 'block', 'none']}
            >
                <Button variant="icon" onClick={toggleSidebar}>
                    <Icon
                        iconName="xCircle"
                        color={theme.colors.silver['600']}
                    />
                </Button>
            </Box>

            <Hr />

            <Box
                mx={4}
                my={6}
                display="flex"
                flexDirection="column"
                alignItems="stretch"
            >
                <Button
                    variant="dropdown"
                    onClick={() =>
                        animateScroll.scrollToTop({
                            duration: 400,
                            containerId: 'mainId',
                        })
                    }
                >
                    <Heading variant="sm">{title}</Heading>
                </Button>
                {renderList && (
                    <>
                        {renderList.map(elem => {
                            return (
                                <Button
                                    variant="dropdown"
                                    mt={3}
                                    ml={5}
                                    pl={5}
                                    key={elem.title}
                                    onClick={() => {
                                        scroller.scrollTo(slugify(elem.title), {
                                            smooth: true,
                                            duration: 400,
                                            offset: -32,
                                            containerId: 'mainId',
                                        });
                                    }}
                                >
                                    <Text
                                        variant="xs"
                                        textAlign="left"
                                        width="100%"
                                        singleLineTruncate
                                    >
                                        {elem.title}
                                    </Text>
                                </Button>
                            );
                        })}
                    </>
                )}
            </Box>
        </Box>
    );
};
