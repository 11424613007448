import { Icon } from '@founders-playbook/component-library';
import styled from 'styled-components';
import { Box } from '../box';
import { Heading } from '../heading';
import { PLAY_ROUTE } from '../../constants';
import { useParams } from 'react-router';
import React from 'react';
import { Group } from './group';
import { groupByDate } from './helper';
import { useGetOrganisation } from '../../hooks/use-get-organisation';
import { useTranslation } from 'react-i18next';

interface Props {
    onClose: () => void;
    data: IPlay | IPlaybook;
    basePath: string;
    handleRestore: (key: string) => void;
}
export const RevisionSidebar: React.FC<Props> = ({
    onClose,
    data,
    basePath = `${PLAY_ROUTE}`,
    handleRestore,
}) => {
    const { t } = useTranslation();
    const {
        _key: organisationKey,
        slug: organisationSlug,
    } = useGetOrganisation();

    const { revisionKey } = useParams<{
        organisationSlug: string;
        organisationKey: string;
        key: string;
        revisionKey: string;
    }>();

    const { revisions, _key, slug, revisionMeta } = data;
    if (!revisions) return null;

    let baseId = _key;

    if (revisionMeta?.parent) {
        const parent = revisionMeta?.parent;

        baseId = parent.slice(parent.indexOf('/') + 1);
    }

    const groupTogether = revisions.reduce(groupByDate, []);

    return (
        <Container>
            <Box paddingX={7} paddingTop={7}>
                <Box position="absolute" right="10px" onClick={onClose}>
                    <Icon
                        iconName="xCircle"
                        // color={theme.colors.silver[disabled ? '300' : '900']}
                    />
                </Box>
                <Heading margin={0} variant="sm">
                    {t('revisionSidebar.title')}
                </Heading>
            </Box>

            {groupTogether.map((group, index) => {
                return (
                    <Group
                        publishedVersion={data.publishedRevision}
                        key={index}
                        group={group}
                        index={index}
                        slug={slug}
                        revisionKey={revisionKey}
                        organisationKey={organisationKey}
                        organisationSlug={organisationSlug}
                        baseId={baseId}
                        basePath={basePath}
                        handleRestore={handleRestore}
                    />
                );
            })}
        </Container>
    );
};

const Container = styled.div`
    overflow-x: hidden;
    position: fixed;
    right: 0;
    top: 89px;
    bottom: 0;
    z-index: 99;
    background-color: white;
    border-radius: 8px;
    width: 372px;
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    box-shadow: 0px 12px 12px rgba(0, 0, 0, 0.1);
    padding-bottom: 150px;
`;
