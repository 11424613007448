import styled from 'styled-components';
import { Box } from '../box';
import { Text } from '../text';
import React, { useState } from 'react';
import { RevisionLink, Hr } from './revision-link';

interface Props {
    publishedVersion: string | undefined | null;
    slug: string;
    group: any;
    index: number;
    basePath: string;
    organisationKey: string;
    organisationSlug: string;
    revisionKey: string;
    baseId: string;
    handleRestore: (key: string) => void;
}

export const Group: React.FC<Props> = ({
    group,
    index,
    revisionKey,
    ...rest
}) => {
    const isSelected = group.revisions.find(el => el._key === revisionKey);

    const [collapse, handleCollapse] = useState(!!isSelected);

    const date = new Date(group.groupedDate);

    const getWeekday = date.toLocaleTimeString([], {
        weekday: 'long',
    });

    const t = new Date().toDateString();
    const today = new Date(t);

    const isToday = today.getTime() === date.getTime();
    const weekday = isToday ? 'TODAY' : getWeekday.split(' ')[0].toUpperCase();

    return (
        <Box
            cursor="pointer"
            paddingLeft={6}
            onClick={() => {
                handleCollapse(!collapse);
            }}
        >
            <Hr />
            <Text variant="xs">{weekday}</Text>
            <Hr />

            <Box display="flex">
                <Box marginRight={8}>
                    <Span isSelected={collapse}>&#9654;</Span>
                </Box>
                <Box>
                    <Text variant="xs" fontWeight={700}>
                        {date.toLocaleString().split(' ')[0]}
                    </Text>
                    <Text variant="xs">{group.editors}</Text>
                    {collapse &&
                        group.revisions.map((rev, i) => {
                            return (
                                <RevisionLink
                                    key={rev._id}
                                    rev={rev}
                                    revisionKey={revisionKey}
                                    isCurrentVersion={index === 0 && i === 0}
                                    {...rest}
                                />
                            );
                        })}
                </Box>
            </Box>
        </Box>
    );
};

const Span = styled.span<{ isSelected: boolean }>`
    display: inline-block;
    position: relative;
    font-size: 10px;

    transition: 0.2s ease-in-out;
    ${({ isSelected }) => {
        if (isSelected) return `transform: rotate(90deg)`;
    }};
`;
