import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import {
    space,
    SpaceProps,
    color,
    ColorProps,
    typography,
    TypographyProps,
    layout,
    LayoutProps,
    compose,
} from 'styled-system';
type SpanSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export interface SpanProps
    extends SpaceProps,
        ColorProps,
        TypographyProps,
        LayoutProps {
    variant?: SpanSizes;
}

export const Span = styled.span<SpanProps>`
    font-family: ${themeGet('fonts.primary')};
    ${compose(space, color, typography, layout)}
`;
