import React from 'react';
import { IndividialIconProps } from '../Icon';

export const CCnc = ({ fillStyle }: IndividialIconProps) => {
    const Stroke = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            id="Layer_1"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="5.5 -3.5 64 64"
            enable-background="new 5.5 -3.5 64 64"
        >
            <g>
                <circle fill="transparent" cx="36.924" cy="28.403" r="28.895" />
                <path d="M60.205,5.779C54.012-0.407,46.428-3.5,37.459-3.5c-8.865,0-16.367,3.091-22.508,9.279C8.649,12.18,5.5,19.752,5.5,28.5   c0,8.745,3.149,16.266,9.451,22.558c6.301,6.296,13.802,9.442,22.508,9.442c8.809,0,16.446-3.175,22.907-9.521   C66.455,44.952,69.5,37.459,69.5,28.5C69.499,19.539,66.401,11.964,60.205,5.779z M56.199,46.82   c-5.286,5.226-11.508,7.837-18.66,7.837c-7.156,0-13.325-2.587-18.504-7.761c-5.179-5.174-7.77-11.306-7.77-18.397   c0-3,0.474-5.837,1.41-8.51l8.479,3.754h-0.611v3.803h3.001c0,0.538-0.054,1.073-0.054,1.608v0.912h-2.947v3.803h3.481   c0.483,2.84,1.555,5.144,3,6.965c3,3.965,7.822,6.106,13.071,6.106c3.43,0,6.533-1.017,8.357-2.036l-1.287-5.944   c-1.125,0.589-3.641,1.391-6.104,1.391c-2.68,0-5.196-0.802-6.911-2.731c-0.803-0.91-1.392-2.144-1.767-3.75h11.646l16.549,7.325   C59.433,43.225,57.978,45.102,56.199,46.82z M35.387,30.065l-0.07-0.054l0.12,0.054H35.387z M45.351,27.545h0.479v-3.803h-9.07   l-3.685-1.63c0.317-0.713,0.693-1.351,1.131-1.85c1.661-2.039,4.017-2.895,6.589-2.895c2.357,0,4.553,0.696,5.945,1.285l1.5-6.108   c-1.93-0.855-4.768-1.605-8.035-1.605c-5.035,0-9.321,2.035-12.375,5.463c-0.678,0.783-1.266,1.662-1.799,2.591l-10.523-4.657   c1.02-1.529,2.219-2.997,3.608-4.398c5.021-5.12,11.16-7.681,18.424-7.681c7.26,0,13.429,2.56,18.502,7.681   c5.124,5.066,7.687,11.252,7.687,18.562c0,2.407-0.272,4.678-0.812,6.82L45.351,27.545z" />
            </g>
        </svg>
    );
    const Fill = (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            id="Layer_1"
            x="0px"
            y="0px"
            width="24"
            height="24"
            viewBox="5.5 -3.5 64 64"
            enable-background="new 5.5 -3.5 64 64"
        >
            <g>
                <circle fill="transparent" cx="36.924" cy="28.403" r="28.895" />
                <path d="M60.205,5.779C54.012-0.407,46.428-3.5,37.459-3.5c-8.865,0-16.367,3.091-22.508,9.279C8.649,12.18,5.5,19.752,5.5,28.5   c0,8.745,3.149,16.266,9.451,22.558c6.301,6.296,13.802,9.442,22.508,9.442c8.809,0,16.446-3.175,22.907-9.521   C66.455,44.952,69.5,37.459,69.5,28.5C69.499,19.539,66.401,11.964,60.205,5.779z M56.199,46.82   c-5.286,5.226-11.508,7.837-18.66,7.837c-7.156,0-13.325-2.587-18.504-7.761c-5.179-5.174-7.77-11.306-7.77-18.397   c0-3,0.474-5.837,1.41-8.51l8.479,3.754h-0.611v3.803h3.001c0,0.538-0.054,1.073-0.054,1.608v0.912h-2.947v3.803h3.481   c0.483,2.84,1.555,5.144,3,6.965c3,3.965,7.822,6.106,13.071,6.106c3.43,0,6.533-1.017,8.357-2.036l-1.287-5.944   c-1.125,0.589-3.641,1.391-6.104,1.391c-2.68,0-5.196-0.802-6.911-2.731c-0.803-0.91-1.392-2.144-1.767-3.75h11.646l16.549,7.325   C59.433,43.225,57.978,45.102,56.199,46.82z M35.387,30.065l-0.07-0.054l0.12,0.054H35.387z M45.351,27.545h0.479v-3.803h-9.07   l-3.685-1.63c0.317-0.713,0.693-1.351,1.131-1.85c1.661-2.039,4.017-2.895,6.589-2.895c2.357,0,4.553,0.696,5.945,1.285l1.5-6.108   c-1.93-0.855-4.768-1.605-8.035-1.605c-5.035,0-9.321,2.035-12.375,5.463c-0.678,0.783-1.266,1.662-1.799,2.591l-10.523-4.657   c1.02-1.529,2.219-2.997,3.608-4.398c5.021-5.12,11.16-7.681,18.424-7.681c7.26,0,13.429,2.56,18.502,7.681   c5.124,5.066,7.687,11.252,7.687,18.562c0,2.407-0.272,4.678-0.812,6.82L45.351,27.545z" />
            </g>
        </svg>
    );

    return fillStyle === 'outline' ? Stroke : Fill;
};
