import styled, { css } from 'styled-components';
import theme from '@founders-playbook/ui-themes';
import {
    compose,
    layout,
    LayoutProps,
    space,
    SpaceProps,
    position,
    PositionProps,
    flexbox,
    FlexboxProps,
    grid,
    GridProps,
    border,
    BorderProps,
    color,
    ColorProps,
    shadow,
    ShadowProps,
    system,
} from 'styled-system';

interface BoxProps
    extends LayoutProps,
        SpaceProps,
        PositionProps,
        FlexboxProps,
        GridProps,
        BorderProps,
        ColorProps,
        ShadowProps {
    cursor?: 'auto' | 'default' | 'none' | 'pointer' | 'move';
    transform?: string;
    fullHeight?: boolean;
    responsiveFullHeight?: boolean;
    noSelect?: boolean;
}

const fullHeightCSS = css`
    height: 100vh;

    @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
    }
`;

const responsiveFullHeightCSS = css`
    height: 100vh;

    @supports (-webkit-touch-callout: none) {
        height: -webkit-fill-available;
    }

    @media (min-width: ${theme.breakpoints['3']}) {
        height: min(100vh, 756px);

        @supports (-webkit-touch-callout: none) {
            height: min(-webkit-fill-available, 756px);
        }
    }
`;

const noSelectCSS = css`
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const Box = styled.div<BoxProps>`
    ${compose(layout, space, position, flexbox, grid, border, color, shadow)};
    ${system({
        cursor: true,
        transform: {
            property: 'transform',
            transform: value => value,
        },
    })};

    ${({ noSelect = false }) => noSelect && noSelectCSS}
    ${({ fullHeight = false }) => fullHeight && fullHeightCSS};
    ${({ responsiveFullHeight = false }) =>
        responsiveFullHeight && responsiveFullHeightCSS};
`;

export default Box;
