import axios from 'axios';

import {
    API_BASE_URL,
    API_ROUTE_CONTENT,
    API_ROUTE_PLAYS,
    API_ROUTE_RECENT,
    API_ROUTE_ORGANISATIONS,
    API_ROUTE_ADD,
    API_ROUTE_MEMBERS,
    API_ROUTE_PAYMENT,
    API_ROUTE_SUBSCRIPTION,
    API_ROUTE_CANCEL,
} from '../constants/api';

export const getOrganisation = (id: string): Promise<IOrganisation> =>
    axios
        .get(`${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${id}`)
        .then(res => res.data)
        .catch(err => {
            throw err;
        });

export const updateSeats = (input: {
    organisationKey: string;
    seats: number;
}): Promise<{ subscriptionStatus: any }> => {
    const { organisationKey, ...rest } = input;
    return axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${organisationKey}/seats`,
            {
                ...rest,
            }
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getContentForOrganisation = (
    organisationId: string
): Promise<(IPlay | IPlaybook)[]> =>
    axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${organisationId}/${API_ROUTE_CONTENT}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });

export const getPlaysForOrganisation = (
    organisationId: string
): Promise<IPlay[]> =>
    axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${organisationId}/${API_ROUTE_PLAYS}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });

export const getMostRecentContent = (
    organisationId: string
): Promise<(IPlay | IPlaybook)[]> =>
    axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${organisationId}/${API_ROUTE_RECENT}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });

interface ICreateOrganisation {
    name: string;
}

export const createOrganisation = (
    organisation: ICreateOrganisation
): Promise<IOrganisation> => {
    return axios
        .post(`${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/`, {
            ...organisation,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const updateOrganisation = (
    organisation: Pick<IOrganisation, '_key'> & Partial<IOrganisation>
): Promise<IOrganisation> => {
    const { _key, ...rest } = organisation;

    return axios
        .put(`${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${_key}`, {
            ...rest,
        })
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getMembers = (key: string): Promise<IOrgUser[]> => {
    return axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${key}/${API_ROUTE_MEMBERS}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const addMember = (data: {
    email: string;
    organisationId: string;
}): Promise<IUser[]> => {
    return axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${API_ROUTE_MEMBERS}/${API_ROUTE_ADD}`,
            data
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const deleteMember = ({
    userKey,
    organisationId,
}: {
    userKey: string;
    organisationId: string;
}): Promise<IUser[]> => {
    return axios
        .delete(
            `${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${API_ROUTE_MEMBERS}/${userKey}/${organisationId}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const createSubscription = ({
    organisationKey,
}: {
    organisationKey: string;
}): Promise<{ paymentUrl: string }> =>
    axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${organisationKey}/${API_ROUTE_SUBSCRIPTION}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });

export const cancelSubscription = ({
    organisationKey,
}: {
    organisationKey: string;
}): Promise<any> => {
    return axios
        .post(
            `${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${organisationKey}/${API_ROUTE_SUBSCRIPTION}/${API_ROUTE_CANCEL}`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};

export const getPaymentList = ({
    organisationKey,
}: {
    organisationKey: string;
}): Promise<any> => {
    return axios
        .get(
            `${API_BASE_URL}/${API_ROUTE_ORGANISATIONS}/${organisationKey}/${API_ROUTE_SUBSCRIPTION}/list/`
        )
        .then(res => res.data)
        .catch(err => {
            throw err;
        });
};
