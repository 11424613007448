import React, { useContext } from 'react';
import styled from 'styled-components';
import { grid, GridProps, space, SpaceProps, compose } from 'styled-system';
import { getTheme } from '../../helpers/style.helper';
import theme from '@founders-playbook/ui-themes';
import { PageContext } from '../../providers';
import { usePageSidebar, useWindowSize } from '../../hooks';
import { Overlay } from '../overlay';

interface PageProps {
    children: React.ReactElement | React.ReactElement[] | string;
    sidebar?: React.ReactElement;
    sidebarPosition?: 'left' | 'right';
    bgColor?: string;
    sidebarBgColor?: string;
}

const PageWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: stretch;
    flex: 1;
    overflow: hidden;
`;

const Main = styled.div<{ bgColor: string }>`
    background-color: ${({ bgColor }) => getTheme(bgColor)};
    flex: 1;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

interface SidebarProps {
    bgColor: string;
    sidebarPosition: 'left' | 'right';
}

const Sidebar = styled.div<SidebarProps>`
    overflow-y: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    background-color: ${({ bgColor }) => getTheme(bgColor)};
    width: 296px;
    flex-shrink: 0;
    z-index: 10;

    ${({ sidebarPosition }) =>
        sidebarPosition === 'left'
            ? {
                  left: 0,
                  order: 0,
              }
            : {
                  right: 0,
                  order: 1,
              }};

    @media (min-width: ${theme.breakpoints[3]}) {
        position: static;
    }
`;

const Grid = styled.div`
    /* TODO: Possibly move this to a CSS constant to make it reusable */
    /* margin: 0 16px; */
    width: 100%;
    flex: 1;
    padding: 0 16px;

    display: grid;
    grid-gap: 8px;
    grid-template-columns: repeat(12, 1fr);

    @media (min-width: ${theme.breakpoints[0]}) {
        margin: 0 auto;
        padding: 0;
        width: 432px;
    }

    @media (min-width: ${theme.breakpoints[1]}) {
        width: 480px;
    }

    @media (min-width: ${theme.breakpoints[2]}) {
        grid-gap: 16px;
        width: 656px;
    }

    @media (min-width: ${theme.breakpoints[4]}) {
        grid-gap: 32px;
        width: 1040px;
    }
`;

interface PageColumnsProps extends GridProps, SpaceProps {}

export const PageColumns = styled.div<PageColumnsProps>`
    ${compose(grid, space)}
`;

export const Page = ({
    children,
    sidebar,
    sidebarPosition = 'left',
    bgColor = 'colors.silver.25', // TODO: Get this from theme instead of primitives
    sidebarBgColor = 'colors.white.100',
}: PageProps) => {
    const [sidebarOpen] = useContext(PageContext);
    const { toggleSidebar } = usePageSidebar();
    const { width } = useWindowSize();

    return (
        <PageWrapper>
            {sidebarOpen && (
                <>
                    <Sidebar
                        bgColor={sidebarBgColor}
                        sidebarPosition={sidebarPosition}
                    >
                        {sidebar}
                    </Sidebar>

                    {width && width < 1024 && (
                        <Overlay position="absolute" onClick={toggleSidebar} />
                    )}
                </>
            )}
            <Main bgColor={bgColor} id="mainId">
                <Grid>{children}</Grid>
            </Main>
        </PageWrapper>
    );
};
