import React, { useMemo, useRef, useState } from 'react';
import Draft from '@draft-js-plugins/editor';

import createLinkPlugin from '@draft-js-plugins/anchor';
import createToolbarPlugin from '@draft-js-plugins/static-toolbar';

import {
    BoldButton,
    ItalicButton,
    UnderlineButton,
    UnorderedListButton,
    OrderedListButton,
    BlockquoteButton,
} from '@draft-js-plugins/buttons';
import {
    convertToRaw,
    EditorState,
    RawDraftContentState,
    RichUtils,
    convertFromRaw,
} from 'draft-js';
import { toolbarConfig, linkConfig } from './toolbar';
import { Box, ErrorMessage } from '..';
import { ValidationError } from 'yup';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

interface DraftEditorProps {
    errors?: Array<ValidationError>;
    value: RawDraftContentState;
    name?: string;
    onBlur?: (value: RawDraftContentState) => void;
    placeholder?: string;
    readOnly?: boolean;
    minHeight?: string | string[];
}

function myBlockStyleFn(contentBlock) {
    const type = contentBlock.getType();
    if (type === 'blockquote') {
        return 'quote';
    }

    return '';
}

const linkPlugin = createLinkPlugin(linkConfig);

export const DraftEditor = ({
    errors,
    name = 'errorName',
    value,
    onBlur = e => {},
    placeholder = '',
    readOnly = false,
    minHeight = 'auto',
}: DraftEditorProps): React.ReactElement => {
    const editorRef = useRef(null);

    const [showToolbar, setShowToolbar] = useState(false);

    const staticToolbarPlugin = useMemo(() => {
        const staticToolbarPlugin = createToolbarPlugin(toolbarConfig);
        return staticToolbarPlugin;
    }, []);

    const { Toolbar } = staticToolbarPlugin;

    const [editorState, setEditorState] = useState<EditorState>(() => {
        if (!value) return EditorState.createEmpty();
        const parsedState = convertFromRaw(value);
        return EditorState.createWithContent(parsedState);
    });

    const handleKeyCommand = (command, state) => {
        const newState = RichUtils.handleKeyCommand(state, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }

        return 'not-handled';
    };

    return (
        <BlockQuote>
            <Box
                minHeight={minHeight}
                onFocus={() => {
                    setShowToolbar(true);
                }}
                onBlur={() => {
                    setShowToolbar(false);
                    onBlur(convertToRaw(editorState.getCurrentContent()));
                }}
            >
                <Draft
                    readOnly={readOnly}
                    placeholder={placeholder}
                    ref={editorRef}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={setEditorState}
                    plugins={[staticToolbarPlugin, linkPlugin]}
                    blockStyleFn={myBlockStyleFn}
                />

                {!readOnly && showToolbar && (
                    <Toolbar>
                        {externalProps => (
                            <>
                                <BoldButton {...externalProps} />
                                <ItalicButton {...externalProps} />
                                <UnderlineButton {...externalProps} />
                                <linkPlugin.LinkButton {...externalProps} />
                                <UnorderedListButton {...externalProps} />
                                <OrderedListButton {...externalProps} />
                                <BlockquoteButton {...externalProps} />
                            </>
                        )}
                    </Toolbar>
                )}
            </Box>

            <ErrorMessage errors={errors} name={name} />
        </BlockQuote>
    );
};

const BlockQuote = styled.div`
    .quote {
        border-left: 4px solid ${themeGet('colors.silver.100')};
        font-style: italic;
        padding-left: 26px;
    }

    li::marker {
        color: ${themeGet('colors.gold.600')};
    }
`;
