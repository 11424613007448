import { colors } from './primitives';
import { Theme } from './types';

export const theme: Theme = {
  name: 'Default theme',
  fontSizes: ['12px', '14px', '16px', '18px', '20px', '24px', '32px', '40px', '48px'],
  fontWeights: {
    normal: 400,
    bold: 600,
  },
  space: [
    '0',
    '1px',
    '2px',
    '4px',
    '8px',
    '12px',
    '16px',
    '24px',
    '32px',
    '40px',
    '48px',
    '64px',
    '80px',
    '96px',
    '112px',
    '128px',
  ],
  radii: ['0', '1px', '2px', '4px', '8px', '100px'],
  borderWidths: ['0', '1px', '2px'],
  colors: {
    primary: {
      lightest: colors.yellow[2],
      light: colors.yellow[4],
      default: colors.yellow[5],
      dark: colors.yellow[6],
      darkest: colors.yellow[8],
    },
    success: {
      light: colors.green[2],
      default: colors.green[5],
    },
    info: {
      lightest: colors.blue[1],
      light: colors.blue[2],
      default: colors.blue[5],
      dark: colors.blue[6],
      darkest: colors.blue[7],
    },
    warning: {
      light: colors.yellow[2],
      default: colors.yellow[5],
    },
    danger: {
      light: colors.red[2],
      default: colors.red[5],
    },
    disabled: {
      default: colors.gray[1],
      dark: colors.gray[5],
    },
    text: {
      light: '#fff',
      default: '#111',
      secondary: colors.gray[6],
    },
    border: {
      default: colors.gray[3],
    },
    white: { default: '#fff' },
    black: { default: '#000' },
  },
  fonts: {
    normal: 'Patron',
  },
};

export const darkTheme: Theme = {
  name: 'Secondary theme',
  fontSizes: ['12px', '14px', '16px', '18px', '20px', '24px', '32px', '40px', '48px'],
  fontWeights: {
    normal: 400,
    bold: 600,
  },
  space: [
    '0',
    '1px',
    '2px',
    '4px',
    '8px',
    '12px',
    '16px',
    '24px',
    '32px',
    '40px',
    '48px',
    '64px',
    '80px',
    '96px',
    '112px',
    '128px',
  ],
  radii: ['0', '1px', '2px', '4px', '8px', '100px'],
  borderWidths: ['0', '1px'],
  colors: {
    primary: {
      lightest: colors.blue[2],
      light: colors.blue[4],
      default: colors.blue[5],
      dark: colors.blue[6],
      darkest: colors.blue[8],
    },
    success: {
      light: colors.green[2],
      default: colors.green[5],
    },
    info: {
      light: colors.blue[2],
      default: colors.blue[5],
    },
    warning: {
      light: colors.yellow[2],
      default: colors.yellow[5],
    },
    danger: {
      light: colors.red[2],
      default: colors.red[5],
    },
    disabled: {
      default: colors.gray[1],
    },
    text: {
      light: '#fff',
      default: '#111',
      secondary: colors.gray[6],
    },
    border: {
      default: colors.gray[3],
    },
    white: { default: '#fff' },
    black: { default: '#000' },
  },
  fonts: {
    normal: 'Montserrat',
    primary: 'Inter',
  },
};
