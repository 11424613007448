import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Route } from 'react-router-dom';
import { Loader } from '../../../components';

const ProtectedRoute = ({ component, ...args }) => (
    <Route
        component={withAuthenticationRequired(component, {
            onRedirecting: () => <Loader />,
            returnTo: () => window.location.pathname + window.location.search,
        })}
        {...args}
    />
);

export default ProtectedRoute;
