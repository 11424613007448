import styled from 'styled-components';
import { border, BorderProps, compose, space, SpaceProps } from 'styled-system';
import { getTheme } from '../../helpers';

interface Props extends SpaceProps, BorderProps {
    errors?: Array<any>;
    name: string;
    checked: boolean;
    ref?: any;
    theme?: any;
    disabled?: boolean;
}
export const Checkbox: React.FC<Props &
    React.InputHTMLAttributes<HTMLInputElement>> = ({
    errors,
    name,
    onChange,
    onBlur,
    checked,
    disabled,
}) => {
    return (
        <StyledLabel checked={checked} disabled={disabled}>
            <StyledInput
                disabled={disabled}
                name={name}
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <CheckMark checked={checked} />
            {name}
        </StyledLabel>
    );
};

const StyledLabel = styled.label<{ checked: boolean; disabled?: boolean }>`
    color: ${({ disabled }) =>
        disabled
            ? getTheme('colors.silver.600')
            : getTheme('checkbox.default.color')};
    font-weight: ${({ checked }) => (checked ? 'bold' : 'normal')};
    position: relative;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    font-size: 14px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

const CheckMark = styled.span<{ checked: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    height: ${getTheme('checkbox.default.width')};
    width: ${getTheme('checkbox.default.height')};
    background-color: ${getTheme('checkbox.default.bg')};

    &:after {
        content: '';
        position: absolute;
        display: ${({ checked }) => (checked ? 'block' : 'none')};
        left: 6px;
        top: 2px;
        width: 5px;
        height: 10px;
        border: solid black;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`;

const StyledInput = styled.input`
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    margin-right: 16px;

    ${compose(space, border)};
`;
